import {useState} from 'react';
import LocaleStrings from '@language';
import TaggedSearch from '@commonFolder/TaggedSearch';
import {connect} from 'react-redux';
import {fieldSearch} from '../../../../actions';
import debounce from 'lodash/debounce';

function PeopleCompanyName(props) {
  const [results, setResults] = useState([]);
  const [wasSearching, setWasSearching] = useState(false);

  const handleSearch = val => {
    if (val !== '' && val.length > 1) {
      setWasSearching(true);
      props.fieldSearch(
        props.session,
        {action: 'search', searchfield: 'company', searchtext: val},
        res => {
          if (res.data) {
            setResults(res.data);
          } else {
            setResults([]);
          }
        },
      );
    }
  };

  const debouncedSearch = debounce(handleSearch, 100);

  return (
    <div>
      <TaggedSearch
        placeholder={LocaleStrings.search.companysearch.company_placeholder}
        id="peoplecompanyname"
        name="peoplecompanyname"
        takesAnyValue={false}
        results={wasSearching ? (results.length === 0 ? [] : results) : []}
        showOptions
        onSearch={val => {
          debouncedSearch(val);
        }}
        onToggled={toggled => {
          if (!toggled) {
            setWasSearching(false);
            setResults([]);
          }
        }}
        {...props}
      />
    </div>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  return {
    session,
    user,
    savedFilters,
    companies: state.preloadedPeopleFilterSupportData.companies,
  };
}

export default connect(mapStateToProps, {fieldSearch})(PeopleCompanyName);
