import React, {Fragment, useState} from 'react';

import TooltipPopover from '@/common/TooltipPopover';
import {PortalWithState} from 'react-portal';

import IconExport from '../icons/export';
import MenuDropdown from '../../../../../../common/exportmenu/menudropdown';

const height = window.innerHeight;

export default function ExportCRMBlock(props) {
  const [coords, setCoords] = useState({}); // takes current button coordinates

  const btnRef = React.createRef();

  const updateTooltipCoords = _ => {
    const rect = btnRef.current.getBoundingClientRect();

    //Detemining top position of last popup in the tableview
    const top = height - 170 < rect.y + 5 ? rect.y - 150 : rect.y + 10;

    setCoords({
      left: rect.x + rect.width / 2 + 50, // add half the width of the button for centering
      top, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
    });
  };

  // const updateTooltipCoords = _ => {
  //   const rect = btnRef.current.getBoundingClientRect();
  //   setCoords({
  //     left: rect.x + rect.width / 2 + 50, // add half the width of the button for centering
  //     top: rect.y + window.scrollY + 10, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
  //   });
  // };

  return (
    <div className="p-1.5 px-2 cursor-pointer">
      <PortalWithState>
        {({openPortal, closePortal, isOpen, portal}) => (
          <Fragment>
            <div
              ref={btnRef}
              onMouseEnter={e => {
                updateTooltipCoords(e.target);
                openPortal(e);
              }}
              onMouseLeave={e => {
                updateTooltipCoords(e.target);
                closePortal();
              }}
              // onClick={e => {
              //   closePortal(e);
              //   const existing = document.querySelectorAll('.menudropdown');
              //   existing.forEach(item => {
              //     if (item) {
              //       const parent = item.parentElement; // Get the parent element
              //       if (parent && parent.contains(item)) {
              //         // Ensure the parent contains the child
              //         parent.style.display = 'none';
              //       } else {
              //         console.warn(
              //           'Parent does not contain the expected child:',
              //           item,
              //         );
              //       }
              //     }
              //   });

              //   updateTooltipCoords(e.target);
              //   setTimeout(() => {
              //     openPortal(e);
              //   }, 50);

              //   console.log('CLicked');
              // }}
              // onMouseLeave={e => {
              //   updateTooltipCoords(e.target);
              //   closePortal();
              // }}
            >
              <div className="">
                <IconExport />
              </div>

              {portal(
                <TooltipPopover
                  width={180}
                  theClassName="menudropdown"
                  coords={coords}
                  direction={'left'}
                  updateTooltipCoords={() =>
                    updateTooltipCoords(btnRef.current.buttonNode)
                  }>
                  <MenuDropdown
                    {...props}
                    onSelectedMenu={type => {
                      closePortal();
                      props.onSelectedMenu(type);
                    }}
                  />
                </TooltipPopover>,
              )}
            </div>
          </Fragment>
        )}
      </PortalWithState>
    </div>
  );
}
