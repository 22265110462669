import {connect} from 'react-redux';
import {people} from '../constants';
import PeopleCardCell from './cells/PeopleCardCell';
import CreateListPopup from '../components/createlistpopup';
import {useState} from 'react';
import LocaleStrings from '../../../languages';
import {FILTER_TYPE_FAV_PEOPLE, FILTER_TYPE_PEOPLE} from '../actions';
import {useNavigate} from 'react-router-dom';
import toast, {Toaster} from 'react-hot-toast';
import BlockTechnology, {
  SECTION_PEOPLE,
} from './cells/components/technologyblock';
import ReportContactPopup from '../components/reportcontactpopup';
import {contactmetaAddupdate} from '@myContactsActions';

function CardTable(props) {
  const {
    selectedPersons,
    onSelectPerson,
    filteredPeople,
    filterType,
    applyFilter,
  } = props;
  const [openCreateListPopup, setOpenCreateListPopup] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState();
  const [openFlagPopup, setOpenFlagPopup] = useState(false);
  const [selectedContact, setSelectedContact] = useState();
  const navigate = useNavigate();

  const updateContactMeta = (val, action, type, linkedinurl, downvotedata) => {
    let obj = {};
    obj.linkedin_url = linkedinurl;
    obj.info = val;
    obj.action = action;
    obj.type = type;

    if (action === 'downvote') {
      obj.downvotereason = downvotedata.downvotereason;
      obj.reasonnote = downvotedata.reasonnote;
    }

    props.contactmetaAddupdate(props.session, obj, callback => {
      toast.success('Thanks! Your feedback has been submitted.');
    });
  };

  return (
    <div className="relative">
      <table className="w-full table-fixed divide-y divide-gray-300">
        <thead>
          <tr className="bg-gray-50">
            {filterType === FILTER_TYPE_PEOPLE ? (
              <th
                scope="col"
                className="relative w-8 sticky top-0 z-50 bg-gray-50"
              />
            ) : null}
            <th
              scope="col"
              className="py-3.5 w-1/5 pr-3 text-left text-xs font-normal text-gray-700 px-7 sticky top-0 z-50 bg-gray-50">
              {LocaleStrings.search.peoplesearch.tableHeaders.contactname}
            </th>
            <th
              scope="col"
              className="w-1/3 px-3 py-3.5 text-left text-xs font-normal text-gray-700 sticky top-0 z-50 bg-gray-50">
              {LocaleStrings.search.peoplesearch.tableHeaders.contactdetails}
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-xs font-normal text-gray-700 sticky top-0 z-50 bg-gray-50">
              {LocaleStrings.search.peoplesearch.tableHeaders.companyname}
            </th>
            <th className="relative w-[180px] sticky top-0 z-50 bg-gray-50" />
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {filteredPeople.map(person => (
            <PeopleCardCell
              filterType={filterType}
              person={person}
              selected={selectedPersons.includes(person)}
              onCheckUnCheck={checked => {
                onSelectPerson(person, checked);
              }}
              onOpenCreateList={person => {
                setOpenCreateListPopup(!openCreateListPopup);
                setSelectedPerson(person);
              }}
              onUpgradePlan={() => navigate('/upgradeplan')}
              onBuyAddon={() => navigate('/creditaddons')}
              onCopySuccess={() => {
                toast.success('Copied successfully');
              }}
              onClickFlag={contact => {
                if (contact.action === 'downvote') {
                  setOpenFlagPopup(true);
                  setSelectedContact({...contact, ...person});
                } else {
                  toast.success(LocaleStrings.mycontacts_upvoted_success);
                }
              }}
              onRefresh={props.onRefresh}
              onRemoveFav={props.onRemoveFav}
              applyFilter={applyFilter}
            />
          ))}
          {props.showTechnologyBlock ? (
            <BlockTechnology
              colSpan={filterType === FILTER_TYPE_FAV_PEOPLE ? 5 : 4}
              totalcount={props.totalcount}
              section={SECTION_PEOPLE}
            />
          ) : null}
        </tbody>
      </table>
      <CreateListPopup
        open={openCreateListPopup}
        filterType={filterType}
        selectedPerson={selectedPerson}
        toggleOpen={open => {
          setOpenCreateListPopup(open);
        }}
      />

      <ReportContactPopup
        open={openFlagPopup}
        {...selectedContact}
        onClose={() => {
          setOpenFlagPopup(false);
        }}
        onSubmit={data => {
          updateContactMeta(
            selectedContact.contact,
            'downvote',
            selectedContact.contacttype,
            selectedContact.linkedin,
            data,
          );
          setOpenFlagPopup(false);
          setSelectedContact(undefined);
        }}
      />
      <Toaster />
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  var {session, user, savedFilters, technologySearch} = state;
  const {totalcount, showTechnologyBlock} = technologySearch;
  let filteredPeople =
    ownProps.filterType === FILTER_TYPE_PEOPLE
      ? state.filteredPeople.data
      : state.filteredFavPeople.data;
  if (showTechnologyBlock) {
    filteredPeople = filteredPeople.slice(0, 5);
  }
  return {
    session,
    user,
    savedFilters,
    searchSelectedSubMenu: state.searchSelectedSubMenu,
    filteredPeople,
    showTechnologyBlock,
    totalcount: totalcount > 1 ? totalcount - 1 : 0, //For technology block
  };
}

export default connect(mapStateToProps, {contactmetaAddupdate})(CardTable);
