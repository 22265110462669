import React, {Component} from 'react';
import {connect} from 'react-redux';
import LocaleStrings from '@language';
import moment from 'moment';
import {
  fetchRevealedcontact,
  fetchRevealedcontactbydirectdials,
  fetchRevealedCounts,
} from '@maindashBoardActions';
import {classNames} from '@commonFunction';

class DashboardBottom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_currency,
      calledfor: '7',
    };

    // All Binded Functions
  }
  componentDidMount() {
    let {user, session} = this.props;
    var sevenDaysAgo = new Date(new Date().setDate(new Date().getDate() - 7));
    sevenDaysAgo = moment(sevenDaysAgo).format('YYYY-MM-DD');

    this.props.fetchRevealedCounts(
      session,
      user?.appuserid,
      sevenDaysAgo,
      callback => {},
    );

    /*this.props.fetchRevealedcontact(
      session,
      user?.appuserid,
      sevenDaysAgo,
      (callback) => {}
    );
    this.props.fetchRevealedcontactbydirectdials(
      session,
      user?.appuserid,
      sevenDaysAgo,
      (callback) => {}
    );*/
  }
  // fetching contacts count for client for last 7,30,90 days
  _fetchContacts(e, comesFrom) {
    let {user, session} = this.props;
    if (comesFrom == '90') {
      var ninetyDaysAgo = new Date(
        new Date().setDate(new Date().getDate() - 90),
      );
      ninetyDaysAgo = moment(ninetyDaysAgo).format('YYYY-MM-DD');

      this.props.fetchRevealedCounts(
        session,
        user?.appuserid,
        ninetyDaysAgo,
        callback => {},
      );

      /*this.props.fetchRevealedcontact(
        session,
        user?.appuserid,
        ninetyDaysAgo,
        (callback) => {}
      );
      this.props.fetchRevealedcontactbydirectdials(
        session,
        user?.appuserid,
        ninetyDaysAgo,
        (callback) => {}
      );*/

      this.setState({calledfor: '90'});
    } else if (comesFrom == '30') {
      var thirtyDaysAgo = new Date(
        new Date().setDate(new Date().getDate() - 30),
      );
      thirtyDaysAgo = moment(thirtyDaysAgo).format('YYYY-MM-DD');

      this.props.fetchRevealedCounts(
        session,
        user?.appuserid,
        thirtyDaysAgo,
        callback => {},
      );

      /*this.props.fetchRevealedcontact(
        session,
        user?.appuserid,
        thirtyDaysAgo,
        (callback) => {}
      );
      this.props.fetchRevealedcontactbydirectdials(
        session,
        user?.appuserid,
        thirtyDaysAgo,
        (callback) => {}
      );*/
      this.setState({calledfor: '30'});
    } else {
      var sevenDaysAgo = new Date(new Date().setDate(new Date().getDate() - 7));
      sevenDaysAgo = moment(sevenDaysAgo).format('YYYY-MM-DD');
      this.props.fetchRevealedCounts(
        session,
        user?.appuserid,
        sevenDaysAgo,
        callback => {},
      );

      /*this.props.fetchRevealedcontact(
        session,
        user?.appuserid,
        sevenDaysAgo,
        (callback) => {}
      );
      this.props.fetchRevealedcontactbydirectdials(
        session,
        user?.appuserid,
        sevenDaysAgo,
        (callback) => {}
      );*/
      this.setState({calledfor: '7'});
    }
  }

  render() {
    let {revealedcontacts} = this.props;

    let revealcontactCount = revealedcontacts.totalrevealcount;
    // let revealcontactCountbyemail = 0;
    // let revealcontactCountbydirectdials = 0;
    // if (revealcontactDatalist && revealcontactDatalist.data) {
    //   revealcontactCountbyemail = revealcontactDatalist.data;
    // }
    // if (revealcontactDirectDials && revealcontactDirectDials.data) {
    //   revealcontactCountbydirectdials = revealcontactDirectDials.data;
    // }

    // revealcontactCount =
    //   parseInt(revealcontactCountbyemail) +
    //   parseInt(revealcontactCountbydirectdials);

    return (
      <>
        <div className="pt-10 font-medium text-xs pl-4">
          {LocaleStrings.dashboard_main_subheader_revealedcontacts}
        </div>
        <div className="py-6 px-4">
          <span className="isolate inline-flex rounded-md shadow-sm">
            <button
              type="button"
              onClick={e => this._fetchContacts(e, '7')}
              className={classNames(
                this.state.calledfor == 7 || this.state.calledfor == '7'
                  ? 'border-indigo-500'
                  : '',
                'relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500',
              )}>
              {LocaleStrings.dashboard_main_subheader_buttonlast7}
            </button>
            <button
              onClick={e => this._fetchContacts(e, '30')}
              type="button"
              className={classNames(
                this.state.calledfor == 30 || this.state.calledfor == '30'
                  ? 'border-indigo-500'
                  : '',
                'relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500',
              )}>
              {LocaleStrings.dashboard_main_subheader_buttonlast30}
            </button>
            <button
              onClick={e => this._fetchContacts(e, '90')}
              type="button"
              className={classNames(
                this.state.calledfor == 90 || this.state.calledfor == '90'
                  ? 'border-indigo-500'
                  : '',
                'relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500',
              )}>
              {LocaleStrings.dashboard_main_subheader_buttonlast90}
            </button>
          </span>
        </div>
        <div className="overflow-hidden bg-white shadow sm:rounded-lg py-4 px-6">
          <div className="text-xs text-gray500">
            {LocaleStrings.dashboard_main_subheader_revealedcontacts}
          </div>
          <div className="text-xl font-semibold mt-2 ">
            {revealcontactCount}
          </div>
        </div>
      </>
    );
  }
}
var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  revealedcontacts: state.revealedcontacts,
});

export default connect(mapStateToProps, {
  fetchRevealedcontact,
  fetchRevealedcontactbydirectdials,
  fetchRevealedCounts,
})(DashboardBottom);
