import {Fragment, useEffect, useRef, useState} from 'react';
import {Dialog, Transition, Combobox} from '@headlessui/react';
import LocaleStrings from '@language';
import {connect} from 'react-redux';
import {confirmAlert} from 'react-confirm-alert';
import {classNames} from '@commonFunction';
import _ from 'lodash';
import toast, {Toaster} from 'react-hot-toast';
import {crmModuleModal, addCRMLead} from '../../search/actions';
import BtnLoaderSvg from '@btnLoaderSvg';
const modulesArr = [
  {value: 'Leads', text: 'Leads'},
  {value: 'Accounts', text: 'Accounts'},
  {value: 'Contacts', text: 'Contacts'},
];
function SelectionField(props) {
  const {
    dataKey,
    title,
    checked,
    value,
    disabled,
    selectedModule,
    setSelectedModule,
    isNotIncluded,
    isDataBlank,
  } = props;

  const handleCheckboxChange = e => {
    if (e.target.checked) {
      // Add value to selectedModule if not already present
      setSelectedModule(prev => [...prev, value]);
    } else {
      // Remove value from selectedModule if already present
      setSelectedModule(prev => prev.filter(item => item !== value));
    }
  };

  return !disabled ? (
    <div key={dataKey} className="group relative flex items-start py-2">
      <div className="ml-2 mr-2 flex h-6 items-center">
        <input
          id={`field-${dataKey}`}
          name={`field-${dataKey}`}
          disabled={disabled}
          type="checkbox"
          className={`h-4 w-4 rounded border-gray-300 text-primary focus:primary ${
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          }`}
          onChange={handleCheckboxChange}
          value={value}
        />
      </div>
      <div className="min-w-0 flex-1 text-xs leading-6">
        <label
          htmlFor={`field-${dataKey}`}
          className={`select-none text-sm ${
            disabled
              ? 'text-gray-400 cursor-not-allowed' // Style for disabled items
              : 'text-gray-900 group-hover:text-primary cursor-pointer'
          }`}>
          {title}
        </label>
        {isNotIncluded ? (
          <span className="ml-2 text-red-500 text-xs">
            (Module not selected)
          </span>
        ) : isDataBlank ? (
          <span className="ml-2 text-yellow-500 text-xs">
            (Fields are not mapped)
          </span>
        ) : null}
      </div>
    </div>
  ) : (
    ''
  );
}

function CrmPopup(props) {
  let {iscrmModuleModalOpen, crmDetails} = props;
  const cancelButtonRef = useRef(null);
  const [processingExport, setProcessingExport] = useState(false);
  const [selectedModule, setSelectedModule] = useState([]);

  const closeModal = () => {
    props.crmModuleModal({isOpen: false, exportcrmType: null});
  };

  const connectToZoho = () => {
    let selectedPersons = iscrmModuleModalOpen.selectedPersons;
    // console.log('selectedPersons', selectedPersons);
    //console.log('selectedModule', selectedModule);
    if (iscrmModuleModalOpen.exportcrmType == 'zoho') {
      if (crmDetails.zoho && selectedModule.length > 0) {
        setProcessingExport(true);
        let peopleIdArr = [];
        _.map(selectedPersons, user => {
          let obj = {};
          obj.peopleid = user.peopleid;
          obj.contactid = user.contactid;
          peopleIdArr.push(obj);
        });
        // console.log('peopleIdArr', peopleIdArr);

        // debugger;
        props.addCRMLead(
          props.session,
          {
            peopleids: peopleIdArr,
            modules: selectedModule,
            lead_source: 'Kipplo Reveal Contact',
          },
          'zoholead',
          resp => {
            console.log('resp', resp);
            setProcessingExport(false);
            if (resp.success === 1) {
              closeModal();
              // setIsLoading(true);
              // applyFilter(false);
              // if (iscrmModuleModalOpen.saveSelectedDPeople) {
              //   iscrmModuleModalOpen.saveSelectedDPeople(props.session, []);
              // }
              // if (iscrmModuleModalOpen.updateUser) {
              //   iscrmModuleModalOpen.updateUser();
              // }

              if (iscrmModuleModalOpen.applyFilter) {
                iscrmModuleModalOpen.applyFilter(false);
              }

              if (iscrmModuleModalOpen.toggleAll) {
                iscrmModuleModalOpen.toggleAll();
              }

              if (iscrmModuleModalOpen.onCheckUnCheck) {
                iscrmModuleModalOpen.onCheckUnCheck(false);
              }

              toast.success(LocaleStrings.zoho_success);
            } else {
              closeModal();
              if (resp.errorlog.length > 0) {
                confirmAlert({
                  customUI: ({onClose}) => {
                    return (
                      <div className="react-confirm-alert-body">
                        <h1>{LocaleStrings.export_to_zoho}</h1>
                        <div>There are some error found</div>
                        <ul>
                          {_.map(resp.errorlog, (item, index) => {
                            return <li>{item.message}</li>;
                          })}
                        </ul>

                        <div className="react-confirm-alert-button-group">
                          <button onClick={onClose}>{LocaleStrings.ok}</button>
                        </div>
                      </div>
                    );
                  },
                });
              } else {
                toast.error(LocaleStrings.common_fail_message);
              }
            }
          },
        );
      }
    } else if (iscrmModuleModalOpen.exportcrmType == 'salesforce') {
      if (crmDetails.salesforce && selectedModule.length > 0) {
        setProcessingExport(true);
        let peopleIdArr = [];
        _.map(selectedPersons, user => {
          let obj = {};
          obj.peopleid = user.peopleid;
          obj.contactid = user.contactid;
          peopleIdArr.push(obj);
        });
        props.addCRMLead(
          props.session,
          {
            peopleids: peopleIdArr,
            modules: selectedModule,
            lead_source: 'Kipplo Reveal Contact',
          },
          'salesforce',
          resp => {
            // console.log('resp', resp);
            setProcessingExport(false);
            if (resp.success === 1) {
              closeModal();
              // setIsLoading(true);
              // applyFilter(false);
              // if (iscrmModuleModalOpen.saveSelectedDPeople) {
              //   iscrmModuleModalOpen.saveSelectedDPeople(props.session, []);
              // }
              // if (iscrmModuleModalOpen.updateUser) {
              //   iscrmModuleModalOpen.updateUser();
              // }
              if (iscrmModuleModalOpen.applyFilter) {
                iscrmModuleModalOpen.applyFilter(false);
              }

              if (iscrmModuleModalOpen.toggleAll) {
                iscrmModuleModalOpen.toggleAll();
              }

              if (iscrmModuleModalOpen.onCheckUnCheck) {
                iscrmModuleModalOpen.onCheckUnCheck(false);
              }

              toast.success(LocaleStrings.salesforce_success);
            } else {
              closeModal();
              if (resp.errorlog.length > 0) {
                confirmAlert({
                  customUI: ({onClose}) => {
                    return (
                      <div className="react-confirm-alert-body">
                        <h1>{LocaleStrings.export_to_salesforce}</h1>
                        <div>There are some error found</div>
                        <ul>
                          {_.map(resp.errorlog, (item, index) => {
                            return <li>{item.message}</li>;
                          })}
                        </ul>

                        <div className="react-confirm-alert-button-group">
                          <button onClick={onClose}>{LocaleStrings.ok}</button>
                        </div>
                      </div>
                    );
                  },
                });
              } else {
                toast.error(LocaleStrings.common_fail_message);
              }
            }
          },
        );
      }
    }
  };
  //console.log('crmDetails', crmDetails);
  let moduleTypesArr = [];
  if (
    iscrmModuleModalOpen.exportcrmType == 'zoho' &&
    crmDetails &&
    crmDetails.zoho_moduletype
  ) {
    moduleTypesArr = crmDetails.zoho_moduletype.split(',');
  } else if (
    iscrmModuleModalOpen.exportcrmType == 'salesforce' &&
    crmDetails &&
    crmDetails.salesforce_moduletype
  ) {
    moduleTypesArr = crmDetails.salesforce_moduletype.split(',');
  }
  // console.log('moduleTypesArr', moduleTypesArr);
  // console.log('selectedPersons', iscrmModuleModalOpen.selectedPersons);

  return (
    <Transition.Root show={iscrmModuleModalOpen.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[9999]"
        initialFocus={cancelButtonRef}
        open={iscrmModuleModalOpen.isOpen}
        onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform rounded-lg bg-white pb-4 pt-5 text-left shadow-xl transition-all w-[450px] p-6">
                <div>
                  <div className="flex items-center gap-4">
                    <div className="text-left">
                      <div className="text-sm font-semibold leading-6 text-gray-900">
                        {LocaleStrings.export_to_zoho}
                      </div>
                      <div>
                        <p className="text-xs text-gray-500">
                          {LocaleStrings.export_to_crm_module}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="py-4">
                    <div className=" px-2">
                      {modulesArr.map((obj, key) => {
                        let dataKey = null;
                        if (iscrmModuleModalOpen.exportcrmType == 'zoho') {
                          dataKey =
                            obj.value.toLowerCase() === 'leads'
                              ? 'zoho_mapped_data'
                              : `zoho_${obj.value
                                  .toLowerCase()
                                  .slice(0, -1)}_data`;
                        } else {
                          dataKey =
                            obj.value.toLowerCase() === 'leads'
                              ? 'sales_mapped_data'
                              : `sales_${obj.value
                                  .toLowerCase()
                                  .slice(0, -1)}_data`;
                        }
                        //console.log('dataKey', dataKey);
                        const dataObj = crmDetails[dataKey] || {};
                        //console.log('dataObj', dataObj);
                        const isDataBlank = Object.keys(dataObj).length === 0;
                        const isNotIncluded = !moduleTypesArr.includes(
                          obj.value.toLowerCase(),
                        );

                        const isDisabled = isDataBlank || isNotIncluded;

                        return (
                          <SelectionField
                            key={key}
                            dataKey={obj.value}
                            title={obj.text}
                            value={obj.value}
                            disabled={isDisabled}
                            selectedModule={selectedModule}
                            setSelectedModule={setSelectedModule}
                            isDataBlank={isDataBlank}
                            isNotIncluded={isNotIncluded}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="mt-6 grid grid-flow-row-dense grid-cols-2 gap-3 mx-4">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-white px-3.5 py-2 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 hover:bg-gray-50"
                    onClick={() => closeModal()}
                    ref={cancelButtonRef}>
                    {LocaleStrings.search.createlist.cancel}
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      'inline-flex w-full justify-center rounded-md  px-3.5 py-2 text-xs text-white shadow-sm hover:opacity-80 bg-primary',
                    )}
                    disabled={selectedModule.length > 0 ? false : true}
                    onClick={() => {
                      connectToZoho();
                    }}>
                    <BtnLoaderSvg loader={processingExport} />
                    {LocaleStrings.export_button}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function mapStateToProps(state, ownProps) {
  var {session, user} = state;
  return {
    session,
    iscrmModuleModalOpen: state.iscrmModuleModalOpen,
    crmDetails: state.crmDetails,
  };
}

export default connect(mapStateToProps, {crmModuleModal, addCRMLead})(CrmPopup);
