import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {connect} from 'react-redux';
import {classNames} from '@commonFunction';
import {FilterIcon} from '@heroicons/react/solid';
import {throttle} from 'lodash';
import CreateListPopup from '../search/components/createlistpopup';

import LocaleStrings from '../../languages';
import PaginationBar from '../search/results/cells/components/paginationbar';
import {
  LABEL_TYPE_MYLIST,
  fetchListPeople,
  removePersonFromlist,
  updateLabel,
  updateListPerson,
} from './actions';
import MyListPeopleCell from './cells/MyListPeopleCell';
import ManageLabelsPopups from './components/managelabels';
import IconExport from '../search/results/cells/icons/export';
import AppAlert from '../../../common/appalert';
import toast, {Toaster} from 'react-hot-toast';
import NoResult from '../../../common/noresult';
import SkeletonLoader from '../../../common/skeletonloader';
import useWindowDimensions from '../../../common/useWindowDimensions';
import {useNavigate} from 'react-router-dom';
import _ from 'lodash';
import ExportMenu from '../../../common/exportmenu';
import {ExportMenuTypes} from '../../../common/exportmenu/menudropdown';
import ProcessingIndicator from '../../../common/processingindicator';
import {
  addCRMLead,
  fetchCRMDetails,
  saveSelectedDPeople,
  zapierHook,
  crmModuleModal,
} from '../search/actions';
import Reportcontactpopup from '../search/components/reportcontactpopup';
import {contactmetaAddupdate} from '@myContactsActions';
import {labelNotAssigned} from './constants';

function ListPeopleTable(props) {
  const maxRowPerPage = props.appuserData?.settings?.max_selection ?? 25;
  const [curPage, setCurPage] = useState(1);
  const [rowsPerPage] = useState(maxRowPerPage);
  const [sortBy, setSortBy] = useState('createdon');
  const [sortDirection, setSortDirection] = useState('asc');
  const {height, width} = useWindowDimensions();

  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPersons, setSelectedPersons] = useState([]);

  const [openManageLabelsPopup, setOpenManageLabelsPopup] = useState(false);

  const [selectedList, setSelectedList] = useState(undefined);

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const navigate = useNavigate();

  const [selectedPersonToDelete, setSelectedPersonToDelete] =
    useState(undefined);

  const [isLoading, setIsLoading] = useState(false);
  const [isPaginating, setIsPaginating] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const [processingExport, setProcessingExport] = useState(false);

  const [openFlagPopup, setOpenFlagPopup] = useState(false);
  const [selectedContact, setSelectedContact] = useState();

  const applyFilter = resetPage => {
    const page = resetPage ? 1 : curPage;
    props.fetchListPeople(
      props.session,
      page,
      rowsPerPage,
      sortBy,
      sortDirection,
      {...props.savedFilters, listid: props.activeList.listid},
      resp => {
        if (resetPage) {
          setCurPage(1);
          setTimeout(() => {
            setIsLoading(false);
            setIsPaginating(false);
            setFirstLoad(false);
          }, 500);
        } else {
          setIsLoading(false);
          setIsPaginating(false);
          setFirstLoad(false);
        }
      },
    );
  };

  useEffect(() => {
    if (isPaginating) {
      applyFilter(false);
    }
  }, [curPage]);

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      applyFilter(false);
    }
  }, [props.savedFilters]);

  useLayoutEffect(() => {
    if (props.filteredListPeople && props.filteredListPeople.length > 0) {
      const isIndeterminate =
        selectedPersons.length > 0 &&
        selectedPersons.length !== props.filteredListPeople.length;
      setChecked(
        selectedPersons.length < maxRowPerPage
          ? selectedPersons.length === props.filteredListPeople.length
          : selectedPersons.length === maxRowPerPage,
      );

      setIndeterminate(isIndeterminate);
      if (checkbox && checkbox.current)
        checkbox.current.indeterminate = isIndeterminate;
    } else {
      setChecked(false);
    }
  }, [selectedPersons, props.filteredListPeople]);

  function toggleAll() {
    setSelectedPersons(
      checked || indeterminate ? [] : props.filteredListPeople,
    );
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const renderResultText = () => {
    const startIndex = (curPage - 1) * maxRowPerPage;
    let endIndex = (curPage - 1) * maxRowPerPage + maxRowPerPage;

    if (props.totalcount < maxRowPerPage) {
      endIndex = props.totalcount;
    }

    if (endIndex > props.totalcount) {
      endIndex = props.totalcount;
    }

    if (props.totalcount > 0)
      return `${startIndex + 1} - ${endIndex} of ${props.totalcount} contacts`;

    return '0 contacts';
  };

  const exportToCSV = () => {
    if (selectedPersons.length > 0) {
      setProcessingExport(true);
      let JSONData = {};
      JSONData['mylists_people'] = selectedPersons;
      JSONToCSVConvertor(JSONData);
    } else {
      toast.error('Please Select Contacts to Export');
      setProcessingExport(false);
    }
  };
  // called from exportBills for downloading report
  const JSONToCSVConvertor = JSONData => {
    var XLSX = require('xlsx');
    let wb = XLSX.utils.book_new();

    let arrjsonn = [];
    _.forEach(JSONData.mylists_people, function (person) {
      const {
        contactname,
        jobtitle,
        company,
        contactdetails,
        linkedin,
        contacttag,
      } = person;

      // console.log("value", value);

      const personalEmails = contactdetails.filter(
        contact => contact.type === 'personalemail' && !contact.encrypted,
      );
      const businessEmails = contactdetails.filter(
        contact => contact.type === 'businessemail' && !contact.encrypted,
      );

      const directDials = contactdetails.filter(
        contact => contact.type === 'directdial' && !contact.encrypted,
      );

      let personalemailcomaseperated = personalEmails
        .map(contact => contact.values)
        .join(',');
      let businessemailcomaseperated = businessEmails
        .map(contact => contact.values)
        .join(',');

      let phonenumbersomaseperated = directDials
        .map(contact => contact.values)
        .join(',');

      var obj = {
        ContactName: contactname,
        JobTitle: jobtitle,
        Company: company.title,
        Linkedin_url: linkedin,
        Label: contacttag ? contacttag.title : 'Not assigned',
        Personal_Emails: personalemailcomaseperated,
        Business_Emails: businessemailcomaseperated,
        Cell_numbers: phonenumbersomaseperated,
      };

      arrjsonn.push(obj);
    });

    let JSONDataOBJ = {
      mylists_people: arrjsonn,
    };
    _.map(JSONDataOBJ, (item, index) => {
      /* Create a worksheet */
      var ws = XLSX.utils.json_to_sheet(item);
      // // var ws1 = XLSX.utils.json_to_sheet(JSONData['F2(149426)']);

      var sheetName = index.replace(/[\/\\:\[\]*?]/g, '_'); // Sheet name cannot contain ': \ / ? * [ ]' so replacing with '_' and also can not contain more than 31 chars
      if (sheetName.length > 30) {
        sheetName = sheetName.substr(0, 28) + '...';
      }

      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      // XLSX.utils.book_append_sheet(wb, ws1, "F2(149426)");
    });
    XLSX.writeFile(wb, 'mylists_people.csv');
    setProcessingExport(false);
    toggleAll();
  };

  const connectToZoho = () => {
    setProcessingExport(true);
    let crmDetails = props.crmDetails;

    if (crmDetails.zoho) {
      let peopleIdArr = [];
      _.map(selectedPersons, user => {
        let obj = {};
        obj.peopleid = user.peopleid;
        peopleIdArr.push(obj);
      });
      props.addCRMLead(
        props.session,
        {
          peopleids: peopleIdArr,
          lead_source: 'Kipplo MyList',
        },
        'zoholead',
        resp => {
          console.log('resp', resp);
          setProcessingExport(false);
          if (resp.success === 1) {
            props.saveSelectedDPeople(props.session, []);

            toast.success(LocaleStrings.zoho_success);
          } else {
            toast.error(LocaleStrings.common_fail_message);
          }
        },
      );
    } else {
      navigate('/settings/crm');
    }
  };

  const connectToSalesForce = () => {
    setProcessingExport(true);
    let crmDetails = props.crmDetails;
    if (crmDetails.salesforce) {
      let peopleIdArr = [];
      _.map(selectedPersons, user => {
        let obj = {};
        obj.peopleid = user.peopleid;
        peopleIdArr.push(obj);
      });
      props.addCRMLead(
        props.session,
        {
          peopleids: peopleIdArr,
          lead_source: 'Kipplo MyList',
        },
        'salesforce',
        resp => {
          console.log('resp', resp);
          setProcessingExport(false);
          if (resp.success === 1) {
            props.saveSelectedDPeople(props.session, []);
            toast.success(LocaleStrings.salesforce_success);
          } else {
            toast.error(LocaleStrings.common_fail_message);
          }
        },
      );
    } else {
      navigate('/settings/crm');
    }
  };

  const connectToZapier = () => {
    setProcessingExport(true);
    let crmDetails = props.crmDetails;
    if (crmDetails.zapier) {
      let peopleIdArr = [];
      _.map(selectedPersons, user => {
        let obj = {};
        obj.peopleid = user.peopleid;
        obj.contactid = user.contactid;
        peopleIdArr.push(obj);
      });
      props.zapierHook(
        props.session,
        {
          peopleids: peopleIdArr,
          lead_source: 'Kipplo Reveal Contact',
        },
        resp => {
          // console.log('resp', resp);
          setProcessingExport(false);
          if (resp.success === 1) {
            props.saveSelectedDPeople(props.session, []);
            toast.success(LocaleStrings.zapier_success);
          } else {
            toast.error(LocaleStrings.common_fail_message);
          }
        },
      );
    } else {
      navigate('/settings/crm');
    }
  };

  const updateContactMeta = (val, action, type, linkedinurl, downvotedata) => {
    let obj = {};
    obj.linkedin_url = linkedinurl;
    obj.info = val;
    obj.action = action;
    obj.type = type;

    if (action === 'downvote') {
      obj.downvotereason = downvotedata.downvotereason;
      obj.reasonnote = downvotedata.reasonnote;
    }

    props.contactmetaAddupdate(props.session, obj, callback => {
      toast.success('Thanks! Your feedback has been submitted.');
    });
  };

  const crmModalOpen = crmtype => {
    let crmDetails = props.crmDetails;

    if (crmDetails[crmtype]) {
      let canProceed = false;

      if (crmtype === 'zoho') {
        canProceed =
          Object.keys(crmDetails.zoho_mapped_data).length > 0 ||
          Object.keys(crmDetails.zoho_contact_data).length > 0 ||
          Object.keys(crmDetails.zoho_account_data).length > 0;
      } else {
        canProceed =
          Object.keys(crmDetails.sales_mapped_data).length > 0 ||
          Object.keys(crmDetails.sales_contact_data).length > 0 ||
          Object.keys(crmDetails.sales_account_data).length > 0;
      }
      if (canProceed) {
        props.crmModuleModal({
          isOpen: true,
          exportcrmType: crmtype,
          selectedPersons: selectedPersons,
          applyFilter: applyFilter,
        });
      } else {
        navigate('/settings/crm');
      }
    } else {
      navigate('/settings/crm');
    }
  };

  const removePopup = className => {
    const existing = document.querySelectorAll(`.${className}`);
    existing.forEach(item => {
      if (item) {
        const parent = item.parentElement; // Get the parent element
        if (parent && parent.contains(item)) {
          // Ensure the parent contains the child
          parent.style.display = 'none';
        } else {
          console.warn('Parent does not contain the expected child:', item);
        }
      }
    });
  };

  useEffect(() => {
    const scrollContainer = window;

    const handleScroll = throttle(() => {
      removePopup('contactdetails');
      removePopup('labeldropdown');
      removePopup('menudropdown');
    }, 200); // Runs at most once every 200ms

    scrollContainer.addEventListener('scroll', handleScroll, true);

    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  return (
    <div className="px-8">
      {props.totalcount > 0 && !isLoading ? (
        <div className="mt-2 flow-root">
          <div className="flex flex-grow">
            <div className="flex -mx-4 mb-6 relative items-center grow shrink basis-0 self-stretch">
              {!processingExport ? (
                <div>
                  <input
                    type="checkbox"
                    className="rounded border-gray-500 text-primary hover:cursor-pointer"
                    ref={checkbox}
                    checked={checked}
                    onChange={toggleAll}
                  />
                </div>
              ) : null}
              {!processingExport ? (
                <div className="ml-4 mt-1 text-xs font-semibold">
                  {renderResultText()}
                </div>
              ) : null}
              {!processingExport ? (
                <div
                  className={classNames(
                    'transition ease-in duration-300 absolute left-5 -top-3 flex h-12 items-center space-x-3 bg-white sm:left-5',
                    selectedPersons.length > 0 ? 'visible' : 'invisible',
                  )}>
                  <div className="ml-2 mt-1 text-xs font-semibold">
                    {`${selectedPersons.length} selected`}
                  </div>
                  <div>
                    <ExportMenu
                      {...props.crmDetails}
                      onSelectedMenu={menuType => {
                        if (menuType === ExportMenuTypes.CSV) {
                          exportToCSV();
                        } else if (menuType === ExportMenuTypes.ZOHO) {
                          crmModalOpen('zoho');
                        } else if (menuType === ExportMenuTypes.SALESFORCE) {
                          crmModalOpen('salesforce');
                        } else if (menuType === ExportMenuTypes.ZAPIER) {
                          connectToZapier();
                        }
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className={classNames(
                    'transition ease-in duration-300 absolute left-5 -top-6 flex h-12 items-center space-x-3 bg-white sm:left-5',
                  )}>
                  <ProcessingIndicator message={'Processing Export'} />
                </div>
              )}
            </div>
          </div>
          {props.filteredListPeople ? (
            <div className={classNames('-my-2 overflow-x-auto -ml-16 -mr-20')}>
              <div
                className="inline-block min-w-full py-2 align-middle px-8"
                style={{height: 'calc(100vh / var(--zoom) - 290px)'}}>
                {isPaginating ? (
                  <div className="px-5">
                    <SkeletonLoader pagination />
                  </div>
                ) : (
                  <div className="relative">
                    <table className="w-full table-fixed divide-y divide-gray-300">
                      <thead>
                        <tr className="bg-gray-50">
                          <th scope="col" className="relative w-12" />
                          <th
                            scope="col"
                            className="py-3.5 pr-3 text-left text-xs font-normal text-gray-700 px-4 relative"
                            style={{width: '10%'}}>
                            {LocaleStrings.listpeople.tableHeaders.contactname}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-xs font-normal text-gray-700"
                            style={{width: '15%'}}>
                            {LocaleStrings.listpeople.tableHeaders.jobtitle}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-xs font-normal text-gray-700"
                            style={{width: '15%'}}>
                            {
                              LocaleStrings.listpeople.tableHeaders
                                .contactdetails
                            }
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-xs font-normal text-gray-700">
                            {LocaleStrings.listpeople.tableHeaders.companyname}
                          </th>

                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-xs font-normal text-gray-700"
                            style={{width: '15%'}}>
                            {LocaleStrings.listpeople.tableHeaders.labels}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-xs font-normal text-gray-700"
                            style={{width: '10%'}}>
                            {LocaleStrings.listpeople.tableHeaders.date}
                          </th>
                          <th />
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {props.filteredListPeople &&
                          props.filteredListPeople.map(person => {
                            const filtered = selectedPersons.filter(
                              item => item.peopleid === person.peopleid,
                            );
                            const selected = filtered.length > 0;
                            return (
                              <MyListPeopleCell
                                person={person}
                                selected={selected}
                                onCheckUnCheck={checked => {
                                  setSelectedPersons(
                                    checked
                                      ? [...selectedPersons, person]
                                      : selectedPersons.filter(
                                          p => p.peopleid !== person.peopleid,
                                        ),
                                  );
                                }}
                                onLabelSelection={label => {
                                  if (label.remove) {
                                    const newpeople = {
                                      ...person,
                                      contacttag: labelNotAssigned,
                                    };
                                    const index =
                                      props.filteredPeople.findIndex(
                                        t => t.peopleid === person.peopleid,
                                      );
                                    props.filteredPeople[index] = newpeople;
                                    props.updateRevealedPeople({
                                      totalcount: props.totalcount,
                                      pages: props.numPages,
                                      data: props.filteredPeople,
                                    });
                                    props.updateLabel(
                                      props.session,
                                      {
                                        contactid: person.contactid,
                                        tag: null,
                                      },
                                      _ => {},
                                    );
                                  } else if (!label.addnew) {
                                    const newpeople = {...person, tag: label};
                                    const index =
                                      props.filteredListPeople.findIndex(
                                        t => t.peopleid === person.peopleid,
                                      );
                                    props.filteredListPeople[index] = newpeople;
                                    props.updateListPerson({
                                      totalcount: props.totalcount,
                                      pages: props.numPages,
                                      data: props.filteredListPeople,
                                    });
                                    props.updateLabel(
                                      props.session,
                                      {
                                        listpeopleid: person.listpeopleid,
                                        tag: label.title,
                                      },
                                      _ => {},
                                    );
                                  } else {
                                    setSelectedList(person);
                                    setOpenManageLabelsPopup(true);
                                  }
                                }}
                                onDeleteFromList={person => {
                                  setShowDeleteAlert(true);
                                  setSelectedPersonToDelete(person);
                                }}
                                onClickFlag={contact => {
                                  if (contact.action === 'downvote') {
                                    setOpenFlagPopup(true);
                                    setSelectedContact({...contact, ...person});
                                  } else {
                                    toast.success(
                                      LocaleStrings.mycontacts_upvoted_success,
                                    );
                                  }
                                }}
                                onUpgradePlan={() => navigate('/upgradeplan')}
                                onBuyAddon={() => navigate('/creditaddons')}
                                onCopySuccess={() => {
                                  toast.success('Copied successfully');
                                }}
                              />
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          ) : null}
          {props.filteredListPeople && (
            <div>
              <PaginationBar
                curPage={curPage}
                numPages={props.numPages}
                onPageChange={curPage => {
                  if (!firstLoad) {
                    setIsPaginating(true);
                  }
                  setCurPage(curPage);
                }}
              />
            </div>
          )}
        </div>
      ) : isLoading && !isPaginating ? (
        <SkeletonLoader />
      ) : !isPaginating ? (
        <NoResult hasFilters />
      ) : null}
      <ManageLabelsPopups
        type={LABEL_TYPE_MYLIST}
        open={openManageLabelsPopup}
        selectedList={selectedList}
        toggleOpen={open => {
          setOpenManageLabelsPopup(open);
          setSelectedList(undefined);
        }}
      />
      <AppAlert
        open={showDeleteAlert}
        title={
          selectedPersonToDelete &&
          `Remove ${selectedPersonToDelete.contactname} from list`
        }
        message={`Are you sure you want to delete the person from list (${props.activeList.listname})?`}
        primaryButton={{title: 'Delete'}}
        secondaryButton={{title: 'Cancel'}}
        primaryButtonAction={() => {
          setShowDeleteAlert(false);

          props.removePersonFromlist(
            props.session,
            {
              listid: props.activeList.listid,
              peopleid: selectedPersonToDelete.peopleid,
            },
            _ => {},
          );
          const filtered = props.filteredListPeople.filter(
            people => people.peopleid !== selectedPersonToDelete.peopleid,
          );
          props.updateListPerson({
            data: filtered,
            totalcount: props.totalcount,
            pages: props.numPages,
          });
        }}
        secondaryButtonAction={() => {
          setShowDeleteAlert(false);
        }}
      />
      <Reportcontactpopup
        open={openFlagPopup}
        {...selectedContact}
        onClose={() => {
          setOpenFlagPopup(false);
        }}
        onSubmit={data => {
          updateContactMeta(
            selectedContact.contact,
            'downvote',
            selectedContact.contacttype,
            selectedContact.linkedin,
            data,
          );
          setOpenFlagPopup(false);
          setSelectedContact(undefined);
        }}
      />
      <Toaster />
    </div>
  );
}

function mapStateToProps(state) {
  var {session, user, mylistPeopleFilters} = state;
  const {totalcount, pages, data} = state.filteredListPeople;
  return {
    session,
    user,
    savedFilters: mylistPeopleFilters,
    totalcount,
    numPages: pages,
    filteredListPeople: data,
    activeList: state.activeList,
    crmDetails: state.crmDetails,
    crmType: state.crmType,
    appuserData: state.appuserData,
  };
}

export default connect(mapStateToProps, {
  fetchListPeople,
  updateListPerson,
  updateLabel,
  removePersonFromlist,
  addCRMLead,
  fetchCRMDetails,
  saveSelectedDPeople,
  contactmetaAddupdate,
  zapierHook,
  crmModuleModal,
})(ListPeopleTable);
