import LocaleStrings from '../components/languages';
export default function SkeletonLoader(props) {
  return (
    <>
      {props.enrich ? (
        <div className="animate-pulse my-8 flex flex-col w-full justify-center items-center">
          <div className="w-4/5 h-16 bg-gray-100 rounded mb-2"></div>
          <div className="w-4/5 h-20 bg-gray-100 rounded mb-2"></div>
          <div className="w-4/5 h-16 bg-gray-100 rounded mb-2"></div>
          <div className="w-4/5 h-20 bg-gray-100 rounded mb-2"></div>
          <div className="w-4/5 h-16 bg-gray-100 rounded mb-2"></div>
          <div className="w-4/5 h-16 bg-gray-100 rounded mb-2"></div>
        </div>
      ) : props.tableembeded ? (
        <div className="animate-pulse my-8">
          <div className="w-full h-16 bg-gray-100 rounded mb-2"></div>
          <div className="w-full h-16 bg-gray-100 rounded mb-2"></div>
          <div className="w-full h-16 bg-gray-100 rounded mb-2"></div>
          <div className="w-full h-16 bg-gray-100 rounded mb-2"></div>
        </div>
      ) : props.pagination ? (
        <div>
          <table className="w-full table-fixed divide-y divide-gray-300 px-8">
            <thead>
              <tr className="bg-gray-50">
                <th
                  scope="col"
                  className="sticky top-0 w-1/6 py-3.5 pr-3 text-left text-xs font-normal text-gray-700 relative px-7 bg-gray-50 z-50">
                  {LocaleStrings.search.peoplesearch.tableHeaders.contactname}
                </th>
                <th
                  scope="col"
                  className="sticky top-0 w-1/6 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
                  {LocaleStrings.search.peoplesearch.tableHeaders.jobtitle}
                </th>
                <th
                  scope="col"
                  className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50 w-[230px]">
                  {
                    LocaleStrings.search.peoplesearch.tableHeaders
                      .contactdetails
                  }
                </th>
                <th
                  scope="col"
                  className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
                  {LocaleStrings.search.peoplesearch.tableHeaders.companyname}
                </th>
                <th
                  scope="col"
                  className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
                  {LocaleStrings.search.peoplesearch.tableHeaders.industry}
                </th>

                <th className="sticky top-0 bg-gray-50 w-[80px] z-50" />
              </tr>
            </thead>
          </table>
          <div className="animate-pulse my-4 flex flex-col w-full justify-center items-center px-8 -mx-4">
            <div className="w-full h-16 bg-gray-100 rounded mb-2"></div>
            <div className="w-full h-16 bg-gray-100 rounded mb-2"></div>
            <div className="w-full h-16 bg-gray-100 rounded mb-2"></div>
            <div className="w-full h-16 bg-gray-100 rounded mb-2"></div>
            <div className="w-full h-16 bg-gray-100 rounded mb-2"></div>
            <div className="w-full h-16 bg-gray-100 rounded mb-2"></div>
            <div className="w-full h-16 bg-gray-100 rounded mb-2"></div>
          </div>
        </div>
      ) : props.small ? (
        <div className="animate-pulse my-8">
          <div className="w-2/3 h-16 bg-gray-100 rounded mb-2"></div>
          <div className="w-full h-20 bg-gray-100 rounded mb-2"></div>
          <div className="w-2/3 h-16 bg-gray-100 rounded mb-2"></div>
          <div className="w-full h-20 bg-gray-100 rounded mb-2"></div>
          <div className="w-2/3 h-16 bg-gray-100 rounded mb-2"></div>
          <div className="w-full h-20 bg-gray-100 rounded mb-2"></div>
        </div>
      ) : props.dashboard ? (
        <div>
          <table className="w-full table-fixed divide-y divide-gray-300 sm:px-6 lg:px-8" />
          <div className="animate-pulse">
            <div className="w-full h-24 bg-gray-100 rounded mb-1"></div>
            <div className="w-full h-40 bg-gray-100 rounded mb-4"></div>
            <div className="w-full h-36 bg-gray-100 rounded mb-6"></div>
            <div className="w-2/3 h-16 bg-gray-100 rounded mb-4"></div>
            <div className="w-full h-36 bg-gray-100 rounded mb-6"></div>
          </div>
        </div>
      ) : (
        <div>
          <table className="w-full table-fixed divide-y divide-gray-300 sm:px-6 lg:px-8 mt-8"></table>
          <div className="animate-pulse my-8">
            <div className="w-2/3 h-16 bg-gray-100 rounded mb-2"></div>
            <div className="w-full h-20 bg-gray-100 rounded mb-2"></div>
            <div className="w-2/3 h-16 bg-gray-100 rounded mb-2"></div>
            <div className="w-full h-20 bg-gray-100 rounded mb-2"></div>
            <div className="w-2/3 h-16 bg-gray-100 rounded mb-2"></div>
            <div className="w-full h-20 bg-gray-100 rounded mb-2"></div>
            <div className="w-full h-20 bg-gray-100 rounded mb-2"></div>
            <div className="w-2/3 h-16 bg-gray-100 rounded mb-2"></div>
            <div className="w-1/2 h-16 bg-gray-100 rounded mb-2"></div>
            <div className="w-full h-16 bg-gray-100 rounded mb-2"></div>
            <div className="w-full h-10 bg-gray-100 rounded"></div>
          </div>
        </div>
      )}
    </>
  );
}
