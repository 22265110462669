import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, reset} from 'redux-form';
import LocaleStrings from '@language';
import _ from 'lodash';
import toast, {Toaster} from 'react-hot-toast';
import {classNames} from '@commonFunction';
import {Fragment} from 'react';
import {Transition, Dialog} from '@headlessui/react';
import {BaseComponent, LABEL_POSITION_TOP} from '@baseComponent';
import {ENTERPRISE_MONTHLY, ENTERPRISE_YEARLY} from '@constant';
import {createApimodal, fetchApikeys, createApi} from '@settingsApiActions';
import {fetchAppuser} from '@sidebarActions';
import IconApi from './icons/api';
import BtnLoaderSvg from '@btnLoaderSvg';
class CreateApi extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isBtnDisable: false,
      btnLoader: false,
    };
    this.closeModal = this.closeModal.bind(this);
  }
  componentWillUnmount() {}

  closeModal() {
    this.props.createApimodal(false);
  }

  /***** Form Submit *****/
  onFormSubmit(values) {
    let {user, session, selectedTab} = this.props;
    if (values.apikey && values.purpose) {
      this.setState({isBtnDisable: true});
      this.setState({btnLoader: true});
      values.clientid = user.clientid;
      values.showbusinessemail = values.showbusinessemail ? 1 : 0;
      values.showcellnumber = values.showcellnumber ? 1 : 0;
      values.showpersonalemail = values.showpersonalemail ? 1 : 0;
      values.showtechnology = values.showtechnology ? 1 : 0;

      if (
        values.showbusinessemail === 0 &&
        values.showcellnumber === 0 &&
        values.showpersonalemail === 0
      ) {
        values.showbusinessemail = 1;
        values.showcellnumber = 1;
        values.showpersonalemail = 1;
      }

      values.keytype = selectedTab;
      this.props.createApi(session, values, callback => {
        if (callback && callback.success == 1) {
          this.props.fetchApikeys(session, callback => {
            this.props.fetchAppuser(session, user?.appuserid);
            toast.success(LocaleStrings.settings_api_createdsuccess);
            this.setState({isBtnDisable: false});
            this.setState({btnLoader: false});
            this.closeModal();
          });
        }
      });
    } else {
      toast.error(LocaleStrings.common_fail_message);
    }
  }

  render() {
    let {handleSubmit, appuserData, selectedTab} = this.props;
    let planid = '';
    if (
      appuserData &&
      appuserData.data &&
      appuserData.data.length > 0 &&
      appuserData.data[0].client_by_clientid
    ) {
      if (
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid
          .length > 0 &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid[0]
          ?.subscriptionplan_by_planid
      ) {
        let subscriptionplan_by_planid =
          appuserData.data[0]?.client_by_clientid
            ?.usersubscription_by_clientid[0]?.subscriptionplan_by_planid;
        planid = subscriptionplan_by_planid.planid;
      }
    }

    return (
      <>
        <Transition.Root show={this.props.iscreateapimodalOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.iscreateapimodalOpen}
            onClose={this.closeModal}
            initialFocus={this.myRef}>
            <div
              className="flex items-end justify-center pt-4 px-4 text-center sm:block sm:p-0 content-center"
              style={{height: 'calc(100vh / var(--zoom))'}}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block rounded-xl align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-3/12 "
                  style={{padding: 0}}>
                  <form
                    className=" "
                    onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
                    <div className="p-6 border-t-2 border-quaternary">
                      <div className="flex gap-4 pb-6">
                        <div className="w-8 h-8 bg-secondary rounded-full justify-center items-center flex">
                          <div className="justify-center items-center flex">
                            <IconApi />
                          </div>
                        </div>
                        <div>
                          <div className="text-sm mainGray">
                            {LocaleStrings.settings_api_createapibtn}
                          </div>
                          <div className="text-[11px] text-gray500 font-normal">
                            {LocaleStrings.settings_crtapi_modal_subh}
                          </div>
                        </div>
                      </div>
                      <div className="pb-6">
                        <Field
                          name="apikey"
                          label={
                            LocaleStrings.settings_crtapi_modal_label_keyname
                          }
                          placeholder={
                            LocaleStrings.settings_crtapi_modal_placeholder_keyname
                          }
                          type="text"
                          isdisabled="true"
                          component={this.renderFieldText}
                          mandatory="true"
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-xs font-normal text-gray700"
                        />
                      </div>
                      <div className="pb-6">
                        <Field
                          name="purpose"
                          label={
                            LocaleStrings.settings_crtapi_modal_label_apipurpose
                          }
                          placeholder={
                            LocaleStrings.settings_crtapi_modal_placeholder_apipurpose
                          }
                          type="text"
                          component={this.renderFieldText}
                          mandatory="true"
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-xs font-normal text-gray700"
                        />
                      </div>
                      {selectedTab == 'people' ? (
                        <>
                          <div className="pb-4">
                            <Field
                              name="showcellnumber"
                              label={LocaleStrings.settings_api_direct_phone}
                              type="checkbox"
                              component={this.renderFieldCheckbox}
                              mandatory="true"
                              labelposition={LABEL_POSITION_TOP}
                              classNameLabel="text-xs font-normal text-gray700"
                              className="focus:primary text-xs"
                            />
                          </div>
                          <div className="pb-4">
                            <Field
                              name="showbusinessemail"
                              label={LocaleStrings.settings_api_business_email}
                              type="checkbox"
                              component={this.renderFieldCheckbox}
                              mandatory="true"
                              labelposition={LABEL_POSITION_TOP}
                              classNameLabel="text-xs font-normal text-gray700"
                            />
                          </div>
                          <div className="pb-4">
                            <Field
                              name="showpersonalemail"
                              label={LocaleStrings.settings_api_personal_email}
                              type="checkbox"
                              component={this.renderFieldCheckbox}
                              mandatory="true"
                              labelposition={LABEL_POSITION_TOP}
                              classNameLabel="text-xs font-normal text-gray700"
                            />
                          </div>
                        </>
                      ) : planid == ENTERPRISE_MONTHLY ||
                        planid == ENTERPRISE_YEARLY ? (
                        <div className="pb-2">
                          {/* <Field
                            name="showtechnology"
                            label={LocaleStrings.settings_api_technology}
                            type="checkbox"
                            component={this.renderFieldCheckbox}
                            mandatory="true"
                            labelposition={LABEL_POSITION_TOP}
                            classNameLabel="text-sm font-normal text-gray700"
                          /> */}
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="flex">
                        <button
                          type="button"
                          onClick={this.closeModal}
                          className="w-full justify-center flex px-4 py-2 text-xs border border-gray-200 rounded-lg hover:bg-gray-100 text-black hover:cursor-pointer">
                          {LocaleStrings.cancel}
                        </button>
                        {/* Footer */}
                        <button
                          type="submit"
                          disabled={this.state.isBtnDisable}
                          className={classNames(
                            this.state.isBtnDisable ? 'cursor-not-allowed' : '',
                            'btn-primary ml-3',
                          )}>
                          <BtnLoaderSvg loader={this.state.btnLoader} />
                          {LocaleStrings.settings_crtapi_modal_createbtn}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  var requiredFields = ['apikey', 'purpose'];
  requiredFields.forEach(field => {
    if (!values[field] || _.trim(values[field]) === '') {
      errors[field] = LocaleStrings.required;
    }
  });
  return errors;
}

function mapStateToProps(state, ownProps) {
  var {session, user} = state;
  let intval = {};
  var apikey = ownProps.apikey;
  intval.apikey = apikey;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    iscreateapimodalOpen: state.iscreateapimodalOpen,
    initialValues: intval,
    appuserData: state.appuserData,
  };
}

export default connect(mapStateToProps, {
  createApimodal,
  fetchApikeys,
  createApi,
  fetchAppuser,
})(
  reduxForm({
    validate,
    form: 'CreateApiForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(CreateApi),
);
