import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import LocaleStrings from '@language';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// Import Action
import {
  showNotificatonCount,
  isNotificationModalOpen,
  onSidebarMenuSelected,
} from '@sidebarActions';
// Common Functions
import toast, {Toaster} from 'react-hot-toast';
import UpgradePricing from '@upgradePricing';
import {fetchAppuser} from '@sidebarActions';
import {fetchCurrentMemberCount, fetchApikeyCount} from '@upgradePlanActions';

class UpgradePlan extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    // All Binded Functions
  }

  componentDidMount() {
    let {session, user} = this.props;
    this.props.fetchAppuser(session, user.appuserid, callback => {
      this.props.fetchCurrentMemberCount(session, callback => {
        this.props.fetchApikeyCount(session, callback => {});
      });
    });
  }

  render() {
    let {appuserData} = this.props;
    let role = '';
    if (appuserData && appuserData.data && appuserData.data.length) {
      role = appuserData.data[0].role;
    }
    return (
      <>
        {role == 'client' ? (
          <div
            className="h-screen flex overflow-hidden bg-quaternary"
            style={{height: 'calc(100vh / var(--zoom) - 56px)'}}>
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
              <main
                className="main-tag-class- xs:px-6 sm:px-12 lg:px-10 py-4 overflow-y-auto bg-homegray scrollbar-hide"
                style={{height: 'calc(100vh / var(--zoom) - 20px)'}}>
                <UpgradePricing router={this.props.router} />
                <Toaster />
              </main>
            </div>
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var {session, user} = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    appuserData: state.appuserData,
  };
}

export default connect(mapStateToProps, {
  onSidebarMenuSelected,
  showNotificatonCount,
  isNotificationModalOpen,
  fetchAppuser,
  fetchCurrentMemberCount,
  fetchApikeyCount,
  // selectedProfileSettingsMenu,
})(UpgradePlan);
