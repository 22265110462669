import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import LocaleStrings from '@language';
import {Field, reduxForm} from 'redux-form';
import toast, {Toaster} from 'react-hot-toast';
import {classNames, isEmpty} from '@commonFunction';
import Dropzone from 'react-dropzone';
import {BaseComponent, LABEL_POSITION_TOP} from '@baseComponent';
import {
  fetchmyaccountData,
  uploadImage,
  updateAppuser,
  updateuserEmail,
  updateEmailModal,
  updateEmailcode,
} from '@settingsMyaccountActions';
import {fetchAppuser} from '@sidebarActions';
import UpdateUserEmail from './updateemail';
import SkeletonLoader from '../../../../../../common/skeletonloader';
import Requestemailchange from './requestemailchange';
import {updateNewEmailRequestModal} from '../actions';
import BtnLoaderSvg from '@btnLoaderSvg';
import {PencilIcon} from '@heroicons/react/outline';
class Myaccounts extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_myaccounts,
      phone: '',
      country: '',
      btnLoader: false,
      isBtnDisable: false,
      btnLoaderupdate: false,
      isBtnDisableupdate: false,
      fileUrl: '',
      fileArrNew: {},
      code: '',
      errors: {},
    };
    this.fieldRefs = {
      firstname: React.createRef(),
      lastname: React.createRef(),
      email: React.createRef(),
      phonenumber: React.createRef(),
      companyname: React.createRef(),
      country: React.createRef(),
      salespeoplecount: React.createRef(),
    };

    // All Binded Functions
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  componentDidMount() {
    let {user, session} = this.props;

    let appuserid = user?.appuserid;

    var url_string = this.props.router.location.search;
    var query = url_string.split('?')[1];

    var params = {};
    if (query) {
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
        // params[pair[1]] = decodeURIComponent(pair[1]);
      }

      if (params && params.code && params.code != '') {
        this.props.updateEmailcode(params);
        this.setState({code: params});
        let e = '';
        this.openeditEmailmodal(e);
      }
    }
    this.props.fetchmyaccountData(session, '', appuserid, callback => {
      this.setState({loading: false});
    });
  }

  validateForm(values) {
    var errors = {};
    var requiredFields = [];

    requiredFields = [
      'firstname',
      'lastname',
      'email',
      'companyname',
      'phonenumber',
      'country',
      'salespeoplecount',
    ];

    requiredFields.forEach(field => {
      if (!values[field] || _.trim(values[field]) === '') {
        errors[field] = LocaleStrings.required;
      }
    });
    return errors;
  }

  onSubmitForm(values) {
    let formValues = {...values};
    const errors = this.validateForm(formValues);
    let {initialValues, user, session} = this.props;

    let appuserid = user?.appuserid;

    if (Object.keys(errors).length > 0) {
      // If there are errors, set them in the state
      this.setState({errors});

      // Scroll to the first error field
      this.scrollToFirstError(errors);

      // Prevent form submission if there are errors
      return;
    }

    this.setState({btnLoader: true});
    this.setState({isBtnDisable: true});
    if (initialValues.email !== formValues.email) {
    } else {
      delete formValues['avatar'];
      if (
        formValues.phonenumber &&
        formValues.phonenumber !== null &&
        formValues.phonenumber !== '' &&
        _.trim(formValues.phonenumber.length) > 4
      ) {
        formValues.phonenumber = formValues.phonenumber.replace(
          /[- )( +]/g,
          '',
        );
      } else {
        this.setState({btnLoader: false, isBtnDisable: false});
        toast.error('Please Enter Valid Phone Number');
        return false;
      }

      // this.setState({ loading: true });
      if (formValues.firstname && formValues.lastname) {
        formValues.name = formValues.firstname + ' ' + formValues.lastname;
      }
      //   delete values['firstname'];
      //   delete values['lastname'];

      this.props.updateAppuser(this.props.session, formValues, response => {
        // console.log("response :- ", response);
        if (response.success === 0) {
          this.setState({btnLoader: false});
          this.setState({isBtnDisable: false});
          toast.error(LocaleStrings.something_went_wrong);
        } else {
          this.props.fetchmyaccountData(session, '', appuserid, callback => {
            this.setState({btnLoader: false});
            this.setState({isBtnDisable: false});
            toast.success(LocaleStrings.updated_successfully);
          });

          // alert("success");
        }
      });
    }
  }

  scrollToFirstError = errors => {
    const errorField = Object.keys(errors)[0];
    console.log('errorField', errorField);
    if (this.fieldRefs[errorField] && this.fieldRefs[errorField].current) {
      this.fieldRefs[errorField].current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  _phoneChange = (value, country, e, formattedValue) => {
    this.setState({phone: value, country: country.dialCode});
  };

  _handleDeleteimage(e) {
    let {initialValues} = this.props;
    this.setState({fileArrNew: {}});
    this.setState({fileUrl: ''});
  }
  // Add File
  onDrop = async files => {
    this.setState({imageLoader: true});

    let file = files[0];
    let filearr = [];

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = e => {
      file.url = e.target.result;
      filearr.push(file);
      this.setState({imageLoader: false});
    };

    this.setState({fileArrNew: filearr});
    this.setState({fileUrl: URL.createObjectURL(file)});
  };

  _handleUpdateimage(e, values) {
    let {user, session, initialValues} = this.props;
    // console.log("initialValues", initialValues);
    if (Object.keys(this.state.fileArrNew).length > 0) {
      this.setState({btnLoaderupdate: true});
      this.setState({isBtnDisableupdate: true});
      let resource_coverimage = '';
      let uploaderCounter = 0;
      let filetobeupload = [];
      let arrPromise = [];
      // Image Exist
      let fileArrNew = this.state.fileArrNew[0];

      let ts = new Date().getTime(); // Timestamp

      let medianame = initialValues.appuserid + '.' + 'png'; // Image Name Creation

      resource_coverimage = medianame;

      let ext = fileArrNew.name.split('.').pop(); // File extension

      let coverJson = {
        // type: "cover",
        type: 'image',
        name: medianame,
        media: fileArrNew.url,
      };
      filetobeupload.push(coverJson);
      let counter = filetobeupload.length;

      filetobeupload.map((item, index) => {
        arrPromise.push(
          new Promise((resolve, reject) => {
            this.props.uploadImage(this.props.session, item, response => {
              if (response.success === 0) {
                resolve();
              } else {
                uploaderCounter++;
                resolve();
              }
            });
          }),
        );
      });

      let updateImageobj = {
        avatar: medianame,
        role: initialValues.role,
        appuserid: initialValues.appuserid,
        clientid: initialValues.clientid,
      };

      Promise.all(arrPromise).then(() => {
        this.props.updateAppuser(
          this.props.session,
          updateImageobj,
          response => {
            if (response.success === 0) {
              this.setState({btnLoaderupdate: false});
              this.setState({isBtnDisableupdate: false});
              toast.error(LocaleStrings.something_went_wrong);
            } else {
              let appuserid = user?.appuserid;
              this.props.fetchmyaccountData(
                session,
                '',
                appuserid,
                callback => {
                  this.setState({fileArrNew: {}});
                  this.setState({fileUrl: ''});
                  this.setState({btnLoaderupdate: false});
                  this.setState({isBtnDisableupdate: false});
                  toast.success(LocaleStrings.settings_myaacounts_img_success);
                },
              );
              this.props.fetchAppuser(
                session,
                this.props.user.appuserid,
                callback => {},
              );
            }
          },
        );
      });
    } else {
      toast.error(LocaleStrings.settings_myaacounts_img_error);
    }
  }
  openeditEmailmodal() {
    this.props.updateEmailModal(true);
  }

  render() {
    let {handleSubmit, initialValues, myaccountdataList, session} = this.props;
    let {fileUrl, code, errors} = this.state;

    let coverimage = '';

    if (isEmpty(fileUrl)) {
      coverimage = initialValues?.avatar;
    } else {
      coverimage = fileUrl;
    }
    let countryOpts = [];

    _.forEach(this.props.countryList, function (value) {
      var obj = {
        name: value.name,
        value: value.name,
      };

      countryOpts.push(obj);
    });

    return (
      <>
        {this.state.loading ? (
          // <>
          //   <BaseLoader data={this.state.loaderDesc} />
          // </>
          <SkeletonLoader small />
        ) : (
          <>
            <form
              className="pt-0 p-6"
              onSubmit={this.props.handleSubmit(this.onSubmitForm)}>
              <div className="block pb-6 border-b border-gray-200 flex justify-between items-center">
                <div
                  className="dropzone-round"
                  key={`drop`}
                  style={{width: 80, height: 60}}>
                  <div className="img_wrpcover relative">
                    <img
                      loading="lazy"
                      src={coverimage}
                      className="mx-auto h-14 w-14 rounded-full"
                      alt=""
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src = '../..//images/user.png';
                      }}
                    />
                  </div>
                  <Dropzone
                    onDrop={e => this.onDrop(e)}
                    accept="image/jpeg, image/png ,image.jpg"
                    multiple={false}>
                    {({getRootProps, getInputProps}) => (
                      <div
                        {...getRootProps()}
                        className="cursor-pointer "
                        align="center">
                        <input {...getInputProps()} />
                        {this.state.imageLoader ? (
                          <BtnLoaderSvg loader={this.state.imageLoader} />
                        ) : (
                          <p className="text-ternary text-[11px]">
                            {LocaleStrings.drop_or_select}
                          </p>
                        )}
                      </div>
                    )}
                  </Dropzone>
                </div>
                <div>
                  <div className="flex">
                    {isEmpty(fileUrl) ? (
                      ''
                    ) : (
                      <div
                        className="cursor-pointer"
                        onClick={e => this._handleDeleteimage(e)}>
                        {LocaleStrings.settings_myaacounts_deletebtn}
                      </div>
                    )}
                    <div className="flex items-center ml-12 sm:ml-4 ">
                      <BtnLoaderSvg loader={this.state.btnLoaderupdate} />
                      <div
                        className={classNames(
                          this.state.isBtnDisableupdate
                            ? 'cursor-not-allowed'
                            : 'cursor-pointer',
                          'text-primary mt-4 sm:mt-0',
                        )}
                        onClick={e => this._handleUpdateimage(e)}>
                        {LocaleStrings.settings_myaacounts_btnupdatepic}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-6">
                <div className="w-full border-b border-gray-200 pb-4">
                  <div className="pb-2">
                    <div className="text-gray700 text-xs font-medium">
                      {LocaleStrings.settings_myaacounts_basicdetails}
                    </div>
                    <div className="text-gray500 text-[11px] font-normal">
                      {LocaleStrings.settings_myaacounts_displaytext}
                    </div>
                  </div>
                  <div className="flex gap-10">
                    <div ref={this.fieldRefs.firstname} className="w-1/2">
                      <Field
                        name="firstname"
                        label="First Name"
                        placeholder="Enter First Name"
                        type="text"
                        component={this.renderFieldText}
                        mandatory="true"
                        labelposition={LABEL_POSITION_TOP}
                        classNameLabel="text-xs font-normal text-secondary"
                      />
                      {this.state.errors.firstname && (
                        <div className="error-message">
                          {this.state.errors.firstname}
                        </div> // Show error message
                      )}
                    </div>
                    <div
                      ref={this.fieldRefs.lastname}
                      className="w-1/2 box-border">
                      <Field
                        name="lastname"
                        label="Last Name"
                        placeholder="Enter Last Name"
                        type="text"
                        component={this.renderFieldText}
                        mandatory="true"
                        labelposition={LABEL_POSITION_TOP}
                        classNameLabel="text-xs font-normal text-secondary"
                      />
                      {this.state.errors.lastname && (
                        <div className="error-message">
                          {this.state.errors.lastname}
                        </div> // Show error message
                      )}
                    </div>
                  </div>
                  <div className="flex gap-10 mt-4">
                    <div
                      className="w-1/2 box-border"
                      ref={this.fieldRefs.phonenumber}>
                      <Field
                        name="phonenumber"
                        label={LocaleStrings.settings_myaacounts_field_phone}
                        type="text"
                        component={this.renderPhonewithFlag}
                        mandatory="true"
                        classNameLabel="text-xs font-normal text-secondary"
                        phone={this.state.phone}
                        phoneChange={this._phoneChange}
                      />
                      {this.state.errors.phonenumber && (
                        <div className="error-message">
                          {this.state.errors.phonenumber}
                        </div> // Show error message
                      )}
                    </div>
                    <div className="block flex justify-between items-center w-1/2">
                      <div ref={this.fieldRefs.email} className="w-full">
                        <Field
                          name="email"
                          label={
                            LocaleStrings.settings_myaacounts_field_businessemail
                          }
                          placeholder={
                            LocaleStrings.settings_myaacounts_field_businessemail
                          }
                          type="text"
                          isdisabled="true"
                          component={this.renderFieldText}
                          mandatory="true"
                          classNameLabel="text-xs font-normal text-secondary"
                        />
                        {this.state.errors.email && (
                          <div className="error-message">
                            {this.state.errors.email}
                          </div> // Show error message
                        )}
                      </div>
                      <div>
                        {this.props.socialLoginUsed ? null : (
                          <div
                            type=""
                            className="cursor-pointer text-primary text-xs"
                            style={{width: 85, marginLeft: 10, marginTop: 20}}
                            onClick={e => {
                              this.props.updateNewEmailRequestModal(true);
                            }}>
                            {LocaleStrings.settings_myaacounts_change_emailbtn}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {myaccountdataList && myaccountdataList.role == 'client' ? (
                <>
                  <div className="block py-6 flex gap-10">
                    <div className="w-1/2">
                      <div className="pb-2">
                        <div className="text-gray700 text-xs font-medium">
                          {LocaleStrings.settings_myaacounts_businessdetails}
                        </div>
                        <div className="text-gray500 text-[11px] font-normal">
                          {
                            LocaleStrings.settings_myaacounts_businessdetails_subtitle
                          }
                        </div>
                      </div>
                      <div ref={this.fieldRefs.companyname}>
                        <Field
                          name="companyname"
                          label={
                            LocaleStrings.settings_myaacounts_field_label_company
                          }
                          placeholder={
                            LocaleStrings.settings_myaacounts_field_placeholder_company
                          }
                          type="text"
                          component={this.renderFieldText}
                          mandatory="true"
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-xs font-normal text-secondary"
                        />
                        {this.state.errors.companyname && (
                          <div className="error-message">
                            {this.state.errors.companyname}
                          </div> // Show error message
                        )}
                      </div>

                      <div className="mt-4" ref={this.fieldRefs.country}>
                        <Field
                          name="country"
                          label={
                            LocaleStrings.settings_myaacounts_field_country
                          }
                          placeholder={'Select Country'}
                          component={this.renderSelect}
                          labelposition={LABEL_POSITION_TOP}
                          opts={countryOpts}
                          mandatory="true"
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-xs border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="text-xs font-normal text-secondary"
                        />
                        {this.state.errors.country && (
                          <div className="error-message">
                            {this.state.errors.country}
                          </div> // Show error message
                        )}
                      </div>
                      <div
                        className="mt-4"
                        ref={this.fieldRefs.salespeoplecount}>
                        <Field
                          name="salespeoplecount"
                          label={
                            LocaleStrings.settings_myaacounts_field_label_salespeoplecount
                          }
                          placeholder={
                            LocaleStrings.settings_myaacounts_field_label_salespeoplecount
                          }
                          type="number"
                          min="0"
                          mandatory="true"
                          component={this.renderFieldText}
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-xs font-normal text-secondary"
                        />
                        {this.state.errors.salespeoplecount && (
                          <div className="error-message">
                            {this.state.errors.salespeoplecount}
                          </div> // Show error message
                        )}
                      </div>
                      <div className="mt-4">
                        <Field
                          name="requiredleadcount"
                          label={
                            LocaleStrings.settings_myaacounts_field_label_leadcountrequired
                          }
                          placeholder={
                            LocaleStrings.settings_myaacounts_field_label_leadcountrequired
                          }
                          type="number"
                          min="0"
                          component={this.renderFieldText}
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-xs font-normal text-secondary"
                        />
                      </div>
                      <div className="mt-4">
                        <Field
                          name="department"
                          label={
                            LocaleStrings.settings_myaacounts_field_label_department
                          }
                          placeholder={
                            LocaleStrings.settings_myaacounts_field_label_department
                          }
                          type="text"
                          component={this.renderFieldText}
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-xs font-normal text-secondary"
                        />
                      </div>
                    </div>
                    <div className="block w-1/2">
                      <div className="w-full">
                        <div className="pb-2">
                          <div className="text-gray700 text-xs font-medium">
                            {LocaleStrings.settings_myaacounts_taxbillingheader}
                          </div>
                          <div className="text-gray500 text-[11px] font-normal">
                            {
                              LocaleStrings.settings_myaacounts_taxbilling_subtitle
                            }
                          </div>
                        </div>
                        <div>
                          <Field
                            name="taxid"
                            label={
                              LocaleStrings.settings_myaacounts_field_label_taxid
                            }
                            placeholder={
                              LocaleStrings.settings_myaacounts_field_placeholder_taxid
                            }
                            type="text"
                            component={this.renderFieldText}
                            labelposition={LABEL_POSITION_TOP}
                            classNameLabel="text-xs font-normal text-secondary"
                          />
                        </div>
                        <div className="mt-4">
                          <Field
                            name="billingaddress"
                            label={
                              LocaleStrings.settings_myaacounts_field_label_billingaddress
                            }
                            placeholder={
                              LocaleStrings.settings_myaacounts_field_placeholder_billingaddress
                            }
                            component={this.renderFieldTextarea}
                            maxlength={200}
                            labelposition={LABEL_POSITION_TOP}
                            classNameLabel="text-xs font-normal text-secondary"
                            className="py-3 px-4 block w-full text-secondary text-xs border border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              <div className="flex justify-between">
                <div />
                <div className="flex mt-4">
                  <button
                    type="submit"
                    disabled={this.state.isBtnDisable}
                    className={classNames(
                      this.state.isBtnDisable ? 'cursor-not-allowed' : '',
                      'btn-primary ml-4 cursor-pointer',
                    )}>
                    <BtnLoaderSvg loader={this.state.btnLoader} />
                    {LocaleStrings.settings_myaacounts_btnupdate}
                  </button>
                </div>
              </div>
            </form>
          </>
        )}
        <UpdateUserEmail router={this.props.router} />
        <Requestemailchange router={this.props.router} />
        <Toaster toastOptions={{className: 'text-sm'}} />
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  // var requiredFields = [];

  // requiredFields = [
  //   'firstname',
  //   'lastname',
  //   'email',
  //   'companyname',
  //   'phonenumber',
  //   'country',
  //   'salespeoplecount',
  // ];

  // requiredFields.forEach(field => {
  //   if (!values[field] || _.trim(values[field]) === '') {
  //     errors[field] = LocaleStrings.required;
  //   }
  // });
  // return errors;
}

function mapStateToProps(state, ownProps) {
  var {session, user, socialLoginUsed} = state;
  return {
    session,
    user,
    myaccountdataList: state.myaccountdataList?.data,
    initialValues: state.myaccountdataList?.data,
    countryList: state.countryList,
    socialLoginUsed,
  };
}

export default connect(mapStateToProps, {
  fetchmyaccountData,
  uploadImage,
  updateAppuser,
  fetchAppuser,
  updateuserEmail,
  updateEmailModal,
  updateEmailcode,
  updateNewEmailRequestModal,
})(
  reduxForm({
    validate,
    form: 'MyaccountsForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Myaccounts),
);
