import React, {Component} from 'react';
import {connect} from 'react-redux';
import LocaleStrings from '@language';
import IconUpgrade from '../../billing/icons/upgrade';
import _ from 'lodash';
import toast, {Toaster} from 'react-hot-toast';
import {
  fetchCRMDetails,
  saveCRMType,
  crmDisconnect,
  crmMakeconnection,
  mapFieldsModal,
  getallZohoFields,
  getallSFFields,
  webHookModal,
} from '../../../../search/actions';
import {
  ZOHO_CLIENTID,
  ZOHO_URL,
  SALESFORCE_CLIENTID,
  SALESFORCE_URL,
} from '@constant';
import MapfieldModal from './mapfields';
import AddhookurlModal from './addhookurl';
import MappingSelectionProvider from './selectioncontext';
import {CheckIcon} from '@heroicons/react/solid';

class Crm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_currency,
      zohoDisconnectionLoading: false,
      salesForceDisconnectionLoading: false,
      zapierLoading: false,
      zohoLoading: false,
      salesForceLoading: false,
      params: {},
      modalstate: null,
    };

    // All Binded Functions
  }

  componentDidMount() {
    let {session, user} = this.props;
    this.props.fetchCRMDetails(session, resp => {});
  }

  componentWillReceiveProps(nextProps) {
    //console.log('componentWillReceiveProps', nextProps);
    var url_string = nextProps.router.location.search;
    var query = url_string.split('?')[1];
    var params = {};
    if (query && Object.keys(this.state.params).length == 0) {
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
        // params[pair[1]] = decodeURIComponent(pair[1]);
      }

      if (params && params.code) {
        const currentURL = window.location.href;
        const isLocal = currentURL.includes('localhost');
        let pathname = 'settings/crm';
        const redirectUri = isLocal
          ? 'http://localhost:3000/' + pathname
          : 'https://phase2.kipplodev.com/' + pathname;
        let crmtype = nextProps.crmType == 'salesforce' ? 'salesforce' : 'zoho';
        let values = {};
        values.code = params.code;
        values.crmtype = crmtype;
        values.redirect_uri = redirectUri;
        //console.log('values', values);
        if (crmtype == 'zoho') {
          this.setState({zohoLoading: true});
        } else {
          this.setState({salesForceLoading: true});
        }
        this.props.crmMakeconnection(nextProps.session, values, res => {
          this.props.fetchCRMDetails(nextProps.session, resp => {
            if (crmtype == 'zoho') {
              //this.props.getallZohoFields(this.props.session, res => {});
              this.setState({zohoLoading: false});
              this.openfieldModal('zoho');
            } else {
              //this.props.getallSFFields(this.props.session, res => {});
              this.setState({salesForceLoading: false});
              this.openfieldModal('salesforce');
            }
          });
        });
      }

      this.setState({params: params});
    }
  }

  _handelredirect = (e, redirect) => {
    this.props.router.navigate('/dashboard/' + redirect);
  };

  disConnect = crmtype => {
    if (crmtype == 'zoho') {
      this.setState({zohoDisconnectionLoading: true});
    } else if (crmtype == 'salesforce') {
      this.setState({salesForceDisconnectionLoading: true});
    } else {
      this.setState({zapierLoading: true});
    }
    this.props.crmDisconnect(this.props.session, crmtype, res => {
      if (res.success == 1) {
        this.props.fetchCRMDetails(this.props.session, resp => {
          if (crmtype == 'zoho') {
            this.setState({zohoDisconnectionLoading: false});
          } else if (crmtype == 'salesforce') {
            this.setState({salesForceDisconnectionLoading: true});
          } else {
            this.setState({zapierLoading: false});
          }
        });
      } else {
        if (crmtype == 'zoho') {
          this.setState({zohoDisconnectionLoading: false});
        } else if (crmtype == 'salesforce') {
          this.setState({salesForceDisconnectionLoading: true});
        } else {
          this.setState({zapierLoading: false});
        }
      }
    });
  };

  connectToZoho = () => {
    const currentURL = window.location.href;
    const isLocal = currentURL.includes('localhost');
    let pathname = 'settings/crm';
    const redirectUri = isLocal
      ? 'http://localhost:3000/' + pathname
      : 'https://phase2.kipplodev.com/' + pathname;
    const scope =
      'ZohoCRM.modules.leads.ALL ZohoCRM.modules.contacts.ALL ZohoCRM.modules.accounts.ALL ZohoSearch.securesearch.READ ZohoCRM.settings.fields.ALL ZohoCRM.org.READ';
    this.props.saveCRMType(this.props.session, 'zoho');
    const authUrl = `${ZOHO_URL}?scope=${scope}&client_id=${ZOHO_CLIENTID}&response_type=code&redirect_uri=${redirectUri}&access_type=offline`;
    window.location = authUrl;
  };

  connectToSalesForce = () => {
    const currentURL = window.location.href;
    const isLocal = currentURL.includes('localhost');
    let pathname = 'settings/crm';
    const redirectUri = isLocal
      ? 'http://localhost:3000/' + pathname
      : 'https://phase2.kipplodev.com/' + pathname;
    this.props.saveCRMType(this.props.session, 'salesforce');
    const authUrl = `${SALESFORCE_URL}?client_id=${SALESFORCE_CLIENTID}&grant_type=authorization_code&response_type=code&redirect_uri=${redirectUri}&scope=api+refresh_token+offline_access`;
    window.location = authUrl;
  };

  updateState = type => {
    this.setState({modalstate: type});
  };

  openfieldModal = type => {
    this.updateState(type);
    this.props.mapFieldsModal(true);

    let {crmDetails} = this.props;
    let moduleTypesArr = [];
    // console.log('crmDetails', crmDetails);
    // if (type == 'zoho' && crmDetails && crmDetails.zoho_moduletype) {
    //   moduleTypesArr = crmDetails.zoho_moduletype.split(',');
    // } else if (
    //   type == 'salesforce' &&
    //   crmDetails &&
    //   crmDetails.salesforce_moduletype
    // ) {
    //   console.log(
    //     'crmDetails.salesforce_moduletype',
    //     crmDetails.salesforce_moduletype,
    //   );
    //   moduleTypesArr = crmDetails.salesforce_moduletype.split(',');
    // }
    // console.log('moduleTypesArr', moduleTypesArr);
    // debugger;
    // if (moduleTypesArr.length > 0) {
    //   moduleTypesArr.forEach(moduleType => {
    //     if (type == 'zoho') {
    //       let values = {module: _.startCase(moduleType)};
    //       this.props.getallZohoFields(this.props.session, values, res => {});
    //     }

    //     if (type == 'salesforce') {
    //       let values = {module: _.startCase(moduleType).slice(0, -1)};
    //       this.props.getallSFFields(this.props.session, values, res => {});
    //     }
    //   });
    // }
  };

  _openUpgradPlan = () => {
    this.props.router.navigate('/upgradeplan');
  };

  showToast = (section, type) => {
    // console.log('section', section);
    // console.log('type', type);
    if (section == 'add') {
      if (type == 'success') {
        toast.success(LocaleStrings.settings_crm_webhookurl_add_success);
      } else {
        toast.error(LocaleStrings.common_fail_message);
      }
    } else if (section == 'update') {
      if (type == 'success') {
        toast.success(LocaleStrings.settings_crm_webhookurl_update_success);
      } else {
        toast.error(LocaleStrings.common_fail_message);
      }
    } else {
      if (type == 'success') {
        toast.success(LocaleStrings.settings_crm_webhook_delete_success);
      } else {
        toast.error(LocaleStrings.common_fail_message);
      }
    }
  };

  render() {
    let {crmDetails, appuserData} = this.props;
    let {
      salesForceDisconnectionLoading,
      zohoDisconnectionLoading,
      zohoLoading,
      salesForceLoading,
      modalstate,
      zapierLoading,
    } = this.state;
    //console.log('crmDetails', crmDetails);
    return (
      <>
        <MappingSelectionProvider>
          {appuserData &&
          appuserData.availability &&
          appuserData.availability.crm_availablity == 0 ? (
            <div className="flex flex-col justify-center items-center h-full gap-4">
              <div className="text-sm text-ternary">
                {LocaleStrings.integration_api_unlock}
              </div>
              <button
                type="submit"
                style={{width: 120}}
                onClick={() => this._openUpgradPlan()}
                className="btn-primary ml-4 cursor-pointer text-xs">
                {LocaleStrings.upgrade_now}
              </button>
            </div>
          ) : (
            <div className="xs:px-6 md:px-0">
              <div className="block md:flex pb-10 border-b border-gray-200 mb-4">
                <div>
                  <div className="text-mainGray text-sm font-medium">
                    {LocaleStrings.crm_title}
                  </div>
                  <div className="text-gray500 text-[11px] font-normal">
                    {LocaleStrings.crm_subtitle}
                  </div>
                </div>
                <div className="ml-auto xs:mt-4 md:mt-0"></div>
              </div>
              <div className="text-gray700 text-xs font-semibold">
                {LocaleStrings.setting_crm_heading}
              </div>
              <div class=" mt-4">
                <div class="flex gap-3">
                  <div class="bg-white p-5 rounded-lg min-h-[160px] shadow-md w-80">
                    <div class="bg-white rounded flex flex-col h-full">
                      <div class="flex gap-4 items-center">
                        <div>
                          <img src="../../images/zoho.png" />
                        </div>
                        <div>{LocaleStrings.setting_crm_tab1}</div>
                      </div>
                      <div class="my-5 text-xs text-gray-600">
                        <div>{LocaleStrings.crm_zoho}</div>
                      </div>
                      <div class="mt-auto flex items-center justify-between">
                        <div className="text-xs">
                          {crmDetails &&
                          crmDetails.hasOwnProperty('zoho') &&
                          crmDetails.zoho ? (
                            <div className="flex items-center text-[10px] text-green-600">
                              <CheckIcon
                                className="h-3 w-3 text-green-600"
                                aria-hidden="true"
                              />
                              {LocaleStrings.setting_crm_account_connected}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>

                        <div>
                          {crmDetails &&
                          crmDetails.hasOwnProperty('zoho') &&
                          crmDetails.zoho ? (
                            <button
                              type="button"
                              class="btn-light-primary cursor-pointer"
                              onClick={() => this.openfieldModal('zoho')}>
                              {LocaleStrings.setting_crm_btn_manage}
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn-light-primary  cursor-pointer"
                              onClick={e => this.connectToZoho()}>
                              {LocaleStrings.setting_crm_btn_connect}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="bg-white p-5 rounded-lg min-h-[160px] shadow-md w-80">
                    <div class="bg-white rounded flex flex-col h-full">
                      <div class="flex gap-4 items-center">
                        <div>
                          <img src="../../images/salesforce.svg" width={32} />
                        </div>
                        <div>{LocaleStrings.setting_crm_tab2}</div>
                      </div>
                      <div class="my-5 text-xs text-gray-600">
                        {LocaleStrings.crm_salesfocrce}
                      </div>
                      <div class="mt-auto flex items-center justify-between">
                        <div className=" text-xs">
                          {crmDetails &&
                          crmDetails.hasOwnProperty('salesforce') &&
                          crmDetails.salesforce ? (
                            <div className="flex items-center text-[10px] text-green-600">
                              <CheckIcon
                                className="h-3 w-3 text-green-600"
                                aria-hidden="true"
                              />
                              {LocaleStrings.setting_crm_account_connected}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        <div>
                          {crmDetails &&
                          crmDetails.hasOwnProperty('salesforce') &&
                          crmDetails.salesforce ? (
                            <button
                              type="button"
                              className="btn-light-primary  cursor-pointer text-xs"
                              onClick={() => this.openfieldModal('salesforce')}>
                              {LocaleStrings.setting_crm_btn_manage}
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn-light-primary  cursor-pointer text-xs"
                              onClick={e => this.connectToSalesForce()}>
                              {LocaleStrings.setting_crm_btn_connect}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-gray700 text-xs font-semibold pt-4">
                {LocaleStrings.setting_webhook_connector_heading}
              </div>
              <div class="mt-4">
                <div class="flex">
                  <div className="bg-white p-5 rounded-lg min-h-[160px] shadow-md w-80 flex flex-col">
                    <div className="flex gap-4 items-center">
                      <div>
                        <img
                          src="../../images/webhook.png"
                          alt="Webhook Icon"
                        />
                      </div>
                      <div>{LocaleStrings.setting_crm_tab3}</div>
                    </div>
                    <div className="my-4">
                      <div className="text-xs text-gray-600">
                        {LocaleStrings.crm_webhook}
                      </div>
                    </div>
                    <div className="flex-grow"></div>
                    <div className="mt-auto flex items-center justify-between">
                      <div className="text-xs">
                        {crmDetails &&
                        crmDetails.hasOwnProperty('zapier') &&
                        crmDetails.zapier ? (
                          <div className="flex items-center text-[10px] text-green-600">
                            <CheckIcon
                              className="h-3 w-3 text-green-600"
                              aria-hidden="true"
                            />
                            {LocaleStrings.setting_crm_account_connected}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div>
                        {crmDetails &&
                        crmDetails.hasOwnProperty('zapier') &&
                        crmDetails.zapier ? (
                          <button
                            type="button"
                            onClick={() => this.props.webHookModal(true)}
                            className="btn-light-primary cursor-pointer">
                            {LocaleStrings.setting_crm_btn_manage}
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn-light-primary cursor-pointer"
                            onClick={() => this.props.webHookModal(true)}>
                            {LocaleStrings.setting_crm_btn_connect}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <MapfieldModal
            modalstate={modalstate}
            updateState={this.updateState}
          />
          <AddhookurlModal showToast={this.showToast} />
        </MappingSelectionProvider>
        <Toaster toastOptions={{className: 'text-xs'}} />
      </>
    );
  }
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  crmDetails: state.crmDetails,
  crmType: state.crmType,
  appuserData: state.appuserData,
});

export default connect(mapStateToProps, {
  fetchCRMDetails,
  saveCRMType,
  crmDisconnect,
  crmMakeconnection,
  mapFieldsModal,
  getallZohoFields,
  getallSFFields,
  webHookModal,
})(Crm);
