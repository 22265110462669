import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import LocaleStrings from '@language';
import {convertDateIntoLocal} from '@commonFunction';
import {CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

class Dashboardsubheader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_currency,
    };

    // All Binded Functions
  }

  render() {
    let {user, appuserData} = this.props;
    // let seatcount = 0;
    // let availableseats = 0;
    // let percentage = 0;
    // let usedseats =0;

    let name = 'No plans Added';
    let price = '';
    let contactcredit = 0;
    let details = '';
    let availableseats = 0;
    let availableapicall = 0;
    let seatcount = 'No Seat';
    let apicallcount = 'No Api';
    let usedseats = 0;
    let usedapi = 0;
    let expirydate = '';
    let percentage = 0;
    let percentage1 = 0;
    let totalPeopleKeys = 0;
    let totalPeopleApiUsageCount = 0;
    let totalCompanyKeys = 0;
    let totalCompanyApiUsageCount = 0;
    let peopleapiPercentage = 0;
    let companyapiPercentage = 0;

    if (appuserData && appuserData.data && appuserData.data.length > 0) {
      seatcount = appuserData.data[0].membercount;
    }

    if (
      appuserData &&
      appuserData.data &&
      appuserData.data.length > 0 &&
      appuserData.data[0].client_by_clientid
    ) {
      let client_by_clientid = appuserData.data[0]?.client_by_clientid;
      if (
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid
          .length > 0
      ) {
        let usersubscription_by_clientid =
          appuserData.data[0]?.client_by_clientid
            ?.usersubscription_by_clientid[0];
        expirydate = convertDateIntoLocal(
          usersubscription_by_clientid.expirydate,
        ).format('D MMM YYYY');
      }
      if (
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid
          .length > 0 &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid[0]
          ?.subscriptionplan_by_planid
      ) {
        let subscriptionplan_by_planid =
          appuserData.data[0]?.client_by_clientid
            ?.usersubscription_by_clientid[0]?.subscriptionplan_by_planid;
        name = subscriptionplan_by_planid.name;
        price = subscriptionplan_by_planid.price;
        details = subscriptionplan_by_planid.details;

        availableapicall = subscriptionplan_by_planid.availableapicall;
      }

      if (
        appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid.length >
          0
      ) {
        let usercredit_by_clientid =
          appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid[0];
        availableseats = usercredit_by_clientid.seatcount;
        contactcredit = usercredit_by_clientid.contactcredit;
        apicallcount = usercredit_by_clientid.apicallcount;
      }

      if (
        appuserData.data[0]?.client_by_clientid?.apikeys_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.apikeys_by_clientid.length > 0
      ) {
        const apikeys_by_clientid =
          appuserData.data[0]?.client_by_clientid?.apikeys_by_clientid;

        const apikeysArr = _.filter(
          apikeys_by_clientid,
          o => o.isactive === 1 && o.isdeleted === 0,
        );

        apikeysArr.forEach(key => {
          const apiUsageCount = Array.isArray(key.apiusage_by_apikey)
            ? key.apiusage_by_apikey.length > 0
              ? 1
              : 0
            : 0;

          // Group keys by keytype and calculate totals
          if (key.keytype === 'people') {
            totalPeopleKeys++;
            totalPeopleApiUsageCount += apiUsageCount;
          } else if (key.keytype === 'company') {
            totalCompanyKeys++;
            totalCompanyApiUsageCount += apiUsageCount;
          }
        });
      }

      if (availableseats !== 0 && seatcount !== 'No Seat') {
        usedseats = parseInt(seatcount);
        availableseats = availableseats + usedseats;
        percentage = (parseInt(usedseats) / availableseats) * 100;
      } else {
        usedseats = 0;
        percentage = 0;
      }

      if (totalPeopleApiUsageCount > 0 && totalPeopleKeys > 0) {
        peopleapiPercentage =
          (parseInt(totalPeopleApiUsageCount) / totalPeopleKeys) * 100;
      }
      if (totalCompanyApiUsageCount > 0 && totalCompanyKeys > 0) {
        companyapiPercentage =
          (parseInt(totalPeopleApiUsageCount) / totalCompanyKeys) * 100;
      }
    }

    return (
      <>
        <div className="grid  grid-cols-1 md:grid-cols-3 mt-5">
          <div className="cols-span-4 pr-2">
            <div className="overflow-hidden bg-white shadow sm:rounded-lg py-4 px-6">
              <div>{LocaleStrings.dashboard_main_subheader_teammembers}</div>
              <div className="flex pt-4 px-3">
                <CircularProgressbar
                  className="team-member-circularprogressbar"
                  value={percentage}
                />
                <div>
                  <div className="px-4 text-xl font-medium">
                    {usedseats}/{availableseats}
                  </div>
                  <div className="px-4 text-xs mt-2 text-gray500">
                    {LocaleStrings.dashboard_main_subheader_usedseats} /{' '}
                    {LocaleStrings.dashboard_main_subheader_totalseats}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cols-span-4 xs:my-2 xs:pl-0 xs:pr-2 md:my-0 md:pr-0 md:pl-2">
            <div className="overflow-hidden bg-white shadow sm:rounded-lg py-4 px-6">
              <div>{LocaleStrings.dashboard_main_subheader_apikey}</div>
              <div className="flex pt-4 px-3">
                <CircularProgressbar
                  className="team-member-circularprogressbar"
                  value={peopleapiPercentage}
                />
                <div>
                  <div className="px-4 text-xl font-medium">
                    {totalPeopleApiUsageCount}/{totalPeopleKeys}
                  </div>
                  <div className="px-4 text-xs mt-2 text-gray500">
                    {LocaleStrings.dashboard_main_subheader_usedtotalkey}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cols-span-4 xs:my-2 xs:pl-0 xs:pr-2 md:my-0 md:pr-0 md:pl-2">
            <div className="overflow-hidden bg-white shadow sm:rounded-lg py-4 px-6">
              <div>{LocaleStrings.dashboard_main_subheader_comapny_apikey}</div>
              <div className="flex pt-4 px-3">
                <CircularProgressbar
                  className="team-member-circularprogressbar"
                  value={companyapiPercentage}
                />
                <div>
                  <div className="px-4 text-xl font-medium">
                    {totalCompanyApiUsageCount}/{totalCompanyKeys}
                  </div>
                  <div className="px-4 text-xs mt-2 text-gray500">
                    {LocaleStrings.dashboard_main_subheader_usedtotalkey}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  appuserData: state.appuserData,
});

export default connect(mapStateToProps, {})(Dashboardsubheader);
