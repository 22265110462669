import React, {useEffect} from 'react';
import debounce from 'lodash/debounce';

const TooltipPopover = ({
  children,
  coords,
  updateTooltipCoords = () => {},
  direction,
  width,
  theClassName = 'tooltippopover',
}) => {
  const updateCoords = debounce(updateTooltipCoords, 100);

  const isLast = coords.top > 700;

  useEffect(() => {
    window.addEventListener('resize', updateCoords);
    return () => window.removeEventListener('resize', updateCoords);
  }, [updateCoords]);

  const translateX =
    direction === 'bottom'
      ? `-${width}px`
      : direction === 'right'
        ? '10px'
        : `-${width}px`;

  const translateY =
    direction === 'bottom'
      ? isLast
        ? '-280px'
        : '40px'
      : direction === 'right'
        ? isLast
          ? '-80%'
          : '2%'
        : isLast
          ? '-40%'
          : '2%';

  return (
    <div
      style={{
        ...styles.popover,
        transform: `translate(${translateX}, ${translateY})`,
        ...coords,
        zIndex: 9999,
        width,
      }}
      className={theClassName}>
      {children}
    </div>
  );
};

const styles = {
  popover: {
    position: 'absolute',
  },
};

export default TooltipPopover;
