import {useState, useEffect} from 'react';
import SubLabel from './sublabel';
import {connect} from 'react-redux';

const addNewLabel = {title: 'Add New', color: '#6b7280', addnew: true};
const clearLabel = {title: 'Clear', color: '#c62828', remove: true};

function LabelsDropdown(props) {
  return (
    <div className="w-96 h-auto p-3 bg-white rounded-lg drop-shadow-xl border border-gray-200 flex-col justify-start items-start gap-3 inline-flex labeldropdown">
      <div className="self-stretch justify-start items-start gap-6 inline-flex">
        <div className="justify-start items-start gap-2 flex-wrap">
          {<SubLabel key={addNewLabel.title} {...addNewLabel} {...props} />}
          {props.availableLabels.map(label => (
            <SubLabel key={label.title} {...label} {...props} />
          ))}
          {props.tag ? (
            <SubLabel key={clearLabel.title} {...clearLabel} {...props} />
          ) : null}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    availableLabels: state.availableLabels,
  };
}

export default connect(mapStateToProps, null)(LabelsDropdown);
