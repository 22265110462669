import axios from 'axios';
import axiosFileupload from 'axios-fileupload';

import {
  getHeaders,
  getImageHeaders,
  getMultiPartHeaders,
  getHeadersWithParams,
  getFlatHeaders,
  getHeadersMultiPart,
  getHeadersWOSession,
  getHeadersZapier,
} from '../common/common-utils.js';
import {showLoading, hideLoading} from 'react-redux-loading-bar';
import {logout} from '../actions/index.js';
import {APP_API_KEY} from '@constant';
import {toast} from 'react-hot-toast';

export function getRequest(
  url,
  session,
  dispatch,
  successCallback,
  errorCallback,
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }
  _handleResponse(
    axios.get(url, getHeaders(session)),
    dispatch,
    session,
    successCallback,
    errorCallback,
  );
}

export function getCustomRequest(
  url,
  params,
  session,
  dispatch,
  successCallback,
  errorCallback,
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }
  _handleResponse(
    axios.get(url, getHeadersWithParams(session, params)),
    dispatch,
    session,
    successCallback,
    errorCallback,
  );
}

export function postMultipartRequest(
  url,
  postBody,
  session,
  dispatch,
  successCallback,
  errorCallback,
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }
  _handleResponse(
    axios.post(url, postBody, getMultiPartHeaders(session)),
    dispatch,
    session,
    successCallback,
    errorCallback,
  );
}

export function postRequest(
  url,
  postBody,
  session,
  dispatch,
  successCallback,
  errorCallback,
  signal = null,
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }

  // console.log('Signal => ', signal);

  if (signal) {
    _handleResponse(
      axios.post(url, postBody, {...getHeaders(session)}, signal),
      dispatch,
      session,
      successCallback,
      errorCallback,
    );
  } else {
    _handleResponse(
      axios.post(url, postBody, getHeaders(session)),
      dispatch,
      session,
      successCallback,
      errorCallback,
    );
  }
}

export function postRequestWithoutSession(
  url,
  postBody,
  session,
  dispatch,
  successCallback,
  errorCallback,
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }
  _handleResponse(
    axios.post(url, postBody, getHeadersWOSession(session)),
    dispatch,
    session,
    successCallback,
    errorCallback,
  );
}

export function postRequestZapier(
  url,
  postBody,
  session,
  dispatch,
  successCallback,
  errorCallback,
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }
  _handleResponse(
    axios.post(url, postBody, getHeadersZapier(session)),
    dispatch,
    session,
    successCallback,
    errorCallback,
  );
}

// WithOut Header
export function getRequestAccess(
  url,
  session = null,
  dispatch,
  successCallback,
  errorCallback,
) {
  var config = {
    headers: {
      'content-type': 'application/json',
      'X-ApiFlo-Api-Key': APP_API_KEY,
    },
  };

  _handleResponse(
    axios.get(url, config),
    dispatch,
    (session = null),
    successCallback,
    errorCallback,
  );
}

export function postRequestAccess(
  url,
  postBody,
  session = null,
  dispatch,
  successCallback,
  errorCallback,
) {
  var config = {
    headers: {
      'content-type': 'application/json',
      'X-ApiFlo-Api-Key': APP_API_KEY,
    },
  };

  _handleResponse(
    axios.post(url, postBody, config),
    dispatch,
    session,
    successCallback,
    errorCallback,
  );
}

export function patchRequest(
  url,
  patchBody,
  session,
  dispatch,
  successCallback,
  errorCallback,
) {
  dispatch(hideLoading());
  dispatch(showLoading());
  _handleResponse(
    axios.patch(url, patchBody, getHeaders(session)),
    dispatch,
    session,
    successCallback,
    errorCallback,
  );
}

export function deleteRequest(
  url,
  session,
  dispatch,
  successCallback,
  errorCallback,
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }
  _handleResponse(
    axios.delete(url, getHeaders(session)),
    dispatch,
    session,
    successCallback,
    errorCallback,
  );
}

export function deleteBulkRequest(
  url,
  session,
  deleteBody,
  dispatch,
  successCallback,
  errorCallback,
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }
  _handleResponse(
    axios.delete(url, {data: deleteBody, headers: getFlatHeaders(session)}),
    dispatch,
    session,
    successCallback,
    errorCallback,
  );
}

export function fileUploadRequest(
  url,
  fileData,
  dispatch,
  successCallback,
  errorCallback,
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }
  _handleResponse(
    axiosFileupload(url, fileData),
    dispatch,
    successCallback,
    errorCallback,
  );
}

export function uploadEncodedFile(
  url,
  session,
  filedata,
  dispatch,
  successCallback,
  errorCallback,
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }
  _handleFileResponse(
    axios.post(url, filedata, getImageHeaders(session)),
    dispatch,
    successCallback,
    errorCallback,
  );
}

export function uploadEncodedFileLarge(
  url,
  session,
  filedata,
  dispatch,
  successCallback,
  errorCallback,
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }
  _handleFileResponse(
    axios.post(url, filedata, getHeadersMultiPart(session)),
    dispatch,
    successCallback,
    errorCallback,
  );
}

export function deleteEncodedFile(
  url,
  session,
  dispatch,
  successCallback,
  errorCallback,
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }
  _handleFileResponse(
    axios.delete(url, getImageHeaders(session)),
    dispatch,
    successCallback,
    errorCallback,
  );
}

function _handleFileResponse(
  response,
  dispatch,
  successCallback,
  errorCallback,
) {
  response.then(
    response => {
      if (dispatch) dispatch(hideLoading());

      if (response.data.resource && response.data.resource)
        successCallback(response.data.resource[0]);
      else successCallback(response.data);
    },
    error => {
      if (dispatch) dispatch(hideLoading());
      errorCallback(error);
    },
  );
}

function _handleResponse(
  response,
  dispatch,
  session = null,
  successCallback,
  errorCallback,
) {
  response
    .then(({data}) => {
      if (dispatch) dispatch(hideLoading());
      successCallback(data);
    })
    .catch(error => {
      if (dispatch) dispatch(hideLoading());
      if (
        error.response &&
        (error.response.status === 401 ||
          error.response.status === 403 ||
          error.response.status === 404)
      ) {
        if (error.response.status === 404) {
          toast.error('You have been deleted by the admin');
        }
        if (dispatch) {
          if (session) dispatch(logout(session));
        }
      } else {
        if (error.response) errorCallback(error.response.data.error);
        else errorCallback(error);
      }

      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message); // Log cancellation
      }
    });
}

export function patchRequestWithoutSession(
  url,
  patchBody,
  session,
  dispatch,
  successCallback,
  errorCallback,
) {
  dispatch(hideLoading());
  dispatch(showLoading());
  _handleResponse(
    axios.patch(url, patchBody, getHeadersWOSession(session)),
    dispatch,
    session,
    successCallback,
    errorCallback,
  );
}
