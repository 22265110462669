export default function CrmLinks(props) {
  const {exported_to_zoho, exported_to_salesforce, exported_to_webhook} = props;
  //console.log('exported_to_zoho', exported_to_zoho);
  return (
    <div className="my-0.2">
      <div className="flex items-center gap-1">
        {exported_to_zoho ? (
          <span>
            <img
              src="../../images/zoho.svg"
              className="w-3 h-3 min-w-[12px] min-h-[12px]"
            />
          </span>
        ) : null}

        {exported_to_salesforce ? (
          <span>
            <img
              src="../../images/sf.svg"
              className="w-3 h-3 min-w-[12px] min-h-[12px]"
            />
          </span>
        ) : null}
        {exported_to_webhook ? (
          <span>
            <img
              src="../../images/webhook_icon.png"
              className="w-3 h-3 min-w-[12px] min-h-[12px]"
            />
          </span>
        ) : null}
      </div>
    </div>
  );
}
