import {
  getRequest,
  postRequest,
  deleteRequest,
  fileUploadRequest,
  patchRequest,
  deleteBulkRequest,
} from '@networkCall';

import {
  APP_API_KEY,
  INSTANCE_URL,
  itemCount,
  DEVELOPMENT_TYPE,
} from '@constant';

import LocaleStrings from '@language';

export var ALL_SUBSCRIPTION_PLANS_SETTINGS = 'ALL_SUBSCRIPTION_PLANS_SETTINGS';

export function fetchsubscriptionplans(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/thomsondata/_table/subscriptionplan?order=planid%20ASC`;
  return dispatch => {
    getRequest(
      url,
      session,
      dispatch,
      data => {
        dispatch({
          type: ALL_SUBSCRIPTION_PLANS_SETTINGS,
          payload: {data: data.resource},
        });
        callback({success: 1, data: data});
      },
      error => {},
    );
  };
}
