import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import toast, {Toaster} from 'react-hot-toast';
import {
  STRIPE_PUBLISHABLE_KEY,
  TRIALPLANID,
  ENTERPRISE_MONTHLY,
  ENTERPRISE_YEARLY,
} from '@constant';
import ReactConfirmAlert, {confirmAlert} from 'react-confirm-alert';
import {classNames} from '@commonFunction';
import LocaleStrings from '@language';
import {convertDateIntoLocal} from '@commonFunction';
import {
  upgradePlan,
  subscription,
  fetchPlanInvoice,
  updatePlan,
  updateUsersubscription,
} from '@upgradePlanActions';
import {fetchsessionData} from '@mainActions';
import {fetchAppuser} from '@sidebarActions';
import {loadStripe} from '@stripe/stripe-js';
import IconCheck from '../../settings/components/plans/icons/check';
import BtnLoaderSvg from '@btnLoaderSvg';
import {Combobox} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/solid';
import {logGTMEvent} from '@commonFolder/gtm-event';

let stripe = null;
loadStripe(STRIPE_PUBLISHABLE_KEY).then(val => (stripe = val));

class UpgradeNewPlanItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: false,
      selectMenuNum: 1,
      selectedThreadId: '',
      isBtnDisable: false,
      btnLoader: false,
      currentplanbtnDisable: true,
      currentplan: {},
      selectedPlan: null,
      query: '',
    };
    // All Binded Functions
  }

  componentWillReceiveProps(nextProps) {
    //console.log('nextProps', nextProps);
    if (nextProps) {
      if (nextProps.values && !nextProps.planSet) {
        let fobj = nextProps.values.allplans[0];
        let obj = {};
        obj.val = fobj.planid;
        obj.text = `${fobj.bus_email_credits} Emails | ${fobj.contactcredit} Phones`;
        this.setState({
          currentplan: nextProps.values,
          selectedPlan: obj,
        });
      }
    }
  }

  renderList() {
    let {values} = this.props;
    let {currentplan} = this.state;
    let feature_json =
      currentplan && currentplan.hasOwnProperty('new_features_json')
        ? JSON.parse(currentplan.new_features_json)
        : JSON.parse(values.new_features_json);
    const {planid} = currentplan;

    //Exclude API feature all plans except Enterprise
    if (planid !== ENTERPRISE_YEARLY && planid !== ENTERPRISE_MONTHLY) {
      feature_json = feature_json.filter(
        obj => obj.value !== 'apiaccessavailable',
      );
    }

    let item = '';
    if (feature_json.length > 0) {
      item = _.map(feature_json, (val, index) => {
        return (
          <ul role="list" className="mt-3 space-y-2 group relative">
            <li className="flex space-x-3 items-center ">
              <div className="w-4 h-4 pt-0.5 pr-0.5 bg-secondary rounded-full justify-center items-center flex">
                <div className="justify-center items-center flex mb-1">
                  <IconCheck />
                </div>
              </div>
              <div className="text-[11px] text-gray-500">{val.name}</div>
              {val.tooltip && val.tooltip != '' ? (
                <img
                  className="cursor-pointer w-4 h-4"
                  src="../../images/infoicon.png"></img>
              ) : (
                ''
              )}
            </li>
            {/* "tooltipapi":1 */}
            {val.tooltip && val.tooltip != '' ? (
              <div
                className={classNames(
                  val.tooltipapi && val.tooltipapi == '1'
                    ? '2xl:left-32 left-24'
                    : '2xl:right-10 xl:right-4',
                  'group-hover:opacity-100 transition-opacity   px-1  bg-primary text-white rounded absolute  -top-12 -translate-x-1/2 translate-y-full opacity-0 mx-auto  text-xs',
                )}>
                {val.tooltipapi && val.tooltipapi == '1' ? (
                  <> {val.tooltip}</>
                ) : (
                  <a
                    href="https://www.kipplo.com/terms-and-conditions/"
                    target="_blank">
                    {val.tooltip}
                  </a>
                )}
              </div>
            ) : (
              ''
            )}
          </ul>
        );
      });
    }
    return item;
  }

  _handleredirection = (type, exceededby) => {
    let {session, user} = this.props;
    let title = '';
    let description = '';
    let pushurl = '';
    if (type == 'api') {
      title = 'API Limit Exceeded';
      description = `Please delete ${exceededby} API, as your selected plan has less number of API access`;
      pushurl = '/settings/api';
    } else {
      title = 'Member Limit Exceeded';
      description = `Please delete ${exceededby} member/admin, as your selected plan has less number of member/admin access`;
      pushurl = '/settings/manageseats';
    }
    confirmAlert({
      title: title,
      message: description,
      buttons: [
        {
          label: `Delete ${type}`,
          onClick: () => {
            this.props.router.navigate(pushurl);
          },
        },
        {
          label: 'Cancel',
          onClick: () => {},
        },
      ],
    });
  };

  upgradePlan(e, values, subId) {
    let {user, session, memberadminListcount, apikeysListcount, appuserData} =
      this.props;

    let planState = 'initial';
    let currentSku = null;
    let usercurrentplanid = null;
    let autorenew = 1;
    let current_stripe_custid = null;
    let expiry_date = null;
    let clientid = null;
    let subscriptionid = null;
    let usercurrentvalidity = null;
    let usercurrentprice = null;
    // APPUSER DATA
    const otherPlan = ['custom_trial', 'custom_monthly', 'custom_yearly', null];
    if (
      appuserData &&
      appuserData.data &&
      appuserData.data.length > 0 &&
      appuserData.data[0].client_by_clientid
    ) {
      let client_by_clientid = appuserData.data[0]?.client_by_clientid;
      current_stripe_custid =
        client_by_clientid.stripe_custid != null ||
        client_by_clientid.stripe_custid != ''
          ? client_by_clientid.stripe_custid
          : null;
      clientid = client_by_clientid.clientid;
      if (
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid
          .length > 0
      ) {
        let usersubscription_by_clientid =
          appuserData.data[0]?.client_by_clientid
            ?.usersubscription_by_clientid[0];
        usercurrentplanid = usersubscription_by_clientid.planid;
        currentSku = usersubscription_by_clientid.sku;
        autorenew = usersubscription_by_clientid.autorenew;
        expiry_date = usersubscription_by_clientid.expirydate;
        subscriptionid = usersubscription_by_clientid.subscriptionid;
        usercurrentvalidity = usersubscription_by_clientid.validity;
        usercurrentprice = usersubscription_by_clientid.price;
      }
    }
    if (autorenew == 0 || autorenew == '0') {
      planState = 'initial';
    } else {
      if (!otherPlan.includes(values.sku) && !otherPlan.includes(currentSku)) {
        if (values.validity > usercurrentvalidity) {
          // Plan is better in terms of validity
          planState = 'up';
        } else if (values.validity < usercurrentvalidity) {
          // Plan is worse in terms of validity
          planState = 'down';
        } else {
          // If validity is the same, compare by price
          if (values.price > usercurrentprice) {
            planState = 'up';
          } else if (values.price < usercurrentprice) {
            planState = 'down';
          }
        }
      }
    }

    if (values.price > 0) {
      //This is for Stripe Subscription
      if (planState == 'initial') {
        if (autorenew == 0 || autorenew == '0') {
          let availableseats = values.availableseats;
          let availableapicall = values.availableapicall;

          let memberExccedby = 0;
          let apiExccedby = 0;

          let memberError = 0;
          let apiError = 0;

          let currentmemberListcount = '';
          let currentapilistcount = '';
          if (memberadminListcount.data) {
            currentmemberListcount = memberadminListcount.data;
          }
          if (apikeysListcount.data) {
            currentapilistcount = apikeysListcount.data;
          }

          if (parseInt(currentmemberListcount) > parseInt(availableseats)) {
            memberExccedby =
              parseInt(currentmemberListcount) - parseInt(availableseats);
            memberError = 1;
          }

          if (parseInt(currentapilistcount) > parseInt(availableapicall)) {
            apiExccedby =
              parseInt(currentapilistcount) - parseInt(availableapicall);
            apiError = 1;
          }

          if (memberError == 1) {
            this.setState({btnLoader: false});
            this.setState({isBtnDisable: false});
            this._handleredirection('member', memberExccedby);
          } else if (apiError == 1) {
            this.setState({btnLoader: false});
            this.setState({isBtnDisable: false});
            this._handleredirection('api', apiExccedby);
          } else {
            var data = {
              email: user.email,
              plan: values.planid,
              ...(current_stripe_custid ? {custId: current_stripe_custid} : {}),
            };
            this.setState({btnLoader: true});
            this.setState({isBtnDisable: true});

            // console.log("data", data);
            // debugger;
            this.props.subscription(session, data, response => {
              this.setState({btnLoader: false});
              this.setState({isBtnDisable: false});
              stripe.redirectToCheckout({sessionId: response.resource.data});
            });
          }
        } else {
          var data = {
            email: user.email,
            plan: values.planid,
            ...(current_stripe_custid ? {custId: current_stripe_custid} : {}),
          };
          // console.log("data1", data);
          // debugger;
          this.setState({btnLoader: true});
          this.setState({isBtnDisable: true});

          this.props.subscription(session, data, response => {
            this.setState({btnLoader: false});
            this.setState({isBtnDisable: false});
            stripe.redirectToCheckout({sessionId: response.resource.data});
          });
        }
      } else if (planState == 'up' && subId) {
        var data = `subId=${subId}&plan=${values.planid}&res=invoice`;
        this.setState({btnLoader: true});
        this.setState({isBtnDisable: true});

        let availableseats = values.availableseats;
        let availableapicall = values.availableapicall;

        let memberExccedby = 0;
        let apiExccedby = 0;

        let memberError = 0;
        let apiError = 0;

        let currentmemberListcount = '';
        let currentapilistcount = '';
        if (memberadminListcount.data) {
          currentmemberListcount = memberadminListcount.data;
        }
        if (apikeysListcount.data) {
          currentapilistcount = apikeysListcount.data;
        }

        if (parseInt(currentmemberListcount) > parseInt(availableseats)) {
          memberExccedby =
            parseInt(currentmemberListcount) - parseInt(availableseats);
          memberError = 1;
        }

        if (parseInt(currentapilistcount) > parseInt(availableapicall)) {
          apiExccedby =
            parseInt(currentapilistcount) - parseInt(availableapicall);
          apiError = 1;
        }

        if (memberError == 1) {
          this.setState({btnLoader: false});
          this.setState({isBtnDisable: false});
          this._handleredirection('member', memberExccedby);
        } else if (apiError == 1) {
          this.setState({btnLoader: false});
          this.setState({isBtnDisable: false});
          this._handleredirection('api', apiExccedby);
        } else {
          var data = {
            res: 'sub',
            plan: values.planid,
            subId: subId,
          };

          let sessionobj = {};
          sessionobj.session_token = session.sessionToken;

          this.props.updatePlan(session, data, feedback => {
            this.setState({btnLoader: false});
            this.setState({isBtnDisable: false});
            stripe.redirectToCheckout({sessionId: feedback.resource.data});
          });

          // this.props.fetchPlanInvoice(session, data, response => {
          //   if (
          //     response.success === 1 &&
          //     response.resource &&
          //     response.resource.status &&
          //     response.resource.status == true &&
          //     response.resource.code &&
          //     response.resource.code == 200 &&
          //     response.resource.data &&
          //     response.resource.data.subscription_proration_date
          //   ) {
          //     let proration_date =
          //       response.resource.data.subscription_proration_date;

          //     let subtotal = response.resource.data.subtotal;
          //     let total = response.resource.data.total;

          //     var data = {
          //       res: 'sub',
          //       plan: values.planid,
          //       subId: subId,
          //     };

          //     let sessionobj = {};
          //     sessionobj.session_token = session.sessionToken;

          //     this.props.updatePlan(session, data, feedback => {
          //       //console.log("feedback", feedback);
          //       // page load and show   toast.success("Plan updated successfully");

          //       this.props.fetchsessionData(sessionobj, '', response => {
          //         if (response.success === 0) {
          //           toast.error(LocaleStrings.common_fail_message);
          //         } else {
          //           this.props.fetchAppuser(
          //             session,
          //             user.appuserid,
          //             callback => {
          //               window.location.reload();
          //             },
          //           );
          //           this.setState({btnLoader: false});
          //           this.setState({isBtnDisable: false});
          //           toast.success(
          //             LocaleStrings.upgradeplan_upgraded_plansuccess,
          //           );

          //           logGTMEvent({
          //             event: 'platformSubscribed',
          //             method: 'upgrade',
          //             plan: values.name,
          //           });
          //         }
          //       });
          //     });
          //   } else if (response.success === 2) {
          //     var data = {
          //       email: user.email,
          //       plan: values.planid,
          //       ...(current_stripe_custid
          //         ? {custId: current_stripe_custid}
          //         : {}),
          //     };
          //     this.setState({btnLoader: true});
          //     this.setState({isBtnDisable: true});

          //     // console.log("data", data);
          //     // debugger;
          //     this.props.subscription(session, data, response => {
          //       this.setState({btnLoader: false});
          //       this.setState({isBtnDisable: false});
          //       stripe.redirectToCheckout({sessionId: response.resource.data});
          //     });
          //   } else {
          //     //console.log("response else", response);
          //     this.setState({btnLoader: false});
          //     this.setState({isBtnDisable: false});
          //   }
          // });
        }
      } else if (planState == 'down' && subId) {
        var data = `subId=${subId}&plan=${values.planid}&res=invoice&stdate=yes`;
        this.setState({btnLoader: true});
        this.setState({isBtnDisable: true});
        let availableseats = values.availableseats;
        let availableapicall = values.availableapicall;

        let memberExccedby = 0;
        let apiExccedby = 0;

        let memberError = 0;
        let apiError = 0;

        let currentmemberListcount = '';
        let currentapilistcount = '';
        if (memberadminListcount.data) {
          currentmemberListcount = memberadminListcount.data;
        }
        if (apikeysListcount.data) {
          currentapilistcount = apikeysListcount.data;
        }

        if (parseInt(currentmemberListcount) > parseInt(availableseats)) {
          memberExccedby =
            parseInt(currentmemberListcount) - parseInt(availableseats);
          memberError = 1;
        }

        if (parseInt(currentapilistcount) > parseInt(availableapicall)) {
          apiExccedby =
            parseInt(currentapilistcount) - parseInt(availableapicall);
          apiError = 1;
        }

        if (memberError == 1) {
          this.setState({btnLoader: false});
          this.setState({isBtnDisable: false});
          this._handleredirection('member', memberExccedby);
        } else if (apiError == 1) {
          this.setState({btnLoader: false});
          this.setState({isBtnDisable: false});
          this._handleredirection('api', apiExccedby);
        } else {
          this.props.fetchPlanInvoice(session, data, response => {
            if (
              response.resource &&
              response.resource.status &&
              response.resource.status == true &&
              response.resource.code &&
              response.resource.code == 200 &&
              response.resource.data &&
              response.resource.data.subscription_proration_date
            ) {
              let proration_date =
                response.resource.data.subscription_proration_date;

              let subtotal = response.resource.data.subtotal;
              let total = response.resource.data.total;

              if (expiry_date != null) {
                let updateobj = {
                  subscriptionid: subscriptionid,
                  clientid: clientid,
                  downgrade_startson: expiry_date,
                  type: 'downgrade',
                  downgrade_planid: values.planid,
                };
                // console.log('updateobj');
                // debugger;
                this.props.updateUsersubscription(
                  session,
                  updateobj,
                  callbackres => {
                    if (callbackres.success && callbackres.success == 1) {
                      var data = {
                        res: 'sub',
                        plan: values.planid,
                        subId: subId,
                        proration_date: proration_date,
                      };

                      this.props.updatePlan(session, data, feedback => {
                        toast.success(
                          LocaleStrings.upgradeplan_downgrade_success,
                        );
                        this.setState({btnLoader: false});
                        this.setState({isBtnDisable: false});

                        logGTMEvent({
                          event: 'platformSubscribed',
                          method: 'downgrade',
                          plan: values.name,
                        });
                      });
                    }
                  },
                );
              }
            } else {
              this.setState({btnLoader: false});
              this.setState({isBtnDisable: false});
            }
          });
        }
      }
    } else {
      if (values.sku == 'custom_yearly' || values.sku == 'custom_monthly') {
        // this is for enterprise plan
        this.props.history.push('/scheduledemo');
        // toast.error("Enterprise Plan is under development");
      } else {
        // This is for free subscription
        let obj = {};
        let sessionobj = {};
        sessionobj.session_token = session.sessionToken;
        obj.planid = values.planid;
        obj.clientid = user.clientid;
        obj.subscriptionid = user?.usersubscription_by_clientid[0]
          ?.subscriptionid
          ? user?.usersubscription_by_clientid[0]?.subscriptionid
          : null;

        this.setState({btnLoader: true});
        this.setState({isBtnDisable: true});
        this.props.upgradePlan(session, obj, callback => {
          if (callback.success == 1) {
            this.props.fetchAppuser(session, user.appuserid, callback => {});
            this.props.fetchsessionData(sessionobj, '', response => {
              if (response.success === 0) {
                toast.error(LocaleStrings.common_fail_message);
              } else {
                this.setState({btnLoader: false});
                this.setState({isBtnDisable: false});
                toast.success(LocaleStrings.upgradeplan_upgraded_success);
                logGTMEvent({
                  event: 'platformSubscribed',
                  method: 'upgrade',
                  plan: values.name,
                });
                window.location.reload();
              }
            });
          } else {
            toast.error(LocaleStrings.common_fail_message);
          }
        });
      }
    }
  }

  _handleaddonRedirect(e, values, subId, planState) {
    let {user, session} = this.props;
    this.props.router.navigate('/creditaddons');
  }

  setSelectedPlan = plan => {
    let {values} = this.props;
    this.setState({selectedPlan: plan});

    if (values.allplans && values.allplans.length > 0) {
      let planObj = _.filter(values.allplans, function (o) {
        return o.planid === plan.val;
      });
      //console.log('planObj', planObj);
      if (planObj.length > 0) {
        this.setState({
          currentplan: {
            ...planObj[0],
            allplans: values.allplans, // Adding allplans as a key
          },
        });
      }
    }
  };

  setQuery = event => {
    this.setState({query: event.target.value});
  };

  render() {
    let {
      user,
      values,
      appuserData,
      memberadminListcount,
      apikeysListcount,
      groupName,
      selectedTenure,
    } = this.props;
    let {currentplan, selectedPlan, query} = this.state;
    let usercurrentplanid = 0;
    let usercurrentvalidity = 0;
    let usercurrentprice = 0;
    // let buttonname = LocaleStrings.upgradeplan_item_btn_subscribenow;
    let buttonname = LocaleStrings.upgradeplan_item_btn_upgrade;
    let expirydate = '';
    let contactcredit = '';
    let subId = null;
    let currentSku = null;
    let autorenew = null;
    const otherPlan = ['custom_trial', 'custom_monthly', 'custom_yearly', null];
    if (
      appuserData &&
      appuserData.data &&
      appuserData.data.length > 0 &&
      appuserData.data[0].client_by_clientid
    ) {
      let client_by_clientid = appuserData.data[0]?.client_by_clientid;

      if (
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid
          .length > 0
      ) {
        let usersubscription_by_clientid =
          appuserData.data[0]?.client_by_clientid
            ?.usersubscription_by_clientid[0];
        expirydate = convertDateIntoLocal(
          usersubscription_by_clientid.expirydate,
        ).format('D MMM YYYY');
        usercurrentplanid = usersubscription_by_clientid.planid;
        subId = usersubscription_by_clientid.transactionid;
        currentSku = usersubscription_by_clientid.sku;
        autorenew = usersubscription_by_clientid.autorenew;
        usercurrentvalidity = usersubscription_by_clientid.validity;
        usercurrentprice = usersubscription_by_clientid.price;
      }

      if (
        appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid.length >
          0
      ) {
        let usercredit_by_clientid =
          appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid[0];
        contactcredit = usercredit_by_clientid.contactcredit;
      }
    }
    let currentDate = moment();
    let expired = false;
    if (expirydate !== '') {
      if (currentDate.isAfter(moment(expirydate))) {
        expired = true;
      }
    }
    let planState = 'initial';
    if (appuserData && appuserData.data && appuserData.data.length > 0) {
      if (expired != true) {
        if (currentplan.validity > usercurrentvalidity) {
          // Plan is better in terms of validity
          buttonname = LocaleStrings.upgradeplan_item_btn_upgrade;
        } else if (currentplan.validity < usercurrentvalidity) {
          // Plan is worse in terms of validity
          buttonname = LocaleStrings.upgradeplan_item_btn_downgrade;
        } else {
          // If validity is the same, compare by price
          if (currentplan.price > usercurrentprice) {
            buttonname = LocaleStrings.upgradeplan_item_btn_upgrade;
          } else if (values.price < usercurrentprice) {
            buttonname = LocaleStrings.upgradeplan_item_btn_downgrade;
          }
        }

        if (autorenew == 0 || autorenew == 0) {
          buttonname = 'Subscribe Now';
        }

        if (
          currentplan.sku == 'custom_yearly' ||
          currentplan.sku == 'custom_monthly'
        ) {
          buttonname = LocaleStrings.upgradeplan_item_btn_contactsales;
        }

        if (
          !otherPlan.includes(currentplan.sku) &&
          !otherPlan.includes(currentSku)
        ) {
          if (currentplan.validity > usercurrentvalidity) {
            // Plan is better in terms of validity
            planState = 'up';
          } else if (currentplan.validity < usercurrentvalidity) {
            // Plan is worse in terms of validity
            planState = 'down';
          } else {
            // If validity is the same, compare by price
            if (currentplan.price > usercurrentprice) {
              planState = 'up';
            } else if (values.price < usercurrentprice) {
              planState = 'down';
            }
          }
        }
      }
    }
    let groupwisePlans = [];
    if (currentplan.allplans && currentplan.allplans.length > 1) {
      _.forEach(currentplan.allplans, function (rows) {
        let obj = {};
        obj.val = rows.planid;
        obj.text = `${rows.bus_email_credits} Emails | ${rows.contactcredit} Phones`;
        groupwisePlans.push(obj);
      });
    }

    // console.log('usercurrentplanid', usercurrentplanid);
    // console.log('planid', currentplan.planid);
    return (
      <>
        <div
          className={classNames(
            usercurrentplanid == currentplan.planid ? 'bg-primaryHover' : '',
            'divide-y divide-gray-200 rounded-lg border border-gray-200 shadow-sm transition-all duration-500 transform hover:-translate-y-2 hover:scale-102',
          )}>
          <div className="p-6">
            <h2 className="text-base font-medium leading-6 text-gray-900">
              {_.startCase(groupName)}
            </h2>
            {currentplan.price == 0 ? (
              <div className="flex my-2">
                <div className="text-xl">
                  {/* {LocaleStrings.upgradeplan_item_btn_initialplan_price} */}
                  ${currentplan.price}
                </div>
              </div>
            ) : currentplan.price == -1 ? (
              <div className="flex my-2">
                <img
                  clasName=""
                  src="../../images/infinity.png"
                  style={{height: 10}}
                />
              </div>
            ) : (
              <div className="flex my-2">
                <div className="text-xl">${currentplan.price}</div>
                <div className="text-xs ml-1 mt-2 text-gray500">
                  {currentplan.validity == 30
                    ? LocaleStrings.upgradeplan_item_permonth
                    : LocaleStrings.upgradeplan_item_peryear}
                </div>
              </div>
            )}

            <div className="text-[11px] text-gray500">
              {currentplan.details}
            </div>

            {groupwisePlans.length > 0 ? (
              <div className="mt-4">
                <div className="text-xs  text-gray500 font-semibold mb-2">
                  Credits/{selectedTenure === 30 ? 'month' : 'year'} :{' '}
                </div>

                <div>
                  <Combobox
                    as="div"
                    value={selectedPlan}
                    onChange={plan => this.setSelectedPlan(plan)}>
                    <div className="relative">
                      <Combobox.Button className="cursor-pointer w-full">
                        <Combobox.Input
                          className="w-full rounded-lg border-0 bg-white py-2 text-xs pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-xs sm:leading-6 cursor-pointer"
                          onChange={this.setQuery}
                          displayValue={plan => (plan ? plan.text : '')}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-2 flex items-center rounded-r-md">
                          <ChevronDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </Combobox.Button>
                      </Combobox.Button>
                      <Combobox.Options
                        className={classNames(
                          'absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-xs scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent scrollbar-thumb-rounded-full shadow-md border border-gray-300',
                          groupwisePlans.length > 3 ? 'max-h-36' : 'max-h-28',
                        )}>
                        {groupwisePlans.map((plan, index) => (
                          <Combobox.Option
                            key={plan.planid}
                            value={plan}
                            className={({active}) =>
                              classNames(
                                'relative cursor-default select-none py-2 text-[9px] px-3',
                                active
                                  ? 'bg-gray-50 text-primary cursor-pointer'
                                  : 'text-gray-900',
                              )
                            }>
                            {({active, selected}) => (
                              <>
                                <span
                                  className={classNames(
                                    'block truncate text-xs',
                                    selected && 'font-semibold',
                                  )}>
                                  {plan.text}
                                </span>
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                      </Combobox.Options>
                    </div>
                  </Combobox>
                </div>
              </div>
            ) : (
              ''
            )}

            {usercurrentplanid == currentplan.planid && expired == false ? (
              <button
                type="button"
                className={classNames(
                  this.state.currentplanbtnDisable
                    ? 'cursor-not-allowed'
                    : 'cursor-pointer',
                  'btn-white mt-2',
                )}
                // className="btn-white mt-6"
              >
                {LocaleStrings.upgradeplan_item_btn_currentplan}
              </button>
            ) : !TRIALPLANID.includes(currentplan.planid) ? (
              <>
                {buttonname == 'Contact Sales' ? (
                  <button type="button" className="btn-primary mt-2">
                    {/* {} */}
                    <a href="https://calendly.com/app-kipplo" target="_blank">
                      {buttonname}
                    </a>
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={e => this.upgradePlan(e, currentplan, subId)}
                    disabled={this.state.isBtnDisable}
                    className={classNames(
                      this.state.isBtnDisable ? 'cursor-not-allowed' : '',
                      'btn-primary mt-2',
                    )}>
                    <BtnLoaderSvg loader={this.state.btnLoader} />
                    {/* {} */}
                    {buttonname}
                  </button>
                )}
              </>
            ) : (
              ''
            )}

            {/* {usercurrentplanid == values.planid &&
            usercurrentplanid != TRIALPLANID &&
            // contactcredit == 0 &&
            expired == false ? (
              <button
                type="button"
                className="btn-primary mt-6 cursor-pointer"
                onClick={e =>
                  this._handleaddonRedirect(e, values, subId, planState)
                }>
                {LocaleStrings.upgradeplan_item_btn_addon}
              </button>
            ) : (
              ''
            )} */}
          </div>

          <div className="px-6 pt-4 pb-2">
            <h3 className="text-xs font-medium text-gray-900">
              {LocaleStrings.upgradeplan_item_features}
            </h3>
            {this.renderList()}
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  var {session, user} = state;
  return {
    session,
    user,
    subscriptionplanList: state.subscriptionplanList,
    appuserData: state.appuserData,
    memberadminListcount: state.memberadminListcount,
    apikeysListcount: state.apikeysListcount,
  };
}
export default connect(mapStateToProps, {
  upgradePlan,
  fetchsessionData,
  fetchAppuser,
  subscription,
  fetchPlanInvoice,
  updatePlan,
  updateUsersubscription,
})(UpgradeNewPlanItem);
