import SubAddToList from './subaddtolist';
import {classNames} from '@commonFunction';

export default function AddToList(props) {
  return (
    <div
      className={classNames(
        'h-auto bg-white rounded-lg shadow-md border border-gray-100 flex-col justify-center items-center inline-flex z-50 addtolist',
      )}>
      <SubAddToList {...props} />
    </div>
  );
}
