import React from 'react';
import {connect} from 'react-redux';
import {Field, formValues, reduxForm, reset, getFormValues} from 'redux-form';
import LocaleStrings from '@language';
import _ from 'lodash';
import {classNames} from '@commonFunction';
import {Fragment} from 'react';
import {Transition, Dialog} from '@headlessui/react';
import {BaseComponent, LABEL_POSITION_TOP} from '@baseComponent';
import IconApi from '../../api/components/icons/api';
import {XIcon, PencilIcon, TrashIcon} from '@heroicons/react/outline';
import {
  webHookModal,
  addHookUrl,
  fetchCRMDetails,
  updateHookUrl,
  deleteHookUrl,
} from '../../../../search/actions';
import BtnLoaderSvg from '@btnLoaderSvg';
import ReactConfirmAlert, {confirmAlert} from 'react-confirm-alert';
class Addhookurl extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isBtnDisable: false,
      btnLoader: false,
      readonly: false,
      editclick: false,
    };
    this.closeModal = this.closeModal.bind(this);

    this.formRef = React.createRef(null);
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    //console.log('nextProps', nextProps);
    if (nextProps) {
      if (
        nextProps.crmDetails &&
        nextProps.crmDetails.zapier &&
        nextProps.crmDetails.hookid &&
        !this.state.readonly &&
        !this.state.editclick
      ) {
        this.setState({readonly: true});
      }
    }
  }

  _deleteHookurl() {
    let {session, crmDetails} = this.props;

    confirmAlert({
      title: LocaleStrings.settings_crm_webhook_delete_title,
      message: LocaleStrings.settings_crm_webhook_delete_desc,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.deleteHookUrl(session, crmDetails.hookid, resource => {
              if (resource.success !== 0) {
                this.props.fetchCRMDetails(session, response => {
                  this.setState({readonly: false, editclick: true});
                  this.props.showToast('delete', 'success');
                });
              } else {
                this.props.showToast('delete', 'error');
              }
            });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }

  editUrl() {
    this.setState({readonly: false, editclick: true});
  }

  closeModal() {
    this.props.webHookModal(false);
  }

  onFormSubmit(values) {}

  handleFormSubmit = values => {
    this.setState({isBtnDisable: true});
    this.setState({btnLoader: true});
    values.clientid = this.props.user.clientid;
    // console.log('initialValues', this.props.initialValues);
    // debugger;
    if (this.props.initialValues && this.props.initialValues.hookid) {
      var data = {
        id: this.props.initialValues.hookid,
        hookurl: values.hookurl,
      };
      this.props.updateHookUrl(this.props.session, data, callback => {
        //console.log('callback', callback);
        if (callback.success && callback.success == 1) {
          this.props.fetchCRMDetails(this.props.session, response => {
            this.setState({isBtnDisable: false});
            this.setState({btnLoader: false});
            this.setState({readonly: true, editclick: false});
            this.props.showToast('update', 'success');
            setTimeout(() => {
              this.closeModal();
            }, 1000);
          });
        }
      });
    } else {
      this.props.addHookUrl(this.props.session, values, callback => {
        //console.log('callback', callback);
        if (callback.success && callback.success == 1) {
          this.props.fetchCRMDetails(this.props.session, response => {
            this.setState({isBtnDisable: false});
            this.setState({btnLoader: false});
            this.setState({readonly: true, editclick: false});
            this.props.showToast('add', 'success');
            setTimeout(() => {
              this.closeModal();
            }, 1000);
          });
        }
      });
    }
  };

  render() {
    let {handleSubmit, initialValues, crmDetails} = this.props;
    return (
      <>
        <Transition.Root show={this.props.iswebHookModalOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.iswebHookModalOpen}
            onClose={this.closeModal}
            initialFocus={this.myRef}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block rounded-xl align-bottom  bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-2/5"
                  style={{padding: 10}}>
                  <div className="p-6 ">
                    <div className="flex items-center gap-4">
                      <div className="w-8 h-8 bg-secondary rounded-full justify-center items-center flex">
                        <div className="justify-center items-center flex">
                          <IconApi />
                        </div>
                      </div>
                      <div>
                        <div className="text-sm mainGray">
                          {LocaleStrings.settings_crm_webhook_title}
                        </div>
                        <div className="text-[11px] text-gray500 font-normal">
                          {LocaleStrings.settings_crm_webhook_subtitle}
                        </div>
                      </div>
                      {initialValues.hookid
                        ? ''
                        : // <div className="absolute top-0 right-0 pt-4 pr-4 ">
                          //   <button
                          //     type="button"
                          //     className="btn-closeModal"
                          //     onClick={this.closeModal}>
                          //     <span className="sr-only">
                          //       {LocaleStrings.close}
                          //     </span>
                          //     <XIcon className="h-6 w-6" aria-hidden="true" />
                          //   </button>
                          // </div>
                          ''}
                    </div>
                    {crmDetails.zapier ? (
                      <div className="mt-6 flex justify-between gap-4 items-center">
                        <div className="truncate text-ellipsis text-xs">
                          {crmDetails.hookurl}
                        </div>

                        <div className="flex gap-4">
                          <div
                            className="text-primary cursor-pointer"
                            onClick={() => this.editUrl()}>
                            <PencilIcon
                              className="h-4 w-4"
                              aria-hidden="true"
                            />
                          </div>
                          <div
                            className="text-red-600 cursor-pointer"
                            onClick={() => this._deleteHookurl()}>
                            <TrashIcon className="h-4 w-4" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className="mt-10">
                      <form onSubmit={handleSubmit(this.handleFormSubmit)}>
                        <div className="pb-1">
                          <Field
                            name="hookurl"
                            label={
                              LocaleStrings.settings_crm_webhookurl_label_keyname
                            }
                            placeholder={
                              LocaleStrings.settings_crm_webhookurl_label_keyname
                            }
                            type="text"
                            component={this.renderFieldText}
                            mandatory="true"
                            labelposition={LABEL_POSITION_TOP}
                            classNameLabel="text-xs font-normal text-gray700"
                            isdisabled={this.state.readonly}
                          />
                        </div>
                        {/* <div className="pb-6">
                          Go to{' '}
                          <a
                            href="https://zapier.com/"
                            target="_blank"
                            className="hover:bg-primaryHover text-primary">
                            {LocaleStrings.setting_crm_tab3}
                          </a>
                        </div> */}
                        <div className="flex mt-8">
                          <div className="w-2/3" />
                          <div className="flex justify-between gap-2">
                            <button
                              type="button"
                              onClick={this.closeModal}
                              className="justify-center px-4 py-2 text-xs border border-gray-200 rounded-lg hover:bg-gray-100 text-black hover:cursor-pointer">
                              {LocaleStrings.cancel}
                            </button>
                            {/* {initialValues.hookid ? (
                              <button
                                type="button"
                                onClick={this.closeModal}
                                className="w-full justify-center flex px-4 py-2 text-sm font-medium border border-gray-200 rounded-lg hover:bg-gray-100 text-black hover:cursor-pointer">
                                {LocaleStrings.delete}
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={this.closeModal}
                                className="w-full justify-center flex px-4 py-2 text-sm font-medium border border-gray-200 rounded-lg hover:bg-gray-100 text-black hover:cursor-pointer">
                                {LocaleStrings.cancel}
                              </button>
                            )} */}
                            {/* Footer */}
                            <button
                              type="submit"
                              disabled={this.state.isBtnDisable}
                              className={classNames(
                                this.state.isBtnDisable
                                  ? 'cursor-not-allowed'
                                  : '',
                                'btn-primary justify-center px-4 py-2 text-xs',
                              )}>
                              <BtnLoaderSvg loader={this.state.btnLoader} />
                              {initialValues.hookid
                                ? LocaleStrings.update
                                : LocaleStrings.save}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  var requiredFields = ['hookurl'];

  const urlPattern = new RegExp(
    '^(https?:\\/\\/)' + // protocol
      '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // domain name
      'localhost|' + // OR localhost
      '\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|' + // OR IPv4
      '\\[([a-fA-F\\d:]+)\\])' + // OR IPv6
      '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-zA-Z\\d_]*)?$', // fragment locator
    'i',
  );
  if (!values.hookurl || _.trim(values.hookurl) === '') {
    errors.hookurl = LocaleStrings.required;
  } else if (!urlPattern.test(values.hookurl)) {
    errors.hookurl = LocaleStrings.settings_crm_invalid_url;
  }
  return errors;
}

function mapStateToProps(state) {
  let initVals = {};
  if (state.crmDetails && state.crmDetails.zapier && state.crmDetails.hookid) {
    initVals.hookid = state.crmDetails.hookid;
  }
  if (state.crmDetails && state.crmDetails.zapier && state.crmDetails.hookurl) {
    initVals.hookurl = state.crmDetails.hookurl;
  }

  return {
    session: state.session,
    user: state.user,
    isLoggedIn: state.isLoggedIn,
    iswebHookModalOpen: state.iswebHookModalOpen,
    crmDetails: state.crmDetails,
    initialValues: initVals,
  };
}

export default connect(mapStateToProps, {
  webHookModal,
  addHookUrl,
  fetchCRMDetails,
  updateHookUrl,
  deleteHookUrl,
})(
  reduxForm({
    validate,
    form: 'hookurladd',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Addhookurl),
);
