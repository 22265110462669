import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import LocaleStrings from '@language';
import BaseLoader from '@baseLoader';
import {
  fetchSubscriptionplan,
  clearSubscriptionPlans,
} from '@upgradePlanActions';
import UpgradeNewPlanItem from './upgradeplan-item-new';
import SkeletonLoader from '../../../../common/skeletonloader';

class UpgradePricing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: false,
      loading: true,
      loaderDesc: LocaleStrings.preparing_subscription_plan,
      selectMenuNum: 1,
      selectedThreadId: '',
      selectedTenure: 30,
      planSet: false,
    };
    // All Binded Functions
  }

  componentDidMount() {
    this.props.fetchSubscriptionplan(this.props.session, callback => {
      this.setState({loading: false});
    });
  }

  componentWillUnmount() {
    this.props.clearSubscriptionPlans();
  }

  renderplanList() {
    let {appuserData} = this.props;

    let usercurrentplanid = null;
    if (
      appuserData &&
      appuserData.data &&
      appuserData.data.length > 0 &&
      appuserData.data[0].client_by_clientid
    ) {
      let client_by_clientid = appuserData.data[0]?.client_by_clientid;

      if (
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid
          .length > 0
      ) {
        let usersubscription_by_clientid =
          appuserData.data[0]?.client_by_clientid
            ?.usersubscription_by_clientid[0];
        usercurrentplanid = usersubscription_by_clientid.planid;
      }
    }

    //console.log('usercurrentplanid', usercurrentplanid);

    // var planList = _.filter(this.props.subscriptionplanList.data, obj => {
    //   return obj.validity === this.state.selectedTenure;
    // });

    const planList = _.filter(this.props.subscriptionplanList.data, obj => {
      if (this.state.selectedTenure === 30 && obj.sku === 'custom_yearly') {
        return true;
      }
      if (this.state.selectedTenure === 365 && obj.sku === 'custom_trial') {
        return true;
      }

      return obj.validity === this.state.selectedTenure;
    });

    const groupedPlans = planList.reduce((acc, plan) => {
      if (!acc[plan.plangroup]) {
        acc[plan.plangroup] = {allplans: []}; // Initialize with an allplans array
      }
      acc[plan.plangroup].allplans.push(plan); // Add each plan to the allplans array
      return acc;
    }, {});

    const finalGroupedPlans = Object.entries(groupedPlans)
      .sort(([, plansA], [, plansB]) => {
        const [firstPlanA] = plansA.allplans;
        const [firstPlanB] = plansB.allplans;

        // Move items with negative price to the end
        if (firstPlanA.price < 0 && firstPlanB.price >= 0) return 1;
        if (firstPlanB.price < 0 && firstPlanA.price >= 0) return -1;

        // For non-negative prices, sort by validity first
        if (firstPlanA.validity !== firstPlanB.validity) {
          return firstPlanA.validity - firstPlanB.validity;
        }

        // If validity is the same, sort by price
        return firstPlanA.price - firstPlanB.price;
      })
      .reduce((acc, [group, data]) => {
        //const [firstPlan, ...restPlans] = data.allplans;
        const firstPlan =
          data.allplans.find(plan => plan.planid === usercurrentplanid) ||
          data.allplans[0];

        console.log('firstPlan', firstPlan);
        acc[group] = {...firstPlan, allplans: data.allplans}; // Spread first plan's properties and add allplans array
        return acc;
      }, {});
    //console.log(finalGroupedPlans);

    const items = _.map(
      Object.entries(finalGroupedPlans),
      ([groupName, values], index) => (
        <UpgradeNewPlanItem
          key={index}
          groupName={groupName} // Pass the group name as a prop
          values={values} // Pass the group values (first plan and allplans array) as a prop
          router={this.props.router}
          planSet={this.state.planSet}
          selectedTenure={this.state.selectedTenure}
        />
      ),
    );

    return items;
  }

  changePlan(key) {
    this.setState({selectedTenure: key, planSet: false});
  }
  _handelback = () => {
    this.props.router.navigate('/dashboard');
  };

  render() {
    let {subscriptionplanList} = this.props;
    let {selectedTenure} = this.state;
    return (
      <>
        {subscriptionplanList && subscriptionplanList.data ? (
          <div>
            <div className="flex">
              <div className="cursor-pointer" onClick={e => this._handelback()}>
                <img
                  className="pt-1"
                  src="../../images/backicon.png"
                  width={10}
                />
              </div>
              <div
                className="pl-2 cursor-pointer text-xs text-gray500 font-medium"
                onClick={e => this._handelback()}>
                {LocaleStrings.settings_backtodashboard}
              </div>
            </div>
            <div className="text-lg font-semibold text-mainGray pt-2">
              {LocaleStrings.upgradeplan_header}
            </div>
            <div className="text-xs text-gray500 font-normal">
              {LocaleStrings.upgradeplan_desc}
            </div>
            {/* Commented for now */}

            <div className="py-4">
              <span className="isolate inline-flex rounded-lg bg-gray-100 p-0.5 w-80 sm:w-auto">
                <button
                  type="button"
                  className={`relative w-1/2 whitespace-nowrap rounded-md  py-2 text-xs font-medium text-gray-900 shadow-sm focus:z-10 focus:outline-none  sm:w-auto sm:px-8 ${
                    selectedTenure == 30 ? 'bg-white border-gray-200' : ''
                  }`}
                  onClick={() =>
                    selectedTenure == 30 ? null : this.changePlan(30)
                  }>
                  {LocaleStrings.upgradeplan_desc_tab_button1}
                </button>
                <button
                  type="button"
                  className={`relative w-1/2 whitespace-nowrap rounded-md  py-2 text-xs font-medium text-gray-900 shadow-sm focus:z-10 focus:outline-none   sm:w-auto sm:px-8 ${
                    selectedTenure == 365 ? 'bg-white border-gray-200' : ''
                  }`}
                  onClick={() =>
                    selectedTenure == 365 ? null : this.changePlan(365)
                  }>
                  {LocaleStrings.upgradeplan_desc_tab_button2}
                </button>
              </span>
            </div>

            {subscriptionplanList.data.length > 0 ? (
              <>
                <div className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
                  {this.renderplanList()}
                </div>
              </>
            ) : (
              <div className="center-item lg:my-48 text-lg text-ternary">
                {LocaleStrings.no_data_found}
              </div>
            )}
          </div>
        ) : (
          <SkeletonLoader />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var {session, user} = state;
  return {
    session,
    user,
    subscriptionplanList: state.subscriptionplanList,
    appuserData: state.appuserData,
  };
}
export default connect(mapStateToProps, {
  fetchSubscriptionplan,
  clearSubscriptionPlans,
})(UpgradePricing);
