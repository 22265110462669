import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {classNames} from '@commonFunction';
import _ from 'lodash';
import toast from 'react-hot-toast';
import IconFav from '../../../search/results/cells/icons/fav';
import SubCompany from '../../../search/results/cells//components/subcompany';
import SocialLinks from '../../../search/results/cells//components/sociallinks';
import CrmLinks from '../../../search/results/cells//components/crmlinks';

import IconFaved from '../../../search/results/cells/icons/faved';

import IconUpdate from '../../../search/results/cells/icons/update';
import {
  addPersonTolist,
  updateRevealedPeople,
  tooglePeopleFav,
  zapierHook,
  postEnrich,
  getEnrichedContact,
} from '../../../search/actions';
import LabelDropdownBlock from '../../../mylist/components/labeldropdownblock';
import {labelNotAssigned} from '../../../mylist/constants';

import LocaleStrings from '../../../../languages';
import Compositesubcontact from '../../../search/results/cells/components/compositesubcontact';
import Combinedicons from '../../../search/results/cells/components/combinedicons';
import {confirmAlert} from 'react-confirm-alert';

function ContactCardCell(props) {
  const {person, selected, onCheckUnCheck} = props;

  const [currentPerson, setCurrentPerson] = useState(person);
  const [processing, setProcessing] = useState(false);

  const {
    contactname,
    jobtitle,
    company,
    contactdetails,
    linkedin,
    facebook,
    twitter,
    isfav,
    new_company_data_available,
    new_designation_data_available,
    new_personal_email_data_available,
    new_business_email_data_available,
    new_phone_number_data_available,
    contacttag,
    exported_to_zoho,
    exported_to_salesforce,
    exported_to_webhook,
  } = currentPerson;

  useEffect(() => {
    if (contacttag !== person.contacttag) {
      setCurrentPerson(person);
    }
  }, [person]);

  useEffect(() => {
    if (isfav !== person.isfav) {
      setCurrentPerson(person);
    }
  }, [person]);

  const displayUpdateIcon =
    new_company_data_available ||
    new_designation_data_available ||
    new_personal_email_data_available ||
    new_business_email_data_available ||
    new_phone_number_data_available;

  const onEnrichClicked = item => {
    if (Object.keys(item).length > 0) {
      let totalCredits = 0;
      let fieldArr = [];
      let paramsArr = [];

      let obj = {};
      obj.contactid = item.contactid;
      obj.peopleid = item.peopleid;
      obj.update_available_business_email =
        item.new_business_email_data_available;
      obj.update_available_personal_email =
        item.new_personal_email_data_available;
      obj.update_available_phone_number = item.new_phone_number_data_available;
      obj.update_available_companyname = item.new_company_data_available;
      obj.update_available_designation = item.new_designation_data_available;
      paramsArr.push(obj);
      if (item.new_business_email_data_available == 1) {
        totalCredits++;
        if (!fieldArr.includes(LocaleStrings.enrich.work_email)) {
          fieldArr.push(LocaleStrings.enrich.work_email);
        }
      }
      if (item.new_personal_email_data_available == 1) {
        totalCredits++;
        if (!fieldArr.includes(LocaleStrings.enrich.secondary_email)) {
          fieldArr.push(LocaleStrings.enrich.secondary_email);
        }
      }
      if (item.new_phone_number_data_available == 1) {
        totalCredits++;
        if (!fieldArr.includes(LocaleStrings.enrich.phone_no)) {
          fieldArr.push(LocaleStrings.enrich.phone_no);
        }
      }
      if (
        item.new_company_data_available == 1 &&
        !fieldArr.includes(LocaleStrings.enrich.companyname)
      ) {
        fieldArr.push(LocaleStrings.enrich.companyname);
      }
      if (
        item.new_designation_data_available == 1 &&
        !fieldArr.includes(LocaleStrings.enrich.designation)
      ) {
        fieldArr.push(LocaleStrings.enrich.designation);
      }

      let paramData = {};
      paramData.contactid = item.contactid;
      paramData.peopleid = item.peopleid;

      confirmAlert({
        customUI: ({onClose}) => {
          return (
            <div className="react-confirm-alert-body">
              <h1>{LocaleStrings.enrich.title}</h1>
              <p>{LocaleStrings.enrich.subtitle}</p>
              <ul>
                {_.map(fieldArr, (item, _) => {
                  return <li>{item}</li>;
                })}
              </ul>
              <p>{`${LocaleStrings.enrich.subsubtitle} ${totalCredits} ${LocaleStrings.enrich.credits}`}</p>
              <div className="react-confirm-alert-button-group">
                <button
                  onClick={() => {
                    onClose();
                    setProcessing(true);
                    props.postEnrich(props.session, paramsArr, response => {
                      if (response.success === 1) {
                        props.getEnrichedContact(
                          props.session,
                          paramData,
                          callback => {
                            if (callback.success === 1 && callback.data) {
                              setProcessing(false);
                              setCurrentPerson(callback.data);
                              toast.success(LocaleStrings.enrich.success);
                            } else {
                              setProcessing(false);
                              toast.error(LocaleStrings.common_fail_message);
                            }
                          },
                        );
                      } else {
                        setProcessing(false);
                        toast.error(LocaleStrings.common_fail_message);
                      }
                    });
                  }}>
                  {LocaleStrings.enrich.enrich}
                </button>
                <button onClick={onClose}>{LocaleStrings.enrich.cancel}</button>
              </div>
            </div>
          );
        },
      });
    }
  };

  return processing ? (
    <tr key={contactname} className={selected ? 'bg-gray-50' : undefined}>
      <td colSpan={7} className="group py-4 px-7">
        <div class="animate-pulse flex">
          <div class="flex-1 space-y-3">
            <div class="h-2 bg-gray-100 rounded"></div>
            <div class="h-2 bg-gray-200 rounded w-10/12"></div>
            <div class="h-2 bg-gray-100 rounded"></div>
            <div class="h-2 bg-gray-200 rounded w-11/12"></div>
          </div>
        </div>
      </td>
    </tr>
  ) : (
    <tr key={contactname} className={selected ? 'bg-gray-50' : undefined}>
      <td className="group relative px-7 py-2">
        {selected && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-primary" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-500 text-primary focus:ring-primary group-hover:cursor-pointer"
          value={contactname}
          checked={selected}
          onChange={e => onCheckUnCheck(e.target.checked)}
        />
      </td>
      <td
        className={classNames(
          'group py-4 pr-3 hover:cursor-pointer relative px-2 xl:px-7 gap-2 flex flex-col',
        )}
        onClick={() => {
          onCheckUnCheck(!selected);
        }}>
        <div className="relative flex gap-2">
          <div
            className={classNames(
              'whitespace-nowrap text-xs font-medium w-fit truncate',
              selected ? 'text-primary' : 'text-gray-900',
            )}>
            {contactname}
          </div>
          <div>
            <SocialLinks
              linkedin={linkedin}
              twitter={twitter}
              facebook={facebook}
            />
          </div>
          <div className="">
            <CrmLinks
              exported_to_zoho={exported_to_zoho}
              exported_to_salesforce={exported_to_salesforce}
              exported_to_webhook={exported_to_webhook}
            />
          </div>

          {displayUpdateIcon ? (
            <div
              className="w-2 h-2 hover:cursor-pointer tooltip"
              onClick={e => onEnrichClicked(person)}>
              <IconUpdate />
              <div className="tooltiptext tooltip-right">
                <div className="text-xs">
                  {LocaleStrings.revealedContacts.updateavailable}
                </div>
                {new_company_data_available ? (
                  <div className="text-xs">
                    - {LocaleStrings.revealedContacts.company}
                  </div>
                ) : (
                  ''
                )}
                {new_designation_data_available ? (
                  <div className="text-xs">
                    - {LocaleStrings.revealedContacts.designation}
                  </div>
                ) : (
                  ''
                )}
                {new_personal_email_data_available ? (
                  <div className="text-xs">
                    - {LocaleStrings.revealedContacts.personalemail}
                  </div>
                ) : (
                  ''
                )}
                {new_business_email_data_available ? (
                  <div className="text-xs">
                    - {LocaleStrings.revealedContacts.businessemail}
                  </div>
                ) : (
                  ''
                )}
                {new_phone_number_data_available ? (
                  <div className="text-xs">
                    - {LocaleStrings.revealedContacts.cell}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : null}
        </div>
        <div className="relative flex">
          <div className="text-xs text-gray-500">{jobtitle}</div>
        </div>
        <div className="text-xs text-gray-500 truncate">{company.location}</div>
      </td>
      <td className="group whitespace-nowrap px-3 py-4 hover:bg-gray-50 hover:cursor-pointer relative">
        <div className="flex flex-col gap-2">
          {contactdetails
            .filter(
              contact =>
                contact.type === 'personalemail' ||
                contact.type === 'businessemail',
            )
            .map(contact => (
              <div key={`${contactname}-${contact.type}`}>
                <Compositesubcontact
                  {...props}
                  {...contact}
                  {...person}
                  contactname={contactname}
                  contacttype="email"
                  onUpgradePlan={props.onUpgradePlan}
                  onBuyAddon={props.onBuyAddon}
                  onClickFlag={props.onClickFlag}
                  onRevealCompletion={updatedPerson => {
                    const contactIndex = props.filteredPeople.data.findIndex(
                      person => person.peopleid === updatedPerson.peopleid,
                    );
                    props.filteredPeople.data[contactIndex] = updatedPerson;

                    props.updateRevealedPeople(props.filteredPeople);
                  }}
                />
              </div>
            ))}
          {contactdetails
            .filter(contact => contact.type === 'directdial')
            .map(contact => (
              <div key={`${contactname}-${contact.type}`}>
                <Compositesubcontact
                  {...props}
                  {...contact}
                  {...person}
                  contactname={contactname}
                  contacttype="phone"
                  onUpgradePlan={props.onUpgradePlan}
                  onBuyAddon={props.onBuyAddon}
                  onClickFlag={props.onClickFlag}
                  onRevealCompletion={updatedPerson => {
                    const contactIndex = props.filteredPeople.data.findIndex(
                      person => person.peopleid === updatedPerson.peopleid,
                    );
                    props.filteredPeople.data[contactIndex] = updatedPerson;

                    props.updateRevealedPeople(props.filteredPeople);
                  }}
                />
              </div>
            ))}
        </div>
      </td>
      <td className="group px-3 py-4 relative">
        <div className="group/company relative flex">
          <div className="text-sm text-primary">
            <SubCompany
              {...company}
              new_company_data_available={new_company_data_available}
              cardView
            />
          </div>
        </div>
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 justify-items-center">
        <div className="justify-start items-start gap-1 inline-flex relative">
          <LabelDropdownBlock
            tag={contacttag}
            labelNotAssigned={labelNotAssigned}
            onLabelSelection={label => {
              props.onLabelSelection(label);
            }}
          />
        </div>
      </td>

      <td className="whitespace-nowrap py-4 pl-3 pr-8">
        <div className="group relative flex gap-2 justify-end items-center">
          <Combinedicons {...props} />
          <div
            className="flex justify-center items-center"
            onClick={() => {
              const newpeople = {...props.person, isfav: !isfav};
              const index = props.filteredPeople.data.findIndex(
                t => t.peopleid === props.person.peopleid,
              );
              props.filteredPeople.data[index] = newpeople;
              props.updateRevealedPeople(props.filteredPeople);
            }}>
            {isfav ? <IconFaved /> : <IconFav />}
          </div>
        </div>
      </td>
    </tr>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  return {
    session,
    user,
    savedFilters,
    availableLists: state.availableLists,
    filteredPeople: state.filteredRevealedContacts,
    crmDetails: state.crmDetails,
  };
}

export default connect(mapStateToProps, {
  updateRevealedPeople,
  addPersonTolist,
  tooglePeopleFav,
  zapierHook,
  postEnrich,
  getEnrichedContact,
})(ContactCardCell);
