import React, {Fragment, useState} from 'react';
import TooltipPopover from '@/common/TooltipPopover';
import {PortalWithState} from 'react-portal';

import IconAddedToList from '../icons/addedtolist';
import IconSaveList from '../icons/save';
import AddToList from './addtolist';
import IconSavedToList from '../icons/savedtolist';
import IconSaveToList from '../icons/savetolist';

const height = window.innerHeight;

export default function AddToListBlock(props) {
  const [showInfo, setShowInfo] = useState(false); // toggles dropdown visibility
  const [coords, setCoords] = useState({}); // takes current button coordinates

  const btnRef = React.createRef();

  // const updateTooltipCoords = button => {
  //   const rect = btnRef.current.getBoundingClientRect();
  //   setCoords({
  //     left: rect.x + rect.width / 2 + 30, // add half the width of the button for centering
  //     top: rect.y + window.scrollY, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
  //   });
  // };

  const updateTooltipCoords = _ => {
    const rect = btnRef.current.getBoundingClientRect();

    //Detemining top position of last popup in the tableview
    const top = height - 170 < rect.y + 5 ? rect.y - 162 : rect.y;

    setCoords({
      left: rect.x + rect.width / 2 + 40, // add half the width of the button for centering
      top, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
    });
  };

  const {revealed, addedtolist, listid, person} = props;

  const renderAddToListIcon = () => {
    if (props.integrated) {
      if (addedtolist) {
        return <IconSavedToList />;
      } else {
        return <IconSaveToList />;
      }
    } else if (revealed) {
      if (addedtolist) {
        return <IconAddedToList />;
      } else {
        return <IconSaveList />;
      }
    }
  };

  return (
    <div className="p-1.5 px-2 cursor-pointer">
      <PortalWithState closeOnOutsideClick closeOnEsc>
        {({openPortal, closePortal, isOpen, portal}) => (
          <Fragment>
            <div
              className="cursor-pointer"
              ref={btnRef}
              onClick={() => {
                props.onOpenCreateList(person);
              }}
              // onMouseEnter={e => {
              //   updateTooltipCoords(e.target);
              //   if (revealed && props.availableLists.length > 0) {
              //     openPortal(e);
              //   }
              // }}
              // onMouseLeave={e => {
              //   updateTooltipCoords(e.target);
              //   if (revealed && props.availableLists.length > 0) {
              //     closePortal();
              //   }
              // }}
              // onClick={e => {
              //   closePortal(e);
              //   const existing = document.querySelectorAll('.addtolist');
              //   existing.forEach(item => {
              //     if (item) {
              //       const parent = item.parentElement; // Get the parent element
              //       if (parent && parent.contains(item)) {
              //         // Ensure the parent contains the child
              //         parent.style.display = 'none';
              //       } else {
              //         console.warn(
              //           'Parent does not contain the expected child:',
              //           item,
              //         );
              //       }
              //     }
              //   });

              //   updateTooltipCoords(e.target);
              //   setTimeout(() => {
              //     openPortal(e);
              //   }, 50);
              // }}
              // onMouseLeave={e => {
              //   updateTooltipCoords(e.target);
              //   closePortal();
              // }}
            >
              <div>{renderAddToListIcon()}</div>

              {portal(
                <TooltipPopover
                  width={200}
                  theClassName="addtolist"
                  coords={coords}
                  direction={'left'}
                  updateTooltipCoords={() =>
                    updateTooltipCoords(btnRef.current.buttonNode)
                  }>
                  {props.availableLists.length > 0 && revealed ? (
                    <AddToList
                      addedListId={listid}
                      onCreateNewList={() => {
                        closePortal();
                        props.onOpenCreateList(person);
                      }}
                      onChangeList={list => {
                        props.onChangeList(list);
                        closePortal();
                      }}
                    />
                  ) : null}
                </TooltipPopover>,
              )}
            </div>
          </Fragment>
        )}
      </PortalWithState>
    </div>
  );
}
