import React, {useState} from 'react';
import {Field, FieldArray} from 'redux-form';
import {XIcon} from '@heroicons/react/outline';
// Component to render each dropdown pair
const DropdownPair = ({
  fields,
  kipploOptions,
  zohoOptions,
  selectedKipplo,
  setSelectedKipplo,
  selectedZoho,
  setSelectedZoho,
  modalstate,
  selectedModule,
}) => {
  // Handle removal of a field
  const handleRemoveField = index => {
    fields.remove(index);

    // Remove corresponding state entries
    setSelectedKipplo(prev => prev.filter((_, i) => i !== index));
    setSelectedZoho(prev => prev.filter((_, i) => i !== index));
  };
  const limit = modalstate == 'salesforce' ? 1 : 0;

  // console.log('kipploOptions', kipploOptions);
  // console.log('zohoOptions', zohoOptions);
  return (
    <div>
      {fields.map((field, index) => (
        <div className="">
          <div key={index} className="flex gap-6 items-center mb-2.5 ">
            <div className="w-1/2">
              <Field
                name={`${field}.kipplo`}
                component="select"
                className="mt-1
                          block
                          w-full
                          pl-3
                          pr-10
                          py-2
                          border
                          border-gray-300
                          text-xs
                          rounded-md"
                onChange={event => {
                  const newValue = event.target.value;
                  setSelectedKipplo(prev => {
                    const newSelected = [...prev];
                    newSelected[index] = newValue;
                    return newSelected;
                  });
                }}>
                <option value="" disabled>
                  Select Kipplo
                </option>
                {kipploOptions
                  .filter(
                    option =>
                      !selectedKipplo.includes(option.value) ||
                      selectedKipplo[index] === option.value,
                  )
                  .map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
              </Field>
            </div>
            <div className="w-1/2 flex items-center">
              <Field
                name={`${field}.zoho`}
                component="select"
                disabled={
                  modalstate == 'salesforce' &&
                  selectedModule == 'leads' &&
                  index == 0
                    ? true
                    : false
                }
                className="mt-1
                          block
                          w-full
                          pl-3
                          pr-10
                          py-2
                          border
                          border-gray-300
                          text-xs
                          rounded-md"
                onChange={event => {
                  const newValue = event.target.value;
                  setSelectedZoho(prev => {
                    const newSelected = [...prev];
                    newSelected[index] = newValue;
                    return newSelected;
                  });
                }}>
                <option value="" disabled>
                  Select {modalstate == 'zoho' ? 'Zoho' : 'Salesforce'}
                </option>
                {zohoOptions
                  .filter(
                    option =>
                      !selectedZoho.includes(option.value) ||
                      selectedZoho[index] === option.value,
                  )
                  .map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
              </Field>
              {index > limit ? (
                <div className="ml-2">
                  <XIcon
                    className="h-4 w-4 cursor-pointer text-red-600"
                    aria-hidden="true"
                    onClick={() => handleRemoveField(index)}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DropdownPair;
