export default function IconPlan() {
  return (
    <div className="flex items-center justify-center">
      <svg
        width="16"
        viewBox="0 0 22 18"
        xmlns="http://www.w3.org/2000/svg"
        className="fill-none stroke-2 stroke-primary">
        <path
          d="M1 11.5L11 16.5L21 11.5M11 1.5L1 6.5L11 11.5L21 6.5L11 1.5Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
