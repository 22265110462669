import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {fetchCSVList, toggleCSVSelectionPopup} from '../actions';
import SkeletonLoader from '../../../../common/skeletonloader';
import LocaleStrings from '../../../languages';
import IconSearch from '../../mylist/icons/search';
import DialogEntry from './components/dialogentry';
import IconCSV from './icons/csv';
import SelectionPopup from './components/selectionpopup';
import Uploadscell from './components/uploadscell';

import {PlusIcon} from '@heroicons/react/solid';
import PreSelectionPopup from './components/preselectionpopup';
import PaginationBar from '../../search/results/cells/components/paginationbar';
import {BASIC_MONTHLY, TRIALPLANID} from '../../../../common/constants';
import {XIcon} from '@heroicons/react/outline';
import {useNavigate} from 'react-router-dom';

const maxRowPerPage = 25;

function CSVEnrich(props) {
  const [fetching, setFetching] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [toggleOpenPreSelectionPopup, setToggleOpenPreSelectionPopup] =
    useState(false);
  const [curPage, setCurPage] = useState(1);
  const [paginating, setPaginating] = useState(false);

  const heightDiff = 300 * (window.innerHeight / 754);

  const navigate = useNavigate();

  const _openUpgradPlan = () => {
    props.router.navigate('/upgradeplan');
  };

  useEffect(() => {
    if (fetching) {
      props.fetchCSVList(props.session, {page: curPage}, _ => {
        setSearchText('');
        setFetching(false);
      });
    }
  }, [fetching]);

  useEffect(() => {
    if (!fetching) {
      setPaginating(true);
      props.fetchCSVList(props.session, {page: curPage}, _ => {
        setPaginating(false);
      });
    }
  }, [curPage]);

  useEffect(() => {
    if (!fetching && searchText.length > 0) {
      props.fetchCSVList(
        props.session,
        {page: curPage, searchtext: searchText},
        _ => {
          setFetching(false);
        },
      );
    } else if (!fetching && searchText.length === 0) {
      props.fetchCSVList(props.session, {page: curPage}, _ => {
        setFetching(false);
      });
    }
  }, [searchText]);

  useEffect(() => {
    if (toggleOpenPreSelectionPopup && props.toggleCSVSelectionPopup.file) {
      //If we have file need to close PreSelection
      setToggleOpenPreSelectionPopup(false);
    }
  }, [props.toggleCSVSelectionPopup]);

  useEffect(() => {
    if (!props.toggleCSVSelectionPopup.open) {
      setPaginating(true);
      props.fetchCSVList(props.session, {page: 1}, _ => {
        setPaginating(false);
      });
    }
  }, [props.toggleCSVSelectionPopup]);

  const planid = props.appuserData.credits && props.appuserData.credits.planid;

  const hasAllCredits = props.credits
    ? props.credits.contactcredits > 0 &&
      props.credits.personal_emailcredits > 0 &&
      props.credits.business_emailcredits
    : false;

  return (
    <>
      {fetching || !props.appuserData.credits ? (
        <SkeletonLoader small />
      ) : (
        <div className="h-full">
          {props.appuserData &&
          props.appuserData.settings &&
          (props.appuserData.settings.csv_max_upload_rows == 0 ||
            props.appuserData.settings.csv_max_upload_rows == null) ? (
            <div className="flex flex-col justify-center items-center h-full gap-4">
              <div className="text-lg text-ternary">
                {LocaleStrings.enrichment.unlock}
              </div>
              <button
                type="submit"
                style={{width: 120}}
                onClick={_openUpgradPlan}
                className="btn-primary ml-4 cursor-pointer">
                {LocaleStrings.upgrade_now}
              </button>
            </div>
          ) : props.csvEnrichList.length > 0 || searchText !== '' ? (
            <div>
              <div className="text-base font-semibold mb-4">
                {LocaleStrings.enrichment.title}
              </div>
              <div className="justify-between items-center gap-3 flex">
                <div className="w-96 flex-col justify-start items-start gap-2 inline-flex relative">
                  <div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex">
                    <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center inline-flex">
                      <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                        <div className="w-5 h-5 relative">
                          <IconSearch />
                        </div>
                        <input
                          type="search"
                          autoComplete="off"
                          className="cust-transparent-input-field bg-transparent w-full no-clear"
                          placeholder={LocaleStrings.searchPlaceolder}
                          value={searchText}
                          onClick={() => {
                            // setToggled(!toggled);
                          }}
                          onChange={e => {
                            setSearchText(e.target.value);
                          }}
                        />
                        {searchText !== '' && (
                          <div
                            className="absolute top-2.4 right-2 z-99 content-center p-1 bg-primaryHover cursor-pointer"
                            style={{borderRadius: 40, height: 20, width: 20}}
                            onClick={() => {
                              setFetching(true);
                            }}>
                            <XIcon
                              className="h-3 w-3 text-primary"
                              aria-hidden="true"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="justify-end">
                  {hasAllCredits ? (
                    <button
                      className="btn-primary"
                      style={{width: '130px'}}
                      onClick={() => {
                        setToggleOpenPreSelectionPopup(true);
                      }}>
                      <div className="flex gap-2">
                        <PlusIcon style={{width: 14}} />
                        {LocaleStrings.enrichment.selectCSV}
                      </div>
                    </button>
                  ) : (
                    <div
                      className="btn-primary"
                      onClick={() => {
                        navigate('/upgradeplan');
                      }}>
                      {LocaleStrings.upgrade}
                    </div>
                  )}
                </div>
              </div>
              <div className="shadow-md rounded-lg mt-4 pb-2">
                <div className="overflow-x-auto overflow-visible">
                  <div
                    className="inline-block overflow-scroll-y"
                    style={{
                      height: `calc(100vh / var(--zoom) - ${heightDiff}px)`,
                    }}>
                    <table className="w-full table-fixed divide-y divide-gray-300">
                      <thead>
                        <tr className="bg-gray-50">
                          <th
                            scope="col"
                            className="sticky top-0 w-1/6 py-3.5 pr-3 text-left text-xs font-normal text-gray-700 relative px-7 bg-gray-50 z-50">
                            {LocaleStrings.enrichment.tableHeader.name}
                          </th>
                          <th
                            scope="col"
                            className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
                            {LocaleStrings.enrichment.tableHeader.type}
                          </th>
                          {/* <th
                              scope="col"
                              className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
                              {LocaleStrings.enrichment.tableHeader.progress}
                            </th> */}
                          <th
                            scope="col"
                            className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
                            {LocaleStrings.enrichment.tableHeader.status}
                          </th>
                          <th
                            scope="col"
                            className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
                            {LocaleStrings.enrichment.tableHeader.totalRecords}
                          </th>
                          <th
                            scope="col"
                            className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
                            {
                              LocaleStrings.enrichment.tableHeader
                                .matchedRecords
                            }
                          </th>
                          <th
                            scope="col"
                            className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
                            {LocaleStrings.enrichment.tableHeader.duplicate}
                          </th>
                          <th
                            scope="col"
                            className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
                            {LocaleStrings.enrichment.tableHeader.generatedon}
                          </th>
                          <th
                            scope="col"
                            className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
                            {LocaleStrings.enrichment.tableHeader.actions}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {paginating ? (
                          <tr>
                            <td colSpan={8}>
                              <SkeletonLoader tableembeded />
                            </td>
                          </tr>
                        ) : (
                          props.csvEnrichList.map(list => (
                            <Uploadscell {...list} />
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="min-h-[60px] max-h-[60px] bg-white px-4">
                  <PaginationBar
                    curPage={curPage}
                    numPages={props.numPages}
                    onPageChange={curPage => {
                      setCurPage(curPage);
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex justify-center content-center">
              <DialogEntry />
            </div>
          )}
          <PreSelectionPopup
            open={toggleOpenPreSelectionPopup}
            onCloseModal={() => {
              setToggleOpenPreSelectionPopup(false);
            }}
          />
        </div>
      )}
      {props.appuserData.credits && (
        <SelectionPopup {...props} {...props.toggleCSVSelectionPopup} />
      )}
    </>
  );
}

const mapStateToProps = state => {
  const {totalcount, pages, data} = state.csvEnrichList;
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    appuserData: state.appuserData,
    csvEnrichList: data ? data : [],
    totalcount,
    numPages: pages,
    toggleCSVSelectionPopup: state.csvSelectionPopupData,
    credits: state.appuserData.credits,
  };
};

export default connect(mapStateToProps, {fetchCSVList})(CSVEnrich);
