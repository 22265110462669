import React from 'react';
import {connect} from 'react-redux';
import {Field, formValues, reduxForm, reset, getFormValues} from 'redux-form';
import LocaleStrings from '@language';
import _ from 'lodash';
import toast, {Toaster} from 'react-hot-toast';
import {classNames} from '@commonFunction';
import {Fragment} from 'react';
import {Transition, Dialog} from '@headlessui/react';
import {BaseComponent, LABEL_POSITION_TOP} from '@baseComponent';
import {
  mapFieldsModal,
  updateMappedFields,
  getallZohoFields,
  getallSFFields,
  crmDisconnect,
  fetchCRMDetails,
  crmModuleUpdate,
} from '../../../../search/actions';
import MappingForm from './MappingForm';
import IconApi from '../../api/components/icons/api';
import IconArrowLeft from '../../../../search/results/cells/icons/arrowleft';
import {ArrowLeftIcon} from '@heroicons/react/solid';

const defaultMappings = [
  {kipplo: '', zoho: ''},
  {kipplo: '', zoho: ''},
];

const modulesArr = [
  {value: 'Leads', text: 'Leads'},
  {value: 'Accounts', text: 'Accounts'},
  {value: 'Contacts', text: 'Contacts'},
];

class Mapfields extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      selectedTab: 'settings',
      selectedModule: 'leads',
      zohoDisconnectionLoading: false,
      salesForceDisconnectionLoading: false,
    };
    this.closeModal = this.closeModal.bind(this);

    this.formRef = React.createRef(null);
  }

  componentDidMount() {
    // this.props.getallZohoFields(this.props.session, res => {});
    // this.props.getallSFFields(this.props.session, res => {});
  }

  closeModal() {
    this.setState({selectedTab: 'settings', changeModule: 'leads'});
    this.props.mapFieldsModal(false);
  }

  onFormSubmit(values) {
    //console.log('values', values);
    let mapData = {};
    if (this.props.modalstate == 'zoho') {
      if (
        values.hasOwnProperty('kfirstname') &&
        values.hasOwnProperty('zfirstname')
      ) {
        mapData[values.zfirstname] = values.kfirstname;
      }
      if (
        values.hasOwnProperty('klastname') &&
        values.hasOwnProperty('zlastname')
      ) {
        mapData[values.zlastname] = values.klastname;
      }
      if (values.hasOwnProperty('kemail') && values.hasOwnProperty('zemail')) {
        mapData[values.zemail] = values.kemail;
      }
      if (
        values.hasOwnProperty('kcompany') &&
        values.hasOwnProperty('zcompany')
      ) {
        mapData[values.zcompany] = values.kcompany;
      }
      if (
        values.hasOwnProperty('kcountry') &&
        values.hasOwnProperty('zcountry')
      ) {
        mapData[values.zcountry] = values.kcountry;
      }
      if (values.hasOwnProperty('kphone') && values.hasOwnProperty('zphone')) {
        mapData[values.zphone] = values.kphone;
      }
      if (
        values.hasOwnProperty('kindustry') &&
        values.hasOwnProperty('zindustry')
      ) {
        mapData[values.zindustry] = values.kindustry;
      }
      if (Object.keys(mapData).length > 0) {
        let data = {};
        data.crmtype = 'zoho';
        data.mapped_data = JSON.stringify(mapData);
        this.setState({loading: true});
        this.props.updateMappedFields(this.props.session, data, res => {
          this.setState({loading: false});
          this.closeModal();
          if (res.success == 1) {
            toast.success('Updated Successfully');
          } else {
            toast.error(LocaleStrings.something_went_wrong);
          }
        });
      }
    } else if (this.props.modalstate == 'salesforce') {
      if (
        values.hasOwnProperty('kfirstname') &&
        values.hasOwnProperty('sfirstname')
      ) {
        mapData[values.sfirstname] = values.kfirstname;
      }
      if (
        values.hasOwnProperty('klastname') &&
        values.hasOwnProperty('slastname')
      ) {
        mapData[values.slastname] = values.klastname;
      }
      if (values.hasOwnProperty('kemail') && values.hasOwnProperty('semail')) {
        mapData[values.semail] = values.kemail;
      }
      if (
        values.hasOwnProperty('kcompany') &&
        values.hasOwnProperty('scompany')
      ) {
        mapData[values.scompany] = values.kcompany;
      }
      if (
        values.hasOwnProperty('kcountry') &&
        values.hasOwnProperty('scountry')
      ) {
        mapData[values.scountry] = values.kcountry;
      }
      if (values.hasOwnProperty('kphone') && values.hasOwnProperty('sphone')) {
        mapData[values.sphone] = values.kphone;
      }
      if (
        values.hasOwnProperty('kindustry') &&
        values.hasOwnProperty('sindustry')
      ) {
        mapData[values.sindustry] = values.kindustry;
      }
      if (Object.keys(mapData).length > 0) {
        let data = {};
        data.crmtype = 'salesforce';
        data.mapped_data = JSON.stringify(mapData);

        // console.log('data', data);
        // debugger;
        this.setState({loading: true});
        this.props.updateMappedFields(this.props.session, data, res => {
          this.setState({loading: false});
          this.closeModal();
          if (res.success == 1) {
            toast.success('Updated Successfully');
          } else {
            toast.error(LocaleStrings.something_went_wrong);
          }
        });
      }
    }
  }

  handleFormSubmit = values => {
    const {mappings} = values;
    const jsonResult = mappings.reduce((acc, {kipplo, zoho}) => {
      if (kipplo && zoho) {
        acc[zoho] = kipplo;
      }
      return acc;
    }, {});

    //console.log('Generated JSON:', jsonResult);
    alert(JSON.stringify(jsonResult, null, 2)); // Display the JSON in an alert
  };

  changeTab(key, moduleType = 'leads') {
    if (key == 'fieldmapping') {
      let {crmDetails, modalstate} = this.props;
      console.log('crmDetails', crmDetails);
      let moduleTypesArr = [];
      if (modalstate == 'zoho' && crmDetails && crmDetails.zoho_moduletype) {
        moduleTypesArr = crmDetails.zoho_moduletype.split(',');
      } else if (
        modalstate == 'salesforce' &&
        crmDetails &&
        crmDetails.salesforce_moduletype
      ) {
        moduleTypesArr = crmDetails.salesforce_moduletype.split(',');
      }
      console.log('moduleTypesArr changeTab', moduleTypesArr);

      if (moduleTypesArr.length > 0) {
        //moduleTypesArr.forEach(moduleType => {
        if (modalstate == 'zoho') {
          let values = {module: _.startCase(moduleType)};
          this.props.getallZohoFields(this.props.session, values, res => {
            //console.log(`Response for ${moduleType}:`, res);
          });
        }

        if (modalstate == 'salesforce') {
          let values = {module: _.startCase(moduleType).slice(0, -1)};
          this.props.getallSFFields(this.props.session, values, res => {
            //console.log(`Response for ${moduleType}:`, res);
          });
        }
        //});
      }
      this.setState({selectedModule: moduleType.toLowerCase()});
    }
    this.setState({selectedTab: key});
  }

  changeModule(key) {
    this.setState({selectedModule: key.toLowerCase()});
  }

  disConnect = crmtype => {
    if (crmtype == 'zoho') {
      this.setState({zohoDisconnectionLoading: true});
    } else if (crmtype == 'salesforce') {
      this.setState({salesForceDisconnectionLoading: true});
    } else {
      this.setState({zapierLoading: true});
    }
    this.props.crmDisconnect(this.props.session, crmtype, res => {
      if (res.success == 1) {
        this.props.fetchCRMDetails(this.props.session, resp => {
          if (crmtype == 'zoho') {
            this.setState({zohoDisconnectionLoading: false});
          } else if (crmtype == 'salesforce') {
            this.setState({salesForceDisconnectionLoading: true});
          } else {
            this.setState({zapierLoading: false});
          }

          this.closeModal();
        });
      } else {
        if (crmtype == 'zoho') {
          this.setState({zohoDisconnectionLoading: false});
        } else if (crmtype == 'salesforce') {
          this.setState({salesForceDisconnectionLoading: true});
        } else {
          this.setState({zapierLoading: false});
        }

        this.closeModal();
      }
    });
  };

  handleModuleToggle = (e, name) => {
    let {crmDetails, modalstate} = this.props;
    // console.log('e', e);
    // console.log('name', name);
    // console.log('crmDetails', crmDetails);
    let checked = e.target.checked;
    let isProceed =
      (modalstate == 'zoho' &&
        crmDetails.zoho &&
        crmDetails.zoho_moduletype &&
        !checked) ||
      (modalstate == 'salesforce' &&
        crmDetails.salesforce &&
        crmDetails.salesforce_moduletype &&
        !checked) ||
      checked;

    if (isProceed) {
      let values = {};
      values.crmtype = modalstate;
      values.module = name;

      this.props.crmModuleUpdate(this.props.session, values, res => {
        this.props.fetchCRMDetails(this.props.session, resp => {
          if (
            resp.success &&
            modalstate == 'zoho' &&
            resp.data &&
            resp.data.zoho_moduletype
          ) {
            let moduleTypesArr = resp.data.zoho_moduletype.split(',');
            if (moduleTypesArr.length > 0) {
              this.setState({selectedModule: moduleTypesArr[0]});
            }
          } else if (
            resp.success &&
            modalstate == 'salesforce' &&
            resp.data &&
            resp.data.salesforce_moduletype
          ) {
            let moduleTypesArr = resp.data.salesforce_moduletype.split(',');
            if (moduleTypesArr.length > 0) {
              this.setState({selectedModule: moduleTypesArr[0]});
            }
          }
        });
      });
    }
  };

  render() {
    let {
      handleSubmit,
      modalstate,
      zohoFields,
      salesforceFields,
      crmDetails,
      zohoAccountFields,
      zohoContactFields,
      salesforceAccountFields,
      salesforceContactFields,
    } = this.props;
    let {
      selectedTab,
      selectedModule,
      salesForceDisconnectionLoading,
      zohoDisconnectionLoading,
    } = this.state;
    let kipploOpts = [];
    let zohoOpts = [];
    let salesforceOpts = [];
    let kipploOptions = [
      {label: 'Name', value: 'name'},
      {label: 'First Name', value: 'firstname'},
      {label: 'Last Name', value: 'lastname'},
      {label: 'Business Email', value: 'bemail'},
      {label: 'Secondary Email 1', value: 'email1'},
      {label: 'Secondary Email 2', value: 'email2'},
      {label: 'Secondary Email 3', value: 'email3'},
      {label: 'Contact address', value: 'contactaddress'},
      {label: 'Contact City', value: 'cities'},
      {label: 'Contact State', value: 'states'},
      {label: 'Contact Country', value: 'countries'},
      {label: 'Mobile', value: 'mobile'},
      {label: 'Phone Number1', value: 'phone1'},
      {label: 'Phone Number2', value: 'phone2'},
      {label: 'Phone Number3', value: 'phone3'},
      {label: 'Company Name', value: 'companyname'},
      {label: 'Company location', value: 'location'},
      {label: 'Company Headquarters phone', value: 'hqnumbers'},
      {label: 'Company phone', value: 'hqnumbers'},
      {label: 'Industry', value: 'industryname'},
      {label: 'Employee size', value: 'headcount'},
      {label: 'Annual Revenue', value: 'revenue'},
      {label: 'Website', value: 'domain'},
      {label: 'SIC code', value: 'siccodes'},
      {label: 'LinkedIn URL', value: 'linkedinurl'},
      {label: 'Job title', value: 'jobtitle'},
      {label: 'Seniority', value: 'seniority'},
      {label: 'Department', value: 'departments'},
      {label: 'Company address', value: 'companyaddress'},
      {label: 'Company City', value: 'ccities'},
      {label: 'Company State', value: 'cstates'},
      {label: 'Company Country', value: 'ccountries'},
    ];
    let fieldsArray;

    // console.log('selectedModule', selectedModule);
    // console.log('zohoFields', zohoFields);
    // console.log('zohoAccountFields', zohoAccountFields);

    if (modalstate == 'zoho') {
      switch (selectedModule) {
        case 'leads':
          fieldsArray = zohoFields;
          break;
        case 'accounts':
          fieldsArray = zohoAccountFields;
          break;
        case 'contacts':
          fieldsArray = zohoContactFields;
          break;
        default:
          fieldsArray = []; // Fallback in case selectedModule is invalid
      }
      //console.log('fieldsArray', fieldsArray);
      _.forEach(fieldsArray, function (obj) {
        var obj = {
          label: obj.field_label,
          value: obj.api_name,
        };
        zohoOpts.push(obj);
      });
    } else {
      switch (selectedModule) {
        case 'leads':
          fieldsArray = salesforceFields.fields;
          break;
        case 'accounts':
          fieldsArray = salesforceAccountFields.fields;
          break;
        case 'contacts':
          fieldsArray = salesforceContactFields.fields;
          break;
        default:
          fieldsArray = []; // Fallback in case selectedModule is invalid
      }
      _.forEach(fieldsArray, function (obj) {
        var obj = {
          label: obj.label,
          value: obj.name,
        };
        salesforceOpts.push(obj);
      });
    }
    //console.log('zohoOpts', zohoOpts);
    let moduleTypesArr = [];
    if (modalstate == 'zoho' && crmDetails && crmDetails.zoho_moduletype) {
      moduleTypesArr = crmDetails.zoho_moduletype.split(',');
    } else if (
      modalstate == 'salesforce' &&
      crmDetails &&
      crmDetails.salesforce_moduletype
    ) {
      moduleTypesArr = crmDetails.salesforce_moduletype.split(',');
    }
    return (
      <>
        <Transition.Root show={this.props.ismapFieldModalOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.ismapFieldModalOpen}
            onClose={this.closeModal}
            initialFocus={this.myRef}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block rounded-xl align-bottom  bg-chatbg rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-3/5"
                  style={{padding: 10}}>
                  <div className="p-6 border-t-2 border-quaternary">
                    <div className="flex items-center gap-2">
                      <div className="w-8 h-8 bg-secondary rounded-full justify-center items-center flex">
                        <div className="justify-center items-center flex">
                          <IconApi />
                        </div>
                      </div>
                      <div className="text-sm mainGray">
                        {modalstate == 'zoho'
                          ? LocaleStrings.setting_crm_zoho
                          : LocaleStrings.setting_crm_salesforce}
                      </div>
                    </div>

                    <div className="flex flex-row py-4 justify-between text-xs">
                      {selectedTab == 'fieldmapping' ? (
                        <div className="flex gap-2 items-center">
                          <div
                            className="cursor-pointer"
                            onClick={() => this.changeTab('settings', null)}>
                            <ArrowLeftIcon className="w-3" />
                          </div>

                          <div>Field Mapping </div>
                        </div>
                      ) : (
                        _.startCase(selectedTab)
                      )}
                      {/* <div
                        className="inline-flex rounded-md shadow-sm"
                        role="group">
                        <button
                          onClick={() =>
                            selectedTab == 'settings'
                              ? null
                              : this.changeTab('settings')
                          }
                          type="button"
                          className={`px-4 py-2 text-xs font-medium text-gray-900  border border-gray-200 rounded-s-lg hover:bg-gray-100 focus:outline-none  ${
                            selectedTab == 'settings'
                              ? 'bg-gray-100 '
                              : 'bg-white'
                          }`}>
                          Settings
                        </button>
                        <button
                          onClick={() =>
                            selectedTab == 'fieldmapping'
                              ? null
                              : this.changeTab('fieldmapping')
                          }
                          type="button"
                          className={`px-4 py-2 text-xs font-medium text-gray-900  border border-gray-200 rounded-e-lg hover:bg-gray-100 focus:outline-none  ${
                            selectedTab == 'fieldmapping'
                              ? 'bg-gray-100 '
                              : 'bg-white'
                          }`}>
                          Field Mapping
                        </button>
                      </div> */}
                    </div>
                    {selectedTab == 'settings' ? (
                      <div className="space-y-4">
                        <div class="bg-white p-4 rounded ">
                          <div className="font-semibold text-xs">
                            {LocaleStrings.settings_crm_export_preference}
                          </div>
                          <div className="text-[11px]">
                            {LocaleStrings.settings_crm_export_desc}{' '}
                            {_.startCase(modalstate)}
                          </div>
                          <div className="divide-y divide-solid">
                            {modulesArr.map((module, index) => (
                              <div className="py-4 flex justify-between items-center">
                                <div className="text-xs">{module.text}</div>
                                <div className="flex items-center">
                                  {moduleTypesArr.length > 0 &&
                                  moduleTypesArr.includes(
                                    module.value.toLowerCase(),
                                  ) ? (
                                    <div className="mx-2">
                                      <button
                                        onClick={() =>
                                          this.changeTab(
                                            'fieldmapping',
                                            module.value,
                                          )
                                        }
                                        type="button"
                                        className={
                                          'btn-light-primary cursor-pointer'
                                        }>
                                        Field Mapping
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="w-[120px]">&nbsp;</div>
                                  )}
                                  <Field
                                    name={module.value.toLowerCase()}
                                    label={null}
                                    sublabel={null}
                                    component={this.renderFieldToggleButton}
                                    type="checkbox"
                                    checked={
                                      moduleTypesArr.length > 0 &&
                                      moduleTypesArr.includes(
                                        module.value.toLowerCase(),
                                      )
                                        ? true
                                        : false
                                    }
                                    onChange={e =>
                                      this.handleModuleToggle(
                                        e,
                                        module.value.toLowerCase(),
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              // <div className="py-4 flex justify-between">
                              //   <div>Contact</div>
                              //   <div>
                              //     <Field
                              //       name="contact"
                              //       label={
                              //         LocaleStrings.kiosk_settings_modal_allow_print
                              //       }
                              //       sublabel={
                              //         LocaleStrings.kiosk_settings_modal_allow_print_desc
                              //       }
                              //       component={this.renderFieldToggleButton}
                              //       type="checkbox"
                              //     />
                              //   </div>
                              // </div>
                              // <div className="pt-4 flex justify-between">
                              //   <div>Account</div>
                              //   <div>
                              //     <Field
                              //       name="account"
                              //       label={
                              //         LocaleStrings.kiosk_settings_modal_allow_print
                              //       }
                              //       sublabel={
                              //         LocaleStrings.kiosk_settings_modal_allow_print_desc
                              //       }
                              //       component={this.renderFieldToggleButton}
                              //       type="checkbox"
                              //     />
                              //   </div>
                              // </div>
                            ))}
                          </div>
                        </div>

                        <div class="bg-white p-4 rounded">
                          <div className="flex justify-between items-center">
                            <div>
                              {modalstate == 'zoho'
                                ? LocaleStrings.setting_crm_tab1
                                : LocaleStrings.setting_crm_tab2}
                            </div>
                            <div>
                              {crmDetails &&
                              modalstate == 'zoho' &&
                              crmDetails.hasOwnProperty('zoho') &&
                              crmDetails.zoho ? (
                                <button
                                  type="button"
                                  //loading={zapierLoading}
                                  onClick={() => this.disConnect('zoho')}
                                  className="btn-light-primary cursor-pointer">
                                  {LocaleStrings.setting_crm_btn_disconnect}
                                </button>
                              ) : crmDetails &&
                                modalstate == 'salesforce' &&
                                crmDetails.hasOwnProperty('salesforce') &&
                                crmDetails.zoho ? (
                                <button
                                  type="button"
                                  //loading={zapierLoading}
                                  onClick={() => this.disConnect('salesforce')}
                                  className="btn-light-primary  cursor-pointer">
                                  {LocaleStrings.setting_crm_btn_disconnect}
                                </button>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {selectedTab == 'fieldmapping' ? (
                      <>
                        <div className="text-[11px] text-gray-800">
                          Field mapping allows you to determine which field will
                          get enriched by Kipplo
                        </div>

                        {/* <div className="flex flex-row py-4 justify-between">
                          <div
                            className="inline-flex rounded-md shadow-sm"
                            role="group">
                            {modulesArr
                              .filter(module =>
                                moduleTypesArr.includes(
                                  module.value.toLowerCase(),
                                ),
                              ) // Filter out modules that exist in moduleTypesArr
                              .map((module, index, filteredModules) => (
                                <button
                                  key={module.value}
                                  onClick={() =>
                                    selectedModule ===
                                    module.value.toLowerCase()
                                      ? null
                                      : this.changeModule(module.value)
                                  }
                                  type="button"
                                  className={`px-4 py-2 text-xs font-medium text-gray-900 border border-gray-200 focus:outline-none ${
                                    index === 0 ? 'rounded-s-lg' : ''
                                  } ${
                                    index === filteredModules.length - 1
                                      ? 'rounded-e-lg'
                                      : ''
                                  } hover:bg-gray-100 ${
                                    selectedModule ===
                                    module.value.toLowerCase()
                                      ? 'bg-gray-100'
                                      : 'bg-white'
                                  }`}>
                                  {module.text}
                                </button>
                              ))}
                          </div>
                          <div className="flex"></div>
                        </div> */}
                        <div>
                          <MappingForm
                            kipploOptions={kipploOptions}
                            zohoOptions={zohoOpts}
                            salesforceOptions={salesforceOpts}
                            defaultMappings={defaultMappings}
                            modalstate={this.props.modalstate}
                            closeModal={this.closeModal}
                            selectedModule={selectedModule}
                            changeTab={this.changeTab}
                          />
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function validate(values) {}

function mapStateToProps(state, ownProps) {
  var {session, user} = state;
  let initVals = {};
  initVals.kfirstname = 'firstname';
  initVals.klastname = 'lastname';
  initVals.kemail = 'businessemails';
  initVals.kcountry = 'country';
  initVals.kcompany = 'company';
  initVals.kindustry = 'industry';
  initVals.kphone = 'phone';

  if (
    ownProps.modalstate == 'zoho' &&
    state.crmDetails &&
    Object.keys(state.crmDetails.zoho_mapped_data).length > 0
  ) {
    // console.log('state.crmDetails', state.crmDetails);
    for (const key in state.crmDetails.zoho_mapped_data) {
      if (state.crmDetails.zoho_mapped_data.hasOwnProperty(key)) {
        const value = state.crmDetails.zoho_mapped_data[key];
        initVals[value == 'businessemails' ? 'zemail' : `z${value}`] = key;
      }
    }
  }
  if (
    ownProps.modalstate == 'salesforce' &&
    state.crmDetails &&
    Object.keys(state.crmDetails.sales_mapped_data).length > 0
  ) {
    for (const key in state.crmDetails.sales_mapped_data) {
      if (state.crmDetails.sales_mapped_data.hasOwnProperty(key)) {
        const value = state.crmDetails.sales_mapped_data[key];
        initVals[value == 'businessemails' ? 'zemail' : `s${value}`] = key;
      }
    }
  }
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    ismapFieldModalOpen: state.ismapFieldModalOpen,
    formValues: getFormValues('Mapfields')(state),
    appuserData: state.appuserData,
    initialValues: initVals,
    crmDetails: state.crmDetails,
    zohoFields: state.zohoFields.data,
    salesforceFields: state.salesforceFields,
    zohoAccountFields: state.zohoAccountFields.data,
    zohoContactFields: state.zohoContactFields.data,
    salesforceAccountFields: state.salesforceAccountFields,
    salesforceContactFields: state.salesforceContactFields,
  };
}

export default connect(mapStateToProps, {
  mapFieldsModal,
  updateMappedFields,
  getallZohoFields,
  getallSFFields,
  crmDisconnect,
  fetchCRMDetails,
  crmModuleUpdate,
})(
  reduxForm({
    validate,
    form: 'Mapfields',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Mapfields),
);
