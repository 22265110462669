import React, {Fragment, useEffect, useState} from 'react';
import TooltipPopover from '@/common/TooltipPopover';
import {PortalWithState} from 'react-portal';
import IconBusinessEmail from '../icons/selectable/businessemail';
import IconEmail from '../icons/selectable/email';
import IconPhone from '../icons/selectable/phone';
import CompositeSubContact from './compositesubcontact';
import {classNames} from '@commonFunction';

const height = window.innerHeight;

export default function CompositeContactBlock(props) {
  const [coords, setCoords] = useState({}); // takes current button coordinates

  let openTooltip, closeTooltip;

  const [showDetails, setShowDetails] = useState(props.toggleDetails);

  const [personalEmailCredits, setPersonalEmailCredits] = useState(
    props.credits.personal_emailcredits,
  );
  const [businessEmailCredits, setBusinessEmailCredits] = useState(
    props.credits.business_emailcredits,
  );
  const [phoneCredits, setPhoneCredits] = useState(
    props.credits.contactcredits,
  );

  const btnRef = React.createRef();

  const updateTooltipCoords = button => {
    const rect = btnRef.current.getBoundingClientRect();

    //Detemining top position of last popup in the tableview
    const top = height - 170 < rect.y + 5 ? rect.y - 105 : rect.y - 10;

    setCoords({
      left: rect.x + rect.width - 55, // add half the width of the button for centering
      top, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
    });
  };

  const {contactname, filterType} = props;

  let contactdetails = props.contactdetails;

  let personalEmails = contactdetails.filter(
    contact => contact.type === 'personalemail',
  );
  let businessEmails = contactdetails.filter(
    contact => contact.type === 'businessemail',
  );
  let directDials = contactdetails.filter(
    contact => contact.type === 'directdial',
  );

  // console.log('contactdetails => ', contactdetails);

  const emptyContactDetails = [];

  if (personalEmails.length > 0) {
    emptyContactDetails.push(personalEmails[0]);
  } else {
    emptyContactDetails.push({
      values: ['*******@*****.***'],
      validation: 'unknown',
      encrypted: true,
      type: 'personalemail',
      available: false,
    });
  }

  if (businessEmails.length > 0) {
    emptyContactDetails.push(businessEmails[0]);
  } else {
    emptyContactDetails.push({
      values: ['*******@*****.***'],
      validation: 'unknown',
      encrypted: true,
      type: 'businessemail',
      available: false,
    });
  }

  if (directDials.length > 0) {
    emptyContactDetails.push(directDials[0]);
  } else {
    emptyContactDetails.push({
      values: ['+**********'],
      validation: 'unknown',
      encrypted: true,
      type: 'directdial',
      available: false,
    });
  }
  contactdetails = emptyContactDetails;

  personalEmails = contactdetails.filter(
    contact => contact.type === 'personalemail',
  );
  businessEmails = contactdetails.filter(
    contact => contact.type === 'businessemail',
  );
  directDials = contactdetails.filter(contact => contact.type === 'directdial');

  const personalEncrypted = personalEmails[0].encrypted;
  const businessEncrypted = businessEmails[0].encrypted;
  const phoneEncrypted = directDials[0].encrypted;

  useEffect(() => {
    if (props.credits.personal_emailcredits !== personalEmailCredits) {
      setPersonalEmailCredits(props.credits.personal_emailcredits);
    }
  }, [props.credits.personal_emailcredits]);

  useEffect(() => {
    if (props.credits.business_emailcredits !== businessEmailCredits) {
      setBusinessEmailCredits(props.credits.business_emailcredits);
    }
  }, [props.credits.business_emailcredits]);

  useEffect(() => {
    if (props.credits.contactcredits !== phoneCredits) {
      setPhoneCredits(props.credits.contactcredits);
    }
  }, [props.credits.contactcredits]);

  useEffect(() => {
    if (showDetails !== props.toggleDetails) {
      setShowDetails(props.toggleDetails);
    }
  }, [props.toggleDetails]);

  useEffect(() => {
    if (showDetails) {
      updateTooltipCoords(btnRef.current);
      openTooltip();
    }
  }, [showDetails]);

  return (
    <div>
      <PortalWithState>
        {({openPortal, closePortal, isOpen, portal}) => {
          openTooltip = openPortal;
          closeTooltip = closePortal;
          return (
            <Fragment>
              <div
                ref={btnRef}
                onMouseEnter={e => {
                  // closePortal(e);
                  // const existing = document.querySelectorAll('.contactdetails');
                  // existing.forEach(item => {
                  //   if (item) {
                  //     const parent = item.parentElement; // Get the parent element
                  //     if (parent && parent.contains(item)) {
                  //       // Ensure the parent contains the child
                  //       parent.style.display = 'none';
                  //     } else {
                  //       console.warn(
                  //         'Parent does not contain the expected child:',
                  //         item,
                  //       );
                  //     }
                  //   }
                  // });

                  // updateTooltipCoords(e.target);
                  // setTimeout(() => {
                  //   openPortal(e);
                  // }, 50);
                  updateTooltipCoords(e.target);
                  openPortal(e);
                }}
                onMouseLeave={e => {
                  updateTooltipCoords(e.target);
                  closePortal();
                }}
                className="w-[140px] h-[50px]">
                <div className="flex gap-2">
                  <div
                    className="rounded-md border border-gray-200 border-solid items-center flex inline-flex bg-white"
                    style={{marginTop: 11}}>
                    <div
                      className={classNames(
                        'border-r border-gray-200 border-solid items-center justify-center p-1.5 px-2 hover:bg-gray-50 hover:cursor-pointer',
                        personalEmailCredits === 0 && personalEncrypted
                          ? 'opacity-40'
                          : 'opacity-100',
                      )}>
                      <IconEmail selected={!personalEncrypted} />
                    </div>
                    <div
                      className={classNames(
                        'border-r border-gray-200 border-solid items-center justify-center p-1.5 px-2 hover:bg-gray-50 hover:cursor-pointer',
                        businessEmailCredits === 0 && businessEncrypted
                          ? 'opacity-40'
                          : 'opacity-100',
                      )}>
                      <IconBusinessEmail selected={!businessEncrypted} />
                    </div>
                    <div
                      className={classNames(
                        'items-center justify-center p-1.5 px-2 hover:bg-gray-50 hover:cursor-pointer',
                        phoneCredits === 0 && phoneEncrypted
                          ? 'opacity-40'
                          : 'opacity-100',
                      )}>
                      <IconPhone selected={!phoneEncrypted} />
                    </div>
                  </div>
                </div>

                {portal(
                  <TooltipPopover
                    width={320}
                    coords={coords}
                    theClassName="contactdetails"
                    direction={'right'}
                    updateTooltipCoords={() =>
                      updateTooltipCoords(btnRef.current.buttonNode)
                    }>
                    <div className="p-2 bg-white shadow-md border rounded-lg border-gray-100 gap-4 w-full contactdetails">
                      <div className="py-2 text-[10px]">Contact Emails</div>
                      {contactdetails
                        .filter(
                          contact =>
                            contact.type === 'personalemail' ||
                            contact.type === 'businessemail',
                        )
                        .map(contact => (
                          <div key={`${contactname}-${contact.type}`}>
                            <CompositeSubContact
                              {...props}
                              {...contact}
                              contactname={contactname}
                              disabled={
                                contact.type === 'personalemail'
                                  ? props.credits.personal_emailcredits === 0
                                  : contact.type === 'businessemail'
                                    ? props.credits.business_emailcredits === 0
                                    : false
                              }
                              contacttype="email"
                              onUpgradePlan={props.onUpgradePlan}
                              onBuyAddon={props.onBuyAddon}
                              onClickFlag={props.onClickFlag}
                              onRevealCompletion={props.onRevealCompletion}
                            />
                          </div>
                        ))}
                      <div>
                        <div className="py-2 text-[10px]">Contact Phones</div>
                        {contactdetails
                          .filter(contact => contact.type === 'directdial')
                          .map(contact => (
                            <div key={`${contactname}-${contact.type}`}>
                              <CompositeSubContact
                                {...props}
                                {...contact}
                                contactname={contactname}
                                disabled={props.credits.contactcredits === 0}
                                contacttype="phone"
                                onUpgradePlan={props.onUpgradePlan}
                                onBuyAddon={props.onBuyAddon}
                                onClickFlag={props.onClickFlag}
                                onRevealCompletion={props.onRevealCompletion}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </TooltipPopover>,
                )}
              </div>
            </Fragment>
          );
        }}
      </PortalWithState>
    </div>
  );
}
