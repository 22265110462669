import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {SearchIcon} from '@heroicons/react/solid';
import {classNames} from '@commonFunction';
import LocaleStrings from '@language';
import UsagePlatform from './usageplatform/usageplatform';
import UsageApi from './usageapi/usageapi.js';
var d = new Date();
var nowx = d.setMonth(d.getMonth() - -0);
let month = moment(nowx).format('YYYY-MM-DD');

let startDate = month.substring(0, 8) + '01';
let endDate = '';
let checkfebruary = month.substring(0, 7);

let febArr = checkfebruary.split('-');

let checkfeb = String(febArr[1]);

if (checkfeb == '02') {
  endDate = month.substring(0, 8) + '27';
} else {
  endDate = month.substring(0, 8) + '30';
}
// console.log('endDate',endDate)

class Usage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_currency,
      selectedtab: 1,
      dateIndex: 0,
      dateNameToShow: '',
      dateNameToHidden: '',
      dailyCurrentSelection: '',
      yearMonthName: '',
      startdate: startDate,
      enddate: endDate,
    };

    // All Binded Functions
  }

  /**** Date change With Arrows *****/
  _handleNameChangeAndindex = (e, leftorright) => {
    let {startdate, enddate} = this.props;

    if (leftorright == 'minus') {
      // Minus
      var stateVal = this.state.dateIndex;
      var currVal = --stateVal;
      // console.log('currVal', currVal)
      this.setState({dateIndex: currVal});

      var d = new Date();
      var nowx = d.setMonth(d.getMonth() - -currVal);
      let month = moment(nowx).format('YYYY-MM-DD');

      let startdate = month.substring(0, 8) + '01';
      let enddate = month.substring(0, 8) + '30';
      this.setState({startdate: startdate});
      this.setState({enddate: enddate});
    } else {
      // Plus
      var stateVal = this.state.dateIndex;
      var currVal = ++stateVal;

      this.setState({dateIndex: currVal});

      var d = new Date();
      var nowx = d.setMonth(d.getMonth() - -currVal);
      let month = moment(nowx).format('YYYY-MM-DD');

      let startdate = month.substring(0, 8) + '01';
      let enddate = month.substring(0, 8) + '30';
      this.setState({startdate: startdate});
      this.setState({enddate: enddate});
    }
  };

  showYearMonth = () => {
    var d = new Date();
    var nowx = d.setMonth(d.getMonth() - -this.state.dateIndex);
    let month = moment(nowx).format('YYYY-MM-DD');
    // console.log('month',month)
    return moment(nowx).format('MMMM  YYYY');
  };

  _handletab(e, selectedtab) {
    this.setState({selectedtab: selectedtab});
  }

  render() {
    return (
      <>
        <div className="xs:px-6 md:px-0">
          <div className="block pb-4">
            <div>
              <div className="text-mainGray text-sm font-medium">
                {LocaleStrings.setting_usage_heading}
              </div>
              <div className="text-gray500 text-[11px] font-normal">
                {LocaleStrings.setting_usage_subheading}
              </div>
            </div>
            <div className="grid sm:grid-cols-1 lg:grid-cols-2 mt-5">
              <div className="bg-chatbg w-1/2 rounded-lg p-2">
                <div className="grid grid-cols-2">
                  <div
                    className={classNames(
                      this.state.selectedtab == 1
                        ? 'overflow-hidden bg-white shadow sm:rounded-lg py-2 px-6 '
                        : '',
                      'center-item cursor-pointer text-xs',
                    )}
                    onClick={e => this._handletab(e, 1)}
                    // className=""
                  >
                    {LocaleStrings.setting_usage_tab_button1}
                  </div>
                  <div
                    className={classNames(
                      this.state.selectedtab == 2
                        ? 'overflow-hidden bg-white shadow sm:rounded-lg py-2 px-6 '
                        : '',
                      'center-item cursor-pointer text-xs',
                    )}
                    onClick={e => this._handletab(e, 2)}>
                    {LocaleStrings.setting_usage_tab_button2}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="grid grid-cols-6 mt-5">
                        <div className="flex">
                            <span className="isolate inline-flex rounded-md shadow-sm">
                                <button
                                    type="button"
                                    className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                >
                                    {LocaleStrings.setting_usage_tab_button1}
                                </button>
                                <button
                                    type="button"
                                    className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                >
                                    {LocaleStrings.setting_usage_tab_button2}
                                </button>
                            </span>
                        </div>
                    </div> */}
          </div>
          {/* <div className="w-full  mx-auto bg-white  rounded-md border border-gray-200">
                    <header className="px-5 py-2 border-b border-gray-100">

                        <div className="flex">
                            <div className="relative w-6/12 text-ternary focus-within:text-secondary ">
                                <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-3">
                                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                                </div>
                                <input
                                    id="search-field"
                                    className="block lg:w-8/12 border-gray-300 xs:w-full sm:w-full h-full pl-8 pr-3 py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                                    placeholder={"Search"}
                                    type="search"
                                    name="search"
                                    value={this.state.search}
                                    onChange={this.handleSearch}
                                />
                            </div>
                            <div className="ml-auto">
                                <span className="isolate inline-flex rounded-md shadow-sm">
                                    <button
                                        type="button"
                                        onClick={(e) => this._handleNameChangeAndindex(e, 'minus')}
                                        className="relative inline-flex items-center bg-white rounded-l-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50  focus:outline-none "
                                    >
                                        <img src="../../images/previous.png" />
                                    </button>
                                    <button
                                        type="button"

                                        className="relative -ml-px inline-flex items-center bg-white  border border-gray-300  px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                    >
                                        {this.showYearMonth()}
                                    </button>
                                    <button
                                        type="button"
                                        onClick={(e) => this._handleNameChangeAndindex(e, 'plus')}
                                        className="relative -ml-px inline-flex items-center bg-white  rounded-r-md border border-gray-300  px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                    >
                                        <img src="../../images/next.png" />
                                    </button>
                                </span>

                            </div>
                        </div>
                    </header>
                </div> */}
          {this.state.selectedtab == 1 ? (
            <UsagePlatform />
          ) : (
            <UsageApi subTab={this.state.subTab} />
          )}
        </div>
      </>
    );
  }
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
});

export default connect(mapStateToProps, {})(Usage);
