import {connect} from 'react-redux';
import {useState} from 'react';
import LocaleStrings from '../../../languages';
import ContactCardCell from './cells/ContactCardCell';
import Createlistpopup from '../../search/components/createlistpopup';
import toast, {Toaster} from 'react-hot-toast';
import {LABEL_TYPE_REVEALED, updateLabel} from '../../mylist/actions';
import ManageLabelsPopups from '../../mylist/components/managelabels';
import {
  FILTER_TYPE_REVEALED_PEOPLE,
  updateRevealedPeople,
  postEnrich,
} from '../../search/actions';
import _ from 'lodash';
import {useNavigate} from 'react-router-dom';
import ReportContactPopup from '../../search/components/reportcontactpopup';
import {contactmetaAddupdate} from '@myContactsActions';
import {labelNotAssigned} from '../../mylist/constants';

function CardTable(props) {
  const {
    selectedPersons,
    onSelectPerson,
    filteredPeople,
    calculatedHeight,
    applyFilter,
  } = props;
  const [openCreateListPopup, setOpenCreateListPopup] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState();
  const [openManageLabelsPopup, setOpenManageLabelsPopup] = useState(false);
  const [openFlagPopup, setOpenFlagPopup] = useState(false);
  const [selectedContact, setSelectedContact] = useState();
  const [selectedList, setSelectedList] = useState(undefined);
  const navigate = useNavigate();

  const updateContactMeta = (val, action, type, linkedinurl, downvotedata) => {
    let obj = {};
    obj.linkedin_url = linkedinurl;
    obj.info = val;
    obj.action = action;
    obj.type = type;

    if (action === 'downvote') {
      obj.downvotereason = downvotedata.downvotereason;
      obj.reasonnote = downvotedata.reasonnote;
    }

    props.contactmetaAddupdate(props.session, obj, callback => {
      toast.success('Thanks! Your feedback has been submitted.');
    });
  };

  return (
    <div className="relative">
      <table className="w-full table-fixed divide-y divide-gray-300">
        <thead>
          <tr className="bg-gray-50">
            <th scope="col" className="relative w-5" />
            <th
              scope="col"
              className="py-3.5 pr-3 text-left text-xs font-normal text-gray-700">
              {LocaleStrings.search.peoplesearch.tableHeaders.contactname}
            </th>
            <th
              scope="col"
              className="w-1/3 px-3 py-3.5 text-left text-xs font-normal text-gray-700">
              {LocaleStrings.search.peoplesearch.tableHeaders.contactdetails}
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-xs font-normal text-gray-700">
              {LocaleStrings.search.peoplesearch.tableHeaders.companyname}
            </th>
            <th
              scope="col"
              className="px-3 w-[150px] py-3.5 text-left text-xs font-normal text-gray-700">
              {LocaleStrings.search.peoplesearch.tableHeaders.label}
            </th>
            <th className="relative w-[130px]" />
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {filteredPeople.map(person => {
            const filtered = selectedPersons.filter(
              item => item.peopleid === person.peopleid,
            );
            const selected = filtered.length > 0;
            return (
              <ContactCardCell
                person={person}
                selected={selected}
                onCheckUnCheck={checked => {
                  onSelectPerson(person, checked);
                }}
                onOpenCreateList={person => {
                  setOpenCreateListPopup(!openCreateListPopup);
                  setSelectedPerson(person);
                }}
                onCopySuccess={() => {
                  toast.success('Copied successfully');
                }}
                onClickFlag={contact => {
                  if (contact.action === 'downvote') {
                    setOpenFlagPopup(true);
                    setSelectedContact({...contact, ...person});
                  } else {
                    toast.success(LocaleStrings.mycontacts_upvoted_success);
                  }
                }}
                onLabelSelection={label => {
                  if (label.remove) {
                    const newpeople = {
                      ...person,
                      contacttag: labelNotAssigned,
                    };
                    const index = props.filteredPeople.findIndex(
                      t => t.peopleid === person.peopleid,
                    );
                    props.filteredPeople[index] = newpeople;
                    props.updateRevealedPeople({
                      totalcount: props.totalcount,
                      pages: props.numPages,
                      data: props.filteredPeople,
                    });
                    props.updateLabel(
                      props.session,
                      {
                        contactid: person.contactid,
                        tag: null,
                      },
                      _ => {},
                    );
                  } else if (!label.addnew) {
                    const newpeople = {...person, contacttag: label};
                    const index = props.filteredPeople.findIndex(
                      t => t.peopleid === person.peopleid,
                    );
                    props.filteredPeople[index] = newpeople;
                    props.updateRevealedPeople({
                      totalcount: props.totalcount,
                      pages: props.numPages,
                      data: props.filteredPeople,
                    });

                    props.updateLabel(
                      props.session,
                      {
                        contactid: person.contactid,
                        tag: label.title,
                      },
                      _ => {},
                    );
                  } else {
                    setSelectedList(person);
                    setOpenManageLabelsPopup(true);
                  }
                }}
                applyFilter={applyFilter}
              />
            );
          })}
        </tbody>
      </table>
      <Createlistpopup
        open={openCreateListPopup}
        selectedPerson={selectedPerson}
        filterType={FILTER_TYPE_REVEALED_PEOPLE}
        toggleOpen={open => {
          setOpenCreateListPopup(open);
        }}
      />
      <Toaster />
      <ManageLabelsPopups
        type={LABEL_TYPE_REVEALED}
        open={openManageLabelsPopup}
        selectedList={selectedList}
        toggleOpen={open => {
          setOpenManageLabelsPopup(open);
          setSelectedList(undefined);
        }}
      />
      <ReportContactPopup
        open={openFlagPopup}
        {...selectedContact}
        onClose={() => {
          setOpenFlagPopup(false);
        }}
        onSubmit={data => {
          updateContactMeta(
            selectedContact.contact,
            'downvote',
            selectedContact.contacttype,
            selectedContact.linkedin,
            data,
          );
          setOpenFlagPopup(false);
          setSelectedContact(undefined);
        }}
      />
    </div>
  );
}

function mapStateToProps(state) {
  const {session, user, savedFilters} = state;
  const {totalcount, pages, data} = state.filteredRevealedContacts;
  return {
    session,
    user,
    savedFilters,
    totalcount,
    numPages: pages,
    searchSelectedSubMenu: state.searchSelectedSubMenu,
    filteredPeople: data,
  };
}

export default connect(mapStateToProps, {
  updateRevealedPeople,
  updateLabel,
  contactmetaAddupdate,
  postEnrich,
})(CardTable);
