import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import LocaleStrings from '@language';
import toast, {Toaster} from 'react-hot-toast';
import CreateApi from './createapi';
import {randomstring} from 'randomstring-js';
import BaseLoader from '@baseLoader';
import CreateApiItem from './api-item';
import TryoutApi from './tryoutapi';
import Apidocumentation from './documentation';
import {
  createApimodal,
  fetchApikeys,
  tryoutapiModal,
  apiDocumentationmodal,
  fetchSampleApiData,
} from '@settingsApiActions';
import {fetchAppuser} from '@sidebarActions';
import {
  TRIALPLANID,
  BASIC_MONTHLY,
  PROFESSIONAL_YEARLY,
  MAIN_URL,
} from '@constant';
import SkeletonLoader from '@common/skeletonloader';
import IconApi from './icons/api';
import {classNames} from '@commonFunction';

const peopleRequest = {
  name: 'Preetham Poovani',
  linkedinurl: 'https://www.linkedin.com/in/preethampoovani/',
  companyname: 'Kipplo',
};

const peopleRequest2 = {
  name: 'Preetham Poovani',
  linkedinurl: 'https://www.linkedin.com/in/preethampoovani/',
  domain: 'Kipplo.com',
};

const companyRequest = {
  companyname: 'KIPPLO',
  domain: 'Kipplo.com',
};

class Api extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_currency,
      apikey: '',
      loading: true,
      selectedTab: 'people',
      copied: null,
    };

    // All Binded Functions
  }

  componentDidMount() {
    let {session, user} = this.props;
    this.props.fetchSampleApiData(session, res => {});
    this.props.fetchAppuser(session, user?.appuserid);
    this.props.fetchApikeys(session, callback => {
      this.setState({loading: false});
    });
  }

  opencreateApi = () => {
    let randomstring16 = randomstring(16);
    this.setState({apikey: randomstring16});
    this.props.createApimodal(true);
  };

  opentryoutApi = () => {
    this.props.tryoutapiModal(true);
  };
  openDocumentationModal = () => {
    this.props.apiDocumentationmodal(true);
  };

  renderCreateapiList() {
    let {selectedTab} = this.state;
    let peopleList = _.filter(this.props.apikeysDatalist.data, function (o) {
      return o.keytype === selectedTab;
    });
    var items = _.map(peopleList, (values, index) => (
      <CreateApiItem
        keyindex={index}
        values={values}
        selectedTab={selectedTab}
        // pagination={this.paginationCallback}
        // pageDataLength={pageDataLength}
        // currentPage={pageNumber}
      />
    ));
    return items;
  }

  _openUpgradPlan(e) {
    this.props.router.navigate('/upgradeplan');
  }

  changeTab(key) {
    this.setState({selectedTab: key});
  }

  renderJson = (json, level = 0) => {
    return (
      <div
        className="json-level"
        style={{marginLeft: `${level * 20}px`, wordWrap: 'break-word'}}>
        {'{'}
        <div className="ml-2">
          {Object.entries(json).map(([key, value], index, array) => (
            <div key={key}>
              <span className="json-key text-xs">"{key}"</span>
              <span className="text-xs">: </span>
              {this.renderValue(value, level)}
              {index < array.length - 1 ? <span>,</span> : ''}
            </div>
          ))}
        </div>
        {'}'}
      </div>
    );
  };

  renderValue = (value, level) => {
    if (Array.isArray(value)) {
      return (
        <span className="json-array">
          [
          {value.map((item, index) => (
            <span key={index}>
              {this.renderValue(item, level + 1)}
              {index < value.length - 1 ? <span>, </span> : ''}
            </span>
          ))}
          ]
        </span>
      );
    } else if (typeof value === 'object' && value !== null) {
      return this.renderJson(value, level + 1);
    } else if (typeof value === 'string') {
      return <span className="json-value">"{value}"</span>;
    } else if (value === null) {
      return <span className="json-null">null</span>;
    } else {
      return <span className="json-value">{value}</span>;
    }
  };

  _handlecopyMethod = (e, nums) => {
    let {selectedTab} = this.state;
    let link = '';
    if (nums == 1) {
      link = `${MAIN_URL}apikipplo/fetch.php?apikey=9ONQ************&tab=${selectedTab}&linkedinurl=${peopleRequest.linkedinurl}`;
    } else if (nums == 2) {
      link = `${MAIN_URL}apikipplo/fetch.php?apikey=9ONQ************&tab=${selectedTab}&name=${peopleRequest.name}&companyname=${peopleRequest.companyname}`;
    } else if (nums == 3) {
      link = `${MAIN_URL}apikipplo/fetch.php?apikey=9ONQ************&tab=${selectedTab}&name=${peopleRequest2.name}&domain=${peopleRequest2.domain}`;
    } else {
      link = `${MAIN_URL}apikipplo/fetch.php?apikey=9ONQ************&tab=${selectedTab}&companyname=${companyRequest.companyname}`;
    }
    this.setState({copied: nums});
    navigator.clipboard
      .writeText(link)
      .then(() => {
        // Show success message if the copying succeeds
        toast.success('Copied to clipboard!');
        setTimeout(() => {
          this.setState({copied: null});
        }, 2000);
      })
      .catch(err => {
        // Handle errors if copying fails
        toast.error('Failed to copy!');
        console.error('Failed to copy JSON data:', err);
      });
  };

  render() {
    let {apikeysDatalist, appuserData, apiEnrichSample} = this.props;
    let {selectedTab, copied} = this.state;
    let apicallcount = 0;
    let totalCreatedAPIKeys = 0;
    if (apikeysDatalist && apikeysDatalist.data) {
      totalCreatedAPIKeys = apikeysDatalist.data.length;
    }

    let peopleList = _.filter(this.props.apikeysDatalist.data, function (o) {
      return o.keytype === selectedTab;
    });

    const planid = appuserData.credits.planid;

    if (
      appuserData &&
      appuserData.data &&
      appuserData.data.length > 0 &&
      appuserData.data[0].client_by_clientid
    ) {
      if (
        appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid.length >
          0
      ) {
        let usercredit_by_clientid =
          appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid[0];
        apicallcount = parseInt(usercredit_by_clientid.apicallcount);
      }
    }

    const canProceed = apicallcount > 0;
    const availableKeys = Math.max(0, apicallcount);

    return (
      <>
        {this.state.loading ? (
          // <>
          //   <BaseLoader data={this.state.loaderDesc} />
          // </>
          <SkeletonLoader small />
        ) : !canProceed && totalCreatedAPIKeys == 0 ? (
          <div className="flex flex-col justify-center items-center h-full gap-4">
            <div className="text-base text-ternary">
              {LocaleStrings.settings_api_unlock}
            </div>
            <button
              type="submit"
              style={{width: 120}}
              onClick={e => this._openUpgradPlan()}
              className="btn-primary ml-4 cursor-pointer text-xs">
              {LocaleStrings.upgrade_now}
            </button>
          </div>
        ) : (
          <div className="xs:px-6 md:px-0">
            <div
              className={classNames(
                'grid gap-3',
                peopleList.length > 0 ? 'grid-cols-2' : 'grid-cols-1',
              )}>
              <div>
                <div className="flex py-2 justify-between">
                  <div className="" role="group">
                    <div className="text-mainGray text-sm font-semibold">
                      {LocaleStrings.settings_api_header}
                    </div>
                    {TRIALPLANID.includes(planid) || planid == BASIC_MONTHLY ? (
                      ''
                    ) : (
                      <div className="text-gray-500 text-[11px] font-normal">
                        {LocaleStrings.settings_api_subheader}
                      </div>
                    )}
                  </div>
                  <div className="flex items-center">
                    <div className="flex flex-col justify-center items-center h-full gap-2">
                      {canProceed ? (
                        <>
                          <div className="text-primary text-[11px] ml-2 bg-primaryHover px-4 text-center rounded-2xl my-2 md:my-0 py-4 md:py-2">
                            {availableKeys}{' '}
                            {availableKeys === 1 ? 'Key' : 'Keys'} Available
                          </div>
                          <button
                            type="button"
                            onClick={this.opencreateApi}
                            className="btn-primary cursor-pointer text-xs mt-1">
                            {LocaleStrings.settings_api_createapibtn}
                          </button>
                        </>
                      ) : (
                        <>
                          <div className="text-sm text-ternary">
                            {LocaleStrings.settings_api_unlock}
                          </div>
                          <button
                            type="submit"
                            style={{width: 120}}
                            onClick={this._openUpgradPlan}
                            className="btn-primary ml-4 cursor-pointer text-xs">
                            {LocaleStrings.upgrade_now}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-row py-4 justify-between">
                  <div
                    className="inline-flex rounded-md shadow-sm"
                    role="group">
                    <button
                      onClick={() =>
                        selectedTab == 'people'
                          ? null
                          : this.changeTab('people')
                      }
                      type="button"
                      className={`px-4 py-2 text-xs text-gray-900  border border-gray-200 rounded-s-lg hover:bg-gray-100  ${
                        selectedTab == 'people' ? 'bg-gray-100 ' : 'bg-white'
                      }`}>
                      {LocaleStrings.settings_api_tab_people}
                    </button>
                    <button
                      onClick={() =>
                        selectedTab == 'company'
                          ? null
                          : this.changeTab('company')
                      }
                      type="button"
                      className={`px-4 py-2 text-xs text-gray-900 border border-gray-200 hover:bg-gray-100 rounded-e-lg ${
                        selectedTab == 'company' ? 'bg-gray-100 ' : 'bg-white'
                      }`}>
                      {LocaleStrings.settings_api_tab_company}
                    </button>
                  </div>
                  <div className="flex"></div>
                </div>

                {apikeysDatalist &&
                apikeysDatalist.data &&
                apikeysDatalist.data.length > 0 &&
                peopleList.length > 0 ? (
                  <>
                    <div className="flex flex-col pr-1">
                      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                          <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table
                              className="min-w-full table-fixed divide-y divide-borderbox"
                              style={{boxShadow: '0px 4px 8px -2px #1018281A'}}>
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-xs font-medium text-gray500">
                                    {LocaleStrings.settings_api_th1}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-xs font-medium text-gray500">
                                    {LocaleStrings.settings_api_th2}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-xs font-medium text-gray500"></th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {this.renderCreateapiList()}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="grid grid-cols-1 mt-5">
                      <div className="cols-span-6 pr-1">
                        <div className="overflow-hidden bg-white shadow sm:rounded-lg py-4 pl-6">
                          <div className="text-lg">
                            {LocaleStrings.settings_api_tryout_api}
                          </div>
                          <div className="grid xs:grid-cols-1 md:grid-cols-2  mt-5 pb-2">
                            <div className="cols-span-10 space-y-4">
                              <div className="text-lg font-semibold text-primary">
                                Method - GET
                              </div>
                              {selectedTab == 'people' ? (
                                <>
                                  <div className="shadow border border-gray-200 rounded-xl p-4">
                                    <div>
                                      {LocaleStrings.settings_api_apicallurl}
                                    </div>
                                  </div>
                                  <div className="shadow border border-gray-200 rounded-xl p-4">
                                    <div>
                                      {
                                        LocaleStrings.settings_api_apicallurl_name
                                      }
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div className="shadow border border-gray-200 rounded-xl p-4">
                                  <div>
                                    {
                                      LocaleStrings.settings_api_company_apicallurl
                                    }
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="cols-span-2 pr-1 ml-auto xs:mt-4 sm:mt-0">
                              <div className="flex">
                                <button
                                  className="btn-primary"
                                  style={{width: '180px'}}
                                  onClick={e => this.openDocumentationModal(e)}>
                                  How to use
                                </button>
                                <button
                                  className="btn-primary ml-3"
                                  style={{width: '150px'}}
                                  onClick={e => this.opentryoutApi(e)}>
                                  {LocaleStrings.settings_api_tryout}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </>
                ) : (
                  <>
                    <div className="flex justify-center items-center">
                      <div
                        className="relative content-center justify-center items-center flex flex-col"
                        style={{height: '300px', width: '100%'}}>
                        <table className="w-full h-full table-fixed divide-y divide-gray-300 sm:px-6 lg:px-8" />
                        <div className="flex justify-center items-center flex-col">
                          <div className="w-8 h-8 bg-secondary rounded-full justify-center items-center mb-2 flex">
                            <div className="justify-center items-center flex">
                              <IconApi />
                            </div>
                          </div>
                          <div className="text-xs text-gray-500">
                            {' '}
                            {this.state.selectedTab == 'people'
                              ? LocaleStrings.no_people_api_found
                              : LocaleStrings.no_company_api_found}
                          </div>
                          <div className="mt-5">
                            <button
                              type="button"
                              style={{width: 'auto'}}
                              onClick={e => this.opencreateApi(e)}
                              className="btn-primary cursor-pointer text-xs">
                              {LocaleStrings.settings_api_createapibtn}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {peopleList.length > 0 ? (
                <div>
                  <div className="py-2">
                    <div className="text-mainGray text-xs font-medium">
                      {selectedTab == 'people'
                        ? LocaleStrings.settings_api_people_header
                        : LocaleStrings.settings_api_company_header}
                    </div>

                    <div className="text-gray500 text-[11px] font-normal">
                      {selectedTab == 'people'
                        ? LocaleStrings.settings_api_people_subheader
                        : LocaleStrings.settings_api_company_subheader}
                    </div>
                  </div>
                  <div className="flex flex-col pr-1 mt-2">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block max-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg bg-gray-50">
                          <div className="p-4">
                            <div className="flex justify-between items-center text-xs">
                              <div>
                                {LocaleStrings.settings_api_request_sample}
                              </div>
                              <div></div>
                            </div>
                            <div className="pt-4">
                              {selectedTab == 'people' ? (
                                <div className="space-y-2">
                                  <div className="flex justify-between">
                                    <div className="text-xs">Method 1</div>
                                    <div>
                                      {' '}
                                      <img
                                        onClick={e =>
                                          this._handlecopyMethod(e, 1)
                                        }
                                        src={
                                          copied == 1
                                            ? '../../images/copied.svg'
                                            : '../../images/init_copy.svg'
                                        }
                                        className="ml-2 cursor-pointer"
                                        style={{opacity: 0.8}}></img>
                                    </div>
                                  </div>
                                  <div className="text-xs">
                                    <span className="json-key text-xs">
                                      {LocaleStrings.apienrichment.url}
                                    </span>
                                    <span
                                      className="json-value pl-2 font-normal text-xs"
                                      style={{wordWrap: 'break-word'}}>
                                      {`${MAIN_URL}apikipplo/fetch.php?apikey=9ONQ************&tab=${selectedTab}&linkedinurl=${peopleRequest.linkedinurl}`}
                                    </span>
                                  </div>
                                  <div className="pt-2 flex justify-between">
                                    <div className="text-xs">Method 2</div>
                                    <div>
                                      {' '}
                                      <img
                                        onClick={e =>
                                          this._handlecopyMethod(e, 2)
                                        }
                                        src={
                                          copied == 2
                                            ? '../../images/copied.svg'
                                            : '../../images/init_copy.svg'
                                        }
                                        className="ml-2 cursor-pointer"
                                        style={{opacity: 0.8}}></img>
                                    </div>
                                  </div>
                                  <div className="text-xs">
                                    <span className="json-key">
                                      {LocaleStrings.apienrichment.url}
                                    </span>
                                    <span
                                      className="json-value pl-2 font-normal text-xs"
                                      style={{wordWrap: 'break-word'}}>
                                      {`${MAIN_URL}apikipplo/fetch.php?apikey=9ONQ************&tab=${selectedTab}&name=${peopleRequest.name}&companyname=${peopleRequest.companyname}`}
                                    </span>
                                  </div>
                                  <div className="pt-2 flex justify-between">
                                    <div className="text-xs">OR</div>
                                    <div>
                                      {' '}
                                      <img
                                        onClick={e =>
                                          this._handlecopyMethod(e, 3)
                                        }
                                        src={
                                          copied == 3
                                            ? '../../images/copied.svg'
                                            : '../../images/init_copy.svg'
                                        }
                                        className="ml-2 cursor-pointer"
                                        style={{opacity: 0.8}}></img>
                                    </div>
                                  </div>

                                  <div className="text-xs">
                                    <span className="json-key">
                                      {LocaleStrings.apienrichment.url}
                                    </span>
                                    <span
                                      className="json-value pl-2 font-normal text-xs"
                                      style={{wordWrap: 'break-word'}}>
                                      {`${MAIN_URL}apikipplo/fetch.php?apikey=9ONQ************&tab=${selectedTab}&name=${peopleRequest2.name}&domain=${peopleRequest2.domain}`}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div className="flex justify-between pb-2">
                                    <div></div>
                                    <div>
                                      <img
                                        onClick={e =>
                                          this._handlecopyMethod(e, 4)
                                        }
                                        src={
                                          copied == 4
                                            ? '../../images/copied.svg'
                                            : '../../images/init_copy.svg'
                                        }
                                        className="ml-2 cursor-pointer"
                                        style={{opacity: 0.8}}></img>
                                    </div>
                                  </div>

                                  <div className="text-xs">
                                    <span className="json-key">
                                      {LocaleStrings.apienrichment.url}
                                    </span>
                                    <span
                                      className="json-value pl-2 font-normal text-xs"
                                      style={{wordWrap: 'break-word'}}>
                                      {`${MAIN_URL}apikipplo/fetch.php?apikey=9ONQ************&tab=${selectedTab}&companyname=${companyRequest.companyname}`}
                                    </span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col pr-1 mt-5">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg bg-gray-50">
                          <div className="p-4">
                            <div className="flex justify-between items-center text-xs">
                              <div>
                                {' '}
                                {selectedTab == 'people'
                                  ? LocaleStrings.settings_api_people_sample
                                  : LocaleStrings.settings_api_company_sample}
                              </div>
                            </div>
                            <div>
                              <div className="json-container pt-5 text-[11px]">
                                {(apiEnrichSample.hasOwnProperty('jsonData') &&
                                  Object.keys(apiEnrichSample?.jsonData)
                                    .length > 0) ||
                                (apiEnrichSample.hasOwnProperty(
                                  'companyJsonData',
                                ) &&
                                  Object.keys(apiEnrichSample?.companyJsonData)
                                    .length > 0)
                                  ? this.renderJson(
                                      selectedTab == 'people'
                                        ? apiEnrichSample?.jsonData
                                        : apiEnrichSample?.companyJsonData,
                                    )
                                  : ''}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <CreateApi
              apikey={this.state.apikey}
              selectedTab={this.state.selectedTab}
            />
            <TryoutApi {...this.props} selectedTab={this.state.selectedTab} />
            <Apidocumentation selectedTab={this.state.selectedTab} />
            <Toaster />
          </div>
        )}
      </>
    );
  }
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  apikeysDatalist: state.apikeysDatalist,
  appuserData: state.appuserData,
  apiEnrichSample: state.apiEnrichSample,
});

export default connect(mapStateToProps, {
  createApimodal,
  fetchApikeys,
  fetchAppuser,
  tryoutapiModal,
  apiDocumentationmodal,
  fetchSampleApiData,
})(Api);
